import React from 'react';
import direction from '../../Assets/direction-icon.png';

const InfoBoxComponent = (props) => {
  const { address, state, mapClickHandler, setRedirectHandler } = props;
  const statePosition = address?.indexOf(state);
  const shortenedAddress = statePosition !== -1 && statePosition !== undefined ? address.substring(0, statePosition).trim() : address

  return (
    <div>
      <div style={{ position: 'absolute', top: '5px', left: '5px', zIndex: '100', }}>
        <div style={{ backgroundColor: 'white', padding: '10px' }}>
          <div className="d-flex gap-3 align-items-start ">
            <div>
              <div className="address"> {shortenedAddress}</div>
              <div style={{ color: '#adb5bd', fontSize: '10px', }}>{address}</div>
              <div onClick={mapClickHandler} style={{ cursor: 'pointer', color: 'blue', fontSize: '10px', }}>
                View larger map
              </div>
            </div>
            <div onClick={setRedirectHandler}>
              <div className="align-self-center" style={{ cursor: "pointer" }}><img style={{ width: "20px" }} src={direction} alt="hi" /></div>
              <div style={{ cursor: 'pointer', color: 'blue', fontSize: '10px', }}>Direction</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoBoxComponent;
