import React from 'react';
import { Typography } from '@mui/material';

import feisLogo from '../Assets/feis-logo.svg';
import OrganizerImg from '../Assets/OrganizerImage.jpg';

const DevinsMessage = () => {
    return (
        <>
            <section className="devincornell" style={{}}>
                <div className="bg-3"> </div>
                <div className="dashboard-organizer mb-5 d-flex justify-content-center align-items-center">
                    <img src={feisLogo} alt="organizer" />
                    <h3 style={{ paddingTop: '10px' }}> BY DEVIN CORNELL</h3>
                </div>
            </section>
            <section className="mx-auto lg-mb-5 lg-mt-5 md-mb-5 md-mt-5 OrgImage">
                <div style={{ display: 'block', margin: 'auto', }}>
                    <img src={OrganizerImg} class="img-fluid" alt="Responsive image" />
                </div>
            </section>
            <section className="mb-5 mt-5">
                <div className="justify-content-center  lg-py-1 md-py-1 sm-py-0 EventsList">
                    <div className="orgainzer-Content">
                        <Typography gutterBottom sx={{ maxWidth: 1000, marginBottom: '25px', }} centred>
                            Hi - and thanks for visiting <span>Feis.link</span>! I created{' '}<span>Feis.link </span>out of frustration with what is currently
                            available. As a feis organizer of 10 years, an adjudicator, and as a parent, I have used all of the various feis software from
                            all angles and came to the conclusion that there is a better way. <span>Feis.link </span>is years in the making and
                            continually improving with new technology to make both experiencing a feis as a participant and running a feis as an
                            organizer easier and more straightforward. The UI is intuitive, the results are fast, and everything is digital and online.
                        </Typography>
                        <Typography gutterBottom sx={{ maxWidth: 1000, marginBottom: '25px', }}>
                            If you have questions, please reach out<a mailTo="mailto:info@feis.link">, info@feis.link</a>, I
                            am happy to help and look forward to helping you run your next feis.
                        </Typography>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DevinsMessage