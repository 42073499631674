import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import TextError from "./TextError";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Input(props) {
  const { label, name, ...rest } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="d-flex flex-column" id="input">
        <label htmlFor={name} >{label} </label>
        <Field
          id={name}
          name={name}
          {...rest}
          startIcon={
            showPassword && name == "Password" ? (
              <VisibilityOffIcon />
            ) : (
              <VisibilityIcon />
            )
          }
        />

        <ErrorMessage component={TextError} name={name} />
      </div>
    </>
  );
}

export default Input;
