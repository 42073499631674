import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import closeIcon from '../../../../../Assets/close-icon.svg';
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import * as Yup from 'yup';
import Button from '../../../../Button/Button';
import '../ManageFeis.css';
import { useSelector, useDispatch } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import Select from 'react-select';
import Loader from '../../../../Loader/Loader';
import '../ManageFeis.css';
import { sortCompetitions } from "../../../../../helper/SortSyllabus"

const AddCompetitorModal = ({ modalState }) => {
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalReducer);
  const [levels, setLevels] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [competitorId, setCompetitorId] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTextState, setSearchTextState] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState("")
  const [err, setErr] = useState('');
  const ManageType = localStorage.getItem('Manage');

  //customize styles for react select
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      marginTop: '0px',
      borderRadius: '4px',
      maxHeight: '200px',
      // Other styles for the dropdown box
    }),
    menuList: (provided) => ({
      ...provided,
      overflowY: 'scroll',
      maxHeight: "200px"
      // Other styles for the dropdown box
    }),
    IndicatorsContainer: (provided) => ({
      ...provided,
      display: 'none',
      // Other styles for the dropdown box
    }),
  };

  // customize multiselect select box with react-select
  const CustomSelect = ({ field, form, ...props }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (competitions) => {
      setFieldValue(field.name, competitions);
    };

    return (
      <Select
        styles={customStyles}
        {...props}
        {...field}
        isMulti
        onChange={handleChange}
      />
    );
  };

  //modal is opened based if the modalname matches the value of modal got from redux
  const modalCloseHandler = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: '',
        eventProp: '',
      },
    });
  };

  //custom formik input handler to type names and call api accordingly
  const handleInputChange = (event, handleChange) => {
    setEnabled(false);
    const searchText = event.target.value;
    setSearchTextState(event.target.value);
    handleChange(event);
  };

  //get competitor names whenever the name changes -> fn called based on searchtext using debouncing
  const getCompetitorNames = async () => {
    const payload = { name: searchTextState };
    const url = `${process.env.REACT_APP_BASE_URL}/account/alldancers`;
    const response = await serviceConsumer('POST', url, payload);
    const responseCompetitors = response.result;
    const competitorsArray = responseCompetitors.map(
      (competitor) => `${competitor.fname} ${competitor.lname}`
    );
    setSearchResults(responseCompetitors);
    if (response.status === 400 || response.status === 500) {
    }
    setLoading(false);
  };

  //debouncing-to delay api call
  useEffect(() => {
    if (searchTextState.length > 0) {
      const Id = setTimeout(() => {
        getCompetitorNames();
      }, 500);
      return () => {
        clearTimeout(Id);
      };
    }
  }, [searchTextState.length]);

  //set searchresults to empty to clear data from previous api call
  useEffect(() => {
    searchTextState.length <= 0 && setSearchResults([]);
  }, [searchTextState]);

  // get levels on modal open
  const getLevels = async (compId) => {
    try {
      const url =
        ManageType == 'feis'
          ? `${process.env.REACT_APP_BASE_URL}/feis/levels`
          : ``;
      const payload = {
        id: modal.eventId,
        dancerId: compId
      };
      setLoading(true);
      const response = await serviceConsumer('POST', url, payload);
      setLevels(response.result);
      setLoading(false);
    } catch (error) {
      // showError(error.messages);
      // setTimeout(() => showError(""), 3000);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   getLevels();
  // }, []);

  //custom formik level select handler
  const handleSelectChange = async (event, handleChange, setFieldValue) => {
    const level = event.target.value;
    setSelectedLevel(level)
    handleChange(event);
    await getCompetitions(level);
    setFieldValue('competitionsArr', null);
  };

  // get competitions on levelselect -> fn called from handleSelectChange fn
  const getCompetitions = async (level) => {
    try {
      setLoading(true);
      const payload = {
        feis_id: modal.eventId,
        level: level ? level : selectedLevel,
        dancerId: competitorId,
      };
      const url = ManageType == 'feis'
        ? `${process.env.REACT_APP_BASE_URL}/feis/level/competitions/addComp`
        : `${process.env.REACT_APP_BASE_URL}/grade-exam/fees/${modal.eventId}`;
      const response = ManageType == 'feis'
        ? await serviceConsumer('POST', url, payload)
        : await serviceConsumer('GET', url, payload);
      const competitionsResponse = ManageType == 'feis'
        ? response.result
        : response.grade_exam_fees;
      const competitionArr = ManageType == 'feis'
        ? sortCompetitions(competitionsResponse).map((data) => { return { code: data?.code, id: data?._id }; })
        : competitionsResponse.map((data) => { return { code: data?.grade_name, id: data?._id }; });
      setCompetitions(competitionArr);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLevels(competitorId)
    getCompetitions();
  }, [competitorId]);

  //Initial values for formik
  const addCompetitorInitialValues = {
    competitor: '',
    level: ManageType == 'feis' ? '' : '1',
    competitionsArr: [],
  };

  //validation schema for formik
  const addCompetitorValidationSchema = Yup.object().shape({
    competitor: Yup.string()
      .trim()
      .label('First Name:')
      .required('This field is required.')
      .nullable()
      .max(30, 'Maximum characters allowed 30'),
    level: Yup.string()
      .trim()
      .label('Gender:')
      .required('This field is required.')
      .nullable(),
    competitionsArr: Yup.array()
      .required('Array is required')
      .min(1, 'Array must have at least one item'),
  });

  //formik submit handler-->add competitor
  const addCompetitorHandler = async (values) => {
    try {
      let competitionArr = [];
      for (let i = 0; i < values.competitionsArr.length; i++) {
        competitionArr.push(values.competitionsArr[i].value);
      }
      const feisPayload = {
        event_id: modal.eventId,
        dancer_id: competitorId,
        competitions: competitionArr,
        date: moment(new Date()).utc(true).format()
        // dancerId: competitorId,
      };
      const gradePayload = {
        event_id: modal.eventId,
        dancerId: competitorId,
        grades: competitionArr,
        date: moment(new Date()).utc(true).format()
      };

      const payload = ManageType === 'feis' ? feisPayload : gradePayload;
      const url =
        ManageType == 'feis'
          ? `${process.env.REACT_APP_BASE_URL}/feis/addcompetitor`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/addcandidate`;
      setLoading(true);
      const response = await serviceConsumer('POST', url, payload);
      dispatch({
        type: SET_MODAL,
        payload: {
          modal: 'AddedCompetitor',
          eventId: modal.eventId,
        },
      });
      setLoading(false);
    } catch (error) {
      setErr(error.messages);
    }

    setLoading(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', }} open={modalState}>
        <div className="org-modal-div">
          <div className="close-div mb-1">
            <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
          </div>
          <p className="modal-org-title text-center ">
            {ManageType == 'feis' ? 'Add Competitors' : 'Add candidate'}
          </p>
          <div className="d-flex align-items-center justify-content-center addCompetitor" style={{ background: 'white,borderRadius: 14px;' }}>
            <Formik
              initialValues={addCompetitorInitialValues}
              validationSchema={addCompetitorValidationSchema}
              onSubmit={addCompetitorHandler}
            >
              {({ setFieldValue, dirty, isValid, handleChange, }) => {
                return (
                  <Form autoComplete="off">
                    <div>
                      <div className="d-flex flex-column">
                        <Field
                          name="competitor"
                          type="text"
                          placeholder={ManageType == 'feis' ? 'Search competitor...' : 'Search candidate...'}
                          className="modal-input"
                          onChange={(event) => handleInputChange(event, handleChange)}
                          onPaste={(event) => handleInputChange(event, handleChange)}
                        />
                        {searchResults.length > 0 && (
                          <div style={{ position: 'relative' }}>
                            <div className="custom-dropdown">
                              {searchResults.map((option, index) => (
                                < div
                                  key={index}
                                  className="custom-dropdown-options"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setEnabled(true);
                                    setFieldValue('level', '');
                                    setFieldValue('competitionsArr', []);
                                    setFieldValue('competitor', `${option.fname} ${option.lname}`);
                                    setSearchResults([]);
                                    setCompetitorId(option._id);
                                  }}
                                >
                                  <span style={{ fontSize: "14px" }}>{option?.fname} {option?.lname} </span>
                                  <span style={{ fontSize: "9px" }}>({option?.parentData?.email})</span>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <ErrorMessage className="auth-error" name="competitor" component="div" />
                      </div>
                      {ManageType == 'feis' && (
                        <div className="d-flex flex-column">
                          <label className="search-label">Level:</label>
                          <Field
                            name="level"
                            as="select"
                            className="modal-select"
                            onChange={(event) => { handleSelectChange(event, handleChange, setFieldValue); }}
                          >
                            <option defaultValue hidden>Select</option>
                            {levels.map((level) => (<option value={level}>{level}</option>))}
                          </Field>
                          <ErrorMessage className="auth-error" name="level" component="div" />
                        </div>
                      )}

                      <div className="d-flex flex-column react-select">
                        <label className="search-label">Competiton:</label>
                        <Field
                          placeholder="Select"
                          name="competitionsArr"
                          component={CustomSelect}
                          options={competitions.map((competition) => {
                            return {
                              value: competition.id,
                              label: competition.code,
                            };
                          })}
                        />
                        <ErrorMessage className="auth-error" name="competitions" component="div" />
                        {err && <div className="auth-error add-comp-error">{err}</div>}
                      </div>
                      <div className="mt-4 mb-5 d-flex justify-content-center">
                        {ManageType == 'feis' ? (
                          <Button
                            className={isValid && dirty && enabled ? 'filled' : 'disabled'}
                            type="submit"
                            disabled={!(isValid && dirty && enabled)}
                          >
                            Add competitor
                          </Button>
                        ) : (
                          <Button
                            className={isValid && dirty && enabled ? 'filled' : 'disabled'}
                            type="submit"
                            disabled={!(isValid && dirty && enabled)}
                          >
                            Add candidate
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Modal >
    </div >
  );
};

export default AddCompetitorModal;
