import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderOrg from '../../../Headers/HeaderOrg';
import ManageTitle from '../../../../Components/TitleElements/ManageTitle';
import Help from '../../../../Components/Help/Help';
import { serviceConsumer } from '../../../../network/ServiceConsumer';
import Loader from '../../../../Components/Loader/Loader';
import OneRoundTable from './OneRoundTable';
import TwoRoundTable from './TwoRoundTable';
import ThreeRoundTable from './ThreeRoundTable';
import { sortCompetitions } from '../../../../helper/SortSyllabus';

const Tabulate = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const eventId = searchParams.get('id')
    const compId = localStorage.getItem("resultStatus") === "edit" && searchParams.get("compId")
    const [adjudicatorCount, setAdjudicatorCount] = useState(0)

    const [event, setEvent] = useState()
    const [loading, setLoading] = useState(false)
    const [levels, setLevels] = useState([])
    const [competitions, setCompetitions] = useState([])
    const [adjudicators, setAdjudicators] = useState([])
    const [level, setLevel] = useState("")
    const [competitionId, setCompetitionId] = useState("")
    const [adjudicator, setAdjudicator] = useState("")
    const [competitors, setCompetitors] = useState([])
    const [rounds, setRounds] = useState("")
    const [tableDataKey, setTableDataKey] = useState(0)
    const [err, setErr] = useState("")
    const [results, setResults] = useState([])
    const [round, setRound] = useState("1")
    const [initialLoading, setInitialLoading] = useState(false)
    const [currentCompetition, setCurrentCompetition] = useState()

    const roundOptions = [1, 2, 3]
    const createOrEdit = localStorage.getItem("resultStatus")

    useEffect(() => {
        getInitialDetails()
    }, [])

    const getInitialDetails = async () => {
        try {
            setInitialLoading(true)
            const eventUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
            const eventResponse = await serviceConsumer('GET', eventUrl)
            setEvent(eventResponse.Feis)

            const levelUrl = `${process.env.REACT_APP_BASE_URL}/feis/levels/checkIn`;
            const levelPayload = {
                id: eventId,
            }

            const levelResponse = await serviceConsumer("POST", levelUrl, levelPayload)
            setLevels(levelResponse.result)
            if (createOrEdit === "create") {
                getCompetitions(levelResponse.result[0])
                setLevel(levelResponse.result[0])
            } else if (createOrEdit === "edit") {
                const currentCompUrl = `${process.env.REACT_APP_BASE_URL}/feis/competition/${compId}`
                const currentCompResponse = await serviceConsumer("GET", currentCompUrl)
                setCurrentCompetition(currentCompResponse?.competition)
                getCompetitions(currentCompResponse.competition.group, currentCompResponse.competition._id)
                setLevel(currentCompResponse.competition.group)
            }

            const adjudicatorUrl = `${process.env.REACT_APP_BASE_URL}/people/${eventId}/adjudicators`
            const adjudicatorResponse = await serviceConsumer("GET", adjudicatorUrl)
            setAdjudicators(adjudicatorResponse.people)
            setInitialLoading(false)
        }
        catch (error) {
            setInitialLoading(false)
        }
    }

    const getResults = async (competition_id, adjudicator) => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/${competition_id}/enteredscores/${adjudicator}`;
            const response = await serviceConsumer("GET", url)
            setResults(response.result)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    //get competitions for selected level
    const getCompetitions = async (level, id = "") => {
        try {
            setLoading(true)
            const payload = {
                feis_id: eventId,
                level: level
            }
            const url = `${process.env.REACT_APP_BASE_URL}/feis/level/competitions`;
            const response = await serviceConsumer("POST", url, payload)
            const competitionsResponse = response.result;
            const sortedCompetitions = sortCompetitions(competitionsResponse)
            setCompetitions(sortedCompetitions)
            createOrEdit === "create"
                ? competitionSelectHandler(sortedCompetitions[0]._id, sortedCompetitions, 1)
                : competitionSelectHandler(id, sortedCompetitions, 1)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    //get dancers of the competition when selected
    const getCompetitors = async (compId) => {
        try {
            setLoading(true)
            const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/dancers/${compId}`;
            const response = await serviceConsumer("GET", url)
            setCompetitors(response.result)
            setLoading(false)
        }
        catch (error) {
            setLoading(false)
        }
    }

    //the select handlers
    const levelSelectHandler = async (selectedLevel) => {
        setErr("")
        setLevel(selectedLevel)
        await getCompetitions(selectedLevel)
    }

    const competitionSelectHandler = async (competition_id, competitionsLevelSelect = [], onLevelSelect = 0) => {
        setErr("")
        setCompetitionId(competition_id)
        let competitionsAfterCheck = onLevelSelect ? competitionsLevelSelect : competitions;//check on page load or levelselect
        let competition = competitionsAfterCheck.find((competition) => competition._id === competition_id)
        let adjudicatorsForEdit = adjudicators.filter(adjudicator => !competition?.results?.find((item) => adjudicator?._id === item?.judge_id))
        let adjudicatorIdsForEdit = adjudicatorsForEdit.map(item => item?._id)
        // setAdjudicator(adjudicatorIdsForEdit[0])
        setAdjudicator("")
        setAdjudicatorCount(competition.adjudicators)
        setRounds(competition.rounds)
        setRound("1")
        createOrEdit === "create" ? await getCompetitors(competition_id) : await getResults(competition_id, adjudicatorIdsForEdit[0])
        setTableDataKey((prevKey) => prevKey + 1);
    }

    const adjudicatorSelectHandler = async (e) => {
        setErr("")
        setAdjudicator(e.target.value)
        if (compId) await getResults(competitionId, e.target.value)
        setTableDataKey((prevKey) => prevKey + 1);
    }

    const roundSelectHandler = (e) => {
        setErr("")
        setRound(e.target.value)
        setTableDataKey((prevKey) => prevKey + 1);
    }

    const backClickHandler = () => {
        localStorage.getItem("resultStatus") === "edit"
            ? navigate(`/result-status/${event.name}}?id=${eventId}`, { state: { eventName: event?.name }, })
            : navigate(`/organizer-manage-feis?id=${eventId}&Manage=feis`)
    }
    return (
        <>
            {initialLoading && <Loader />}
            {loading && <Loader />}
            <HeaderOrg />
            <ManageTitle eventLogo={event?.logo} eventName={event?.name} eventType={event?.eventType} eventDate={event?.feis_date} loading={initialLoading} onBackClick={backClickHandler} />
            {!initialLoading && <div className="db-body">
                <div className='org-body-title pt-4'>Results</div>
                <div className="mb-3 hr-org-body" />
                <div className="tabulate-select-div">
                    <div className={adjudicatorCount === 9 ? "wrap-select-with-round" : "wrap-select"}>
                        {createOrEdit === "create" && <>
                            <div className="search-label">Select a level:</div>
                            <select name="level" className="tabulate-select" onChange={(e) => levelSelectHandler(e.target.value)} value={level}>
                                {levels?.map(levelOption => <option selected={levelOption === level} value={levelOption} >{levelOption}</option>)}
                            </select>
                        </>}
                        {createOrEdit === "edit" && <>
                            <div className="search-label">Level:</div>
                            <input name="level" className="tabulate-input" value={level} readOnly />
                        </>}
                    </div>
                    <div className={adjudicatorCount === 9 ? "wrap-select-with-round mt-2 mt-md-0" : "wrap-select mt-2 mt-md-0"}>
                        {createOrEdit === "create" && <>
                            <div className="search-label ">Select a competition:</div>
                            <select name="competition" className="tabulate-select" onChange={(e) => competitionSelectHandler(e.target.value)}>
                                {competitions?.map(competitionOption => <option value={competitionOption._id} selected={competitionOption._id === competitionId}>{competitionOption.code}-{competitionOption.group}</option>)}
                            </select>
                        </>}
                        {createOrEdit === "edit" && <>
                            <div className="search-label">Competition:</div>
                            <input name="level" className="tabulate-input" value={`${currentCompetition?.code}-${currentCompetition?.group}`} readOnly />
                        </>}
                    </div>
                    <div className={adjudicatorCount === 9 ? "wrap-select-with-round mt-2 mt-md-0" : "wrap-select mt-2 mt-md-0"}>
                        <div className="search-label" >Select an Adjudicator:</div>
                        <select name="adjudicator" className="tabulate-select" onChange={adjudicatorSelectHandler} value={adjudicator}>
                            <option defaultValue hidden >Select</option>
                            {createOrEdit === "create" && adjudicators?.map(adjOption => <option value={adjOption?._id} disabled={(competitions?.find((item) => item?._id === competitionId)?.results?.some(item => item?.judge_id === adjOption?._id))}>{adjOption?.name}</option>)}
                            {createOrEdit === "edit" && adjudicators?.filter((adjOption => competitions?.find((item) => item?._id === competitionId)?.results?.some((item) => item?.judge_id === adjOption?._id)))?.map(adjOption => <option value={adjOption?._id} >{adjOption?.name}</option>)}
                        </select>
                    </div>
                    {adjudicatorCount === 9 && <div className={"wrap-select-with-round mt-2 mt-md-0"}>
                        <div className="search-label" >Select a Round:</div>
                        <select name="round" className="tabulate-select" onChange={roundSelectHandler}>
                            {roundOptions?.map(round => <option value={round} >{round}</option>)}
                        </select>
                    </div>}
                </div>
                {adjudicatorCount !== 9 && level && competitionId && adjudicator && rounds === 1 && <OneRoundTable competitors={competitors} adjudicator={adjudicator} compId={competitionId} event={event} keyProp={tableDataKey} errProp={err} results={results} adjudicatorCount={adjudicatorCount} round={round} getCompetitors={getCompetitors} propLoading={loading} />}
                {adjudicatorCount !== 9 && level && competitionId && adjudicator && rounds === 2 && <TwoRoundTable competitors={competitors} adjudicator={adjudicator} compId={competitionId} event={event} keyProp={tableDataKey} errProp={err} results={results} getCompetitors={getCompetitors} propLoading={loading} />}
                {adjudicatorCount !== 9 && level && competitionId && adjudicator && rounds === 3 && <ThreeRoundTable competitors={competitors} adjudicator={adjudicator} compId={competitionId} event={event} keyProp={tableDataKey} errProp={err} results={results} getCompetitors={getCompetitors} propLoading={loading} />}
                {level && competitionId && adjudicator && adjudicatorCount === 9 && <OneRoundTable competitors={competitors} adjudicator={adjudicator} compId={competitionId} event={event} keyProp={tableDataKey} errProp={err} results={results} adjudicatorCount={adjudicatorCount} round={round} getCompetitors={getCompetitors} propLoading={loading} />}
            </div>}
            <Help />
        </>
    )
}

export default Tabulate