import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { SIGNIN } from './redux/actionTypes/authActionType';
import { ParentDashboard } from './Parent/Dashboard/ParentDashboard';
import LoggedIn from './Components/LoggedIn/LoggedIn';
import Homepage from './Feis-Homepage/Homepage';
import TransactionHistory from './Parent/Trasaction-History/TransactionHistory';
import SignIn from './Parent/Auth/SignIn';
import AddAccount from './Parent/Auth/AddAccount';
import AddDancers from './Parent/AddDancers';
import RegisterGradeEvents from './Parent/Register-Events/RegisterGradeEvents';
import { PageNotFound } from './Components/Errors/PageNotFound';
import { routesArray } from './routes';
import ResetPassword from './Parent/Auth/ResetPassword';
import FeiseannazViewEvent from './Parent/ViewEvent/FeiseannazViewEvent';
import GradeViewEvent from './Parent/ViewEvent/GradeViewEvent';
import RegisterFiesanaaEvent from './Parent/Register-Events/RegisterFiesanaaEvent';
import Competitors from './Parent/Competitors';
import Congratulations from './Parent/Success/Congratulations';
import CongratratulationsGrade from './Parent/Success/CongratratulationsGrade';
import FeiseannazEditEvent from './Parent/Register-Events/Edit-Event/FeiseannazEditEvent';
import ViewFiesEdit from './Parent/ViewEditEvent/ViewFiesEdit';
import ViewGradeEdit from './Parent/ViewEditEvent/ViewGradeEdit';
import GradeEditEvent from './Parent/Register-Events/Edit-Event/GradeEditEvent';
import Confirmpasswords from './Parent/Auth/Confirmpasswords';
import EventResults from './Parent/Results/EventResults';
import StripePage from './Parent/Payments/StripePage';
import Profile from './Parent/Account/Profile';
import { ToastContainer, toast } from 'react-toastify';
import StripeAccount from './Organizer/Payment/StripeAccount';
import 'react-toastify/dist/ReactToastify.css';
import Account from "./Parent/Account/Account"


// organizer Routes
import GradeOrg from './Organizer/CreateGradeEvent/GradeOrg';
import FiesOrg from './Organizer/CreateFeisEvent/FiesOrg';
import FinalGradeDetails from './Organizer/GoLive/FinalGradeDetails';
import LoginOrg from './Organizer/Auth/LoginOrg';
import SignupOrg from './Organizer/Auth/SignupOrg';
import ForgotPasswordOrg from './Organizer/Auth/ForgotPasswordOrg';
import ConfirmPasswordOrg from './Organizer/Auth/ConfirmPasswordOrg';
import CreateEventFromPrev from './Organizer/CreateEventFromPrev/CreateEventFromPrev';
import DashboardOrg from './Organizer/Dashboard/DashboardOrg';
import ManageFeisGrade from './Organizer/ManageFeisGrade/ManageFeisGrade';
import FeisCompetitors from './Organizer/ManageFeisGrade/RedirectFeis/Entries/FeisCompetitors';
import AccountOrg from './Organizer/Account/AccountOrg';
import EditCompetitors from './Organizer/ManageFeisGrade/RedirectFeis/Entries/EditCompetitors';
import EditRegister from './Organizer/ManageFeisGrade/RedirectFeis/Entries/EditRegister';
import MergeSplit from './Organizer/ManageFeisGrade/RedirectFeis/Entries/MergeSplit';
import MergeSplitFn from './Organizer/ManageFeisGrade/RedirectFeis/Entries/MergeSplitFn';
import Email from './Organizer/ManageFeisGrade/RedirectFeis/Entries/Email';
import EventDetails from './Organizer/ManageFeisGrade/RedirectFeis/Event/EventDetails';
import FinalFiezDetails from './Organizer/GoLive/FinalFiezDetails';
import CheckIn from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/CheckIn';
import ResultStatus from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/ResultStatus';
import Tabulate from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/Tabulate';
import CompleteResults from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/CompleteResults';
import ScoreCheckInDownload from './Organizer/ManageFeisGrade/RedirectFeis/Print/ScoreCheckInDownload';
import Transactions from './Organizer/ManageFeisGrade/RedirectFeis/Financial/Transactions';
import CandidateLists from './Organizer/ManageFeisGrade/RedirectGrade/Entries/CandidateLists';
import PublishedResults from './Organizer/ManageFeisGrade/RedirectFeis/Tabulation/PublishedResults';
import ViewCompleteResults from './Parent/Results/ViewCompleteResults';
import EditCandidates from './Organizer/ManageFeisGrade/RedirectGrade/Entries/EditCandidates';
import GradeEditRegister from './Organizer/ManageFeisGrade/RedirectGrade/Entries/GradeEditRegister';
import DeletedEvent from './Parent/Register-Events/DeletedEvent/DeletedEvent';
import ManageSchedule from './Organizer/ManageFeisGrade/RedirectFeis/Schedule/ManageSchedule';
import AwardsTableComponent from './Organizer/AwardsTable/AwardsTableComponent';
const theme = createTheme({
  palette: {
    primary: {
      main: '#68AD3F',
    },
    secondary: {
      main: '#FBB040',
    },
  },

  typography: {
    fontFamily: 'Rubik ',
    h1: {
      fontSize: '52px',
      fontWeight: 700,
    },
    h2: {
      fontSize: '40px',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
    },
  },
});

export const AppWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hasHelp, setHasHelp] = useState(true);
  const [apiAlert, setApiAlert] = useState([]);
  const [isToastVisible, setIsToastVisible] = useState(true);
  const accessToken = localStorage.getItem('accessToken');
  //Authenticating user while the session is live or user is logged in
  const authenticateUser = () => {
    if (accessToken) {
      dispatch({
        type: SIGNIN,
        payload: {
          accessToken,
        },
      });
    }
  };
  useEffect(() => {
    authenticateUser();
  }, []);

  // Add a request interceptor
  axios.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(handleResponse, handleError);

  function handleResponse(response) {
    return response;
  }

  function handleError(error) {
    if (error.response.status === 401) {
      localStorage.clear();
      navigate('/', {
        state: { from: `${error.response.data.message.name}` },
      });
      setApiAlert(error.response.data.message);
      return Promise.reject(error);
    }

    if (error.response.status === 500) {
      if (isToastVisible) {
        toast.dismiss();
        toast.error('Some Thing went wrong', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 100,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setIsToastVisible(false);
      }
    }

    <ToastContainer />;

    if (error.response.status === 403) {
      localStorage.clear();
      navigate('/', {
        state: { from: `${error.response.data.message}` },
      });
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }

  // const isValuableRoute = () => {
  //   const pathToMatch = window.location.pathname;

  //   for (let i = 0; i < routesArray.length; i++) {

  //     if (pathToMatch.startsWith(routesArray[i].split(':name')[0])) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }


  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {accessToken ? (
          <Routes>
            <Route exact path="/" element={<LoggedIn />} />
            <Route path="/add-dancers" element={<AddDancers />} />
            <Route path="/parent" element={<ParentDashboard />} />
            <Route path="/my-account" exact element={<Account />} />
            <Route path="/parent/account/profile" exact element={<Profile />} />
            <Route path="/parent/transaction-history" element={<TransactionHistory />} />
            <Route path="/feis/:name" element={<FeiseannazViewEvent />} />
            <Route path="/register-dancers" element={<RegisterFiesanaaEvent />} />
            <Route path="/checkout" element={<StripePage />} />
            <Route path="/edit-feis/:name" element={<ViewFiesEdit />} />
            <Route path="/feis-edit-dancers/:id" element={<FeiseannazEditEvent />} />
            <Route path="/parent/feis/register/payment/success" element={<Congratulations />} />
            <Route path="/parent/feis/edit-register/payment/success" element={<Congratulations edit />} />
            <Route path="/grade/:id" element={<GradeViewEvent />} />
            <Route path="/register-grade-exam" element={<RegisterGradeEvents />} />
            <Route path="/edit-grade/:name" element={<ViewGradeEdit />} />
            <Route path="/grade-edit-dancers/:id" element={<GradeEditEvent />} />
            <Route path="/parent/grade/register/payment/success" element={<CongratratulationsGrade />} />
            <Route path="/parent/grade/edit-register/payment/success" element={<CongratratulationsGrade edit />} />
            <Route path="/results/:name" element={<EventResults />} />
            <Route path="/view-complete-results" element={<ViewCompleteResults />} />
            <Route path="/competitors/:name" element={<Competitors />} />
            <Route path="/organizer-create-grade" element={<GradeOrg />} />
            <Route path="/organizer-create-feisanna" element={<FiesOrg />} />
            <Route path="/organiser-dasboard/createevent-grade/finalGradeDetails" element={<FinalGradeDetails />} />
            <Route path="/organiser-dasboard/createevent-feisanna/finalfiesDetails" element={<FinalFiezDetails />} />
            <Route path="/previous-events" element={<CreateEventFromPrev />} />
            <Route path="/organizer-dashboard" element={<DashboardOrg />} />
            <Route path="/organizer-manage-feis" element={<ManageFeisGrade />} />
            <Route path="/organizer-manage-grade" element={<ManageFeisGrade />} />
            <Route path="/competitorss/:name" element={<FeisCompetitors />} />
            <Route path="/candidate/:name" element={<CandidateLists />} />
            <Route path="/edit-competitors/:name" element={<EditCompetitors />} />
            <Route path="/edit-candidates/:name" element={<EditCandidates />} />
            <Route path="/my-account-org" exact element={<AccountOrg />} />
            <Route path="/organizer-account" exact element={<StripeAccount />} />
            <Route path="/edit-register/:name" exact element={<EditRegister />} />
            <Route path="/grade/edit-register/:name" exact element={<GradeEditRegister />} />
            <Route path="/merge-split/:name" exact element={<MergeSplit />} />
            <Route path="/merge-split-comp/:name" exact element={<MergeSplitFn />} />
            <Route path="/email" exact element={<Email />} />
            {/* <Route path="/event-details/:name" exact element={<EventDetails />} /> */}
            <Route path="/event-details" exact element={<EventDetails />} />
            <Route path="/check-in/:name" exact element={<CheckIn />} />
            <Route path="/result-status/:name" exact element={<ResultStatus />} />
            <Route path="/tabulate/:name" exact element={<Tabulate />} />
            <Route path="/complete-results/:name" exact element={<CompleteResults />} />
            <Route path="/score-checkin-download/:name" exact element={<ScoreCheckInDownload />} />
            <Route path="/transaction-history-org/:name" exact element={<Transactions />} />
            <Route path="/published-results/:name" exact element={<PublishedResults />} />
            <Route path="/temporary-registration" exact element={<DeletedEvent />} />
            <Route path="/awards" exact element={<AwardsTableComponent />} />
            <Route path="/manage-schedule" exact element={<ManageSchedule />} />
            <Route path="*" exact element={<PageNotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route exact path="/login" element={<SignIn />} />
            <Route path="/signup" element={<AddAccount />} />
            <Route path="/forgot-password" element={<ResetPassword />} />
            <Route path="/reset-password" element={<Confirmpasswords />} />
            <Route path="/add-dancers" element={<AddDancers />} />
            <Route path="/feis/:name" element={<FeiseannazViewEvent />} />
            <Route path="/grade/:name" exact element={<GradeViewEvent />} />
            <Route path="/login-org" element={<LoginOrg />} />
            <Route path="/signup-org" element={<SignupOrg />} />
            <Route path="/forgotpwd-org" element={<ForgotPasswordOrg />} />
            <Route path="/reset-pwd-org" element={<ConfirmPasswordOrg />} />
            <Route path="/temporary-registration" exact element={<DeletedEvent />} />
            <Route path="/results/:name" element={<EventResults />} />
            <Route path="*"
              element={
                (() => {

                  // if (routesArray.includes(window.location.pathname) || isValuableRoute()) {
                  if (routesArray.includes(window.location.pathname)) {

                    const fullPath = window.location.pathname + window.location.search;
                    const url = new URL(window.location.href);
                    const params = new URLSearchParams(url.search);
                    const id = params.get('id');
                    const type = params.get('type');
                    if (type === "redirect") {
                      localStorage.setItem("orgPath", fullPath)
                      localStorage.setItem("redirectFromOrg", "redirect")
                    }
                    return <Navigate to="/" state={{ from: '404PAGE' }} />;
                  } else {
                    return <PageNotFound />;
                  }
                })()
              }
            />
          </Routes>
        )}
        <ToastContainer />
      </ThemeProvider>
    </div>
  );
};
