import React from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Field, ErrorMessage } from 'formik';
import NumberInput from './NumberInput';

//for create there is delete option and for edit there is no delete option
export const DeleteIconTd = ({ onClick }) => {
    return <td >
        <div className="d-flex justify-content-center align-items-center">
            <DeleteOutlineIcon style={{ color: "red", cursor: "pointer" }} onClick={onClick} />
        </div>
    </td>
}

//text area for comments
export const CommentTd = ({ className, name }) => {
    return <td className={className}>
        <div className="input-desc">
            <Field name={name} as="textarea" className="table-text-area" placeholder="#" autoComplete="new-password" />
            <ErrorMessage name={name} component="div" className='auth-error' style={{ marginTop: "-7px" }} />
        </div>
    </td>
}

//number inputs for rounds and card number
export const NumberInputTd = ({ className, name }) => {
    return <td className={className}>
        <div className="input-code">
            <Field name={name} placeholder="&nbsp;&nbsp;#" type="number" component={NumberInput} autoComplete="new-password" />
            <ErrorMessage name={name} component="div" className='auth-error' />
        </div>
    </td>
}
