import React from 'react'
import congratulation from "../Assets/Congratulations.png"

const SuccessContent = ({ eventName, edit, deleted }) => {
    return (
        <div className="d-flex justify-content-center container" style={{ alignItems: "center" }}>
            <div className="congratulations">
                <div className="text-center">
                    <img src={congratulation} className="rounded" alt="..." />
                    <h3>Congratulations!</h3>
                    {!edit && !deleted && <p className="title" style={{ fontSize: "16px", fontWeight: "400" }}>
                        You have successfully registered for the
                        <br />{eventName}
                    </p>}
                    {edit && !deleted && <p className="title" style={{ fontSize: "16px", fontWeight: "400" }}>
                        Your registration has been edited.
                    </p>}
                    {deleted && <p className="title" style={{ fontSize: "16px", fontWeight: "400" }}>
                        You registration is updated for the
                        <br />{eventName}
                    </p>}
                </div>
            </div>
        </div>
    )
}

export default SuccessContent