import React, { useState, useEffect } from 'react';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Container } from 'react-bootstrap';

import ParentHeader from '../ParentHeader/ParentHeader';
import Card from '../../Components/Card/Card';
import PaginationNew from '../../Components/Pagination/PaginationNew';
import Help from '../../Components/Help/Help';

import moment from 'moment';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Loader from '../../Components/Loader/Loader';
import favIcon from '../../Assets/favicon.ico';
import { v4 as uuidv4 } from 'uuid';
import { serviceConsumer } from '../../network/ServiceConsumer';

import './Dashboard.css';
import { formatDate } from '../../helper/formatDate';

export const ParentDashboard = (props) => {
  const [registeredDancers, setRegisteredDancers] = useState([]);
  const [index, setIndex] = useState(0);
  const [value, setValue] = useState('1');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const payload = useSelector((state) => state.authReducer);
  let arr = [1, 2, 3, 4, 5];

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //edit registration button handler
  const editPage = (id, eventType, item) => {
    const urlEventName = item?.result?.name?.split(' ').join('+');
    if (eventType === 'grade') {
      navigate(`/edit-grade/${urlEventName}?id=${item.result._id}`, { state: { eventProp: item } });
    } else {
      navigate(`/edit-feis/${urlEventName}?id=${item.result._id}`, { state: { eventProp: item } });
    }
  };

  //get details of all registered dancers
  const getAllEntered = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/feis/entered`;
    const payload = {
      page: page,
      limit: 5,
    }
    try {
      setLoading(true);
      const response = await serviceConsumer("POST", url, payload);
      let totalData = response?.totalCount;
      let totalPage = Math.ceil(Number(totalData / 5));
      setTotalPages(totalPage);
      let startindex = page % 10 === 0 ? page - 9 : Math.floor(page / 10) * 10 + 1;
      setStartIndex(startindex);
      startindex + 9 > totalPage ? setEndIndex(totalPage) : setEndIndex(startindex + 9);
      if (response?.Feis?.results?.length > 0) {
        let data = response?.Feis?.results;
        setRegisteredDancers(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEntered();
    document.title = 'Feis.Link ';
  }, [page]);

  const getDatePlace = (item) => {
    return (item?.result?.eventType === 'Feiseanna' ? item?.result?.feis_date : item?.result?.exam_date)
      ? moment(formatDate(item?.result?.eventType === 'Feiseanna' ? item?.result?.feis_date : item?.result?.exam_date))
        .format(' MMMM Do, YYYY') : 'NA' - item?.result?.venue_city, item?.result?.state
  }

  return (
    <div>
      <ParentHeader payload={payload} />
      {loading && <Loader />}
      <div className="user-name d-flex ">
        <Container className="align-self-center">
          <p className="dashboard-name"> Hi, {localStorage.getItem('userFirstName')}!{' '} </p>
        </Container>
      </div>
      {!loading && <div>
        <div className="pd-container">
          {registeredDancers?.length > 0 && <>
            <Container>
              <div className="enteredEvents">
                <h5 style={{ color: '#424242' }}>Entered</h5>
                <hr className="mt-0 " />
              </div>
            </Container>
            <div className={totalPages < 2 ? 'pb-3' : ''}>
              {registeredDancers.map((item) => {
                return (
                  <div key={uuidv4()}>
                    <Container>
                      <div className="register-events" key={item?._id} data-value={123} onClick={() => { editPage(item._id, item.event_type, item); }} >
                        <div className="web-events-list ">
                          <img src={item?.result?.logo || favIcon} className="event-img" alt="logo" />
                          <div className="event-details">
                            <p className="title m-0">{item?.result?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"} </p>
                            <p className="event-name m-0">{item?.result?.name} </p>
                            <p className="reg-date mt-2 ">{getDatePlace(item)}
                            </p>
                          </div>
                          <div className="event-btn-pd">
                            <div className="d-flex align-items-center">
                            </div>
                            <div>
                              <button className="outlined ms-3" onClick={() => { editPage(item._id, item.event_type, item); }} > Edit registration</button>
                            </div>
                          </div>
                        </div>
                        <div className="event-mobile" onClick={() => { editPage(item._id, item.event_type, item); }}>
                          <div className="event-mobile-div">
                            <div><img src={item.result?.logo || favIcon} className="event-img" alt="logo" /></div>
                            <div>
                              <p className="title m-0">{item?.result?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
                              <p className="event-name m-0">{item?.result?.name}</p>
                              <p className="reg-date">{getDatePlace(item)}
                              </p>
                            </div>
                          </div>
                          <div className={`reg-close justify-content-end ${item.event_type === 'feis' ? '' : ' d-flex '}`} >
                            <div>
                              <button className="outlined" id="regBtnp" onClick={() => { editPage(item._id, item.event_type, item); }}>
                                Edit registration
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>
                );
              })
                .splice(index, 5)}
            </div>
            {!props.isHomePage && totalPages > 1 ? (
              <Container>
                <PaginationNew
                  loading={loading}
                  startIndex={startIndex}
                  endIndex={endIndex}
                  totalPages={totalPages}
                  page={page}
                  setPage={val => setPage(val)}
                  setStartIndex={val => setStartIndex(val)}
                  setEndIndex={val => setEndIndex(val)}
                  dataPerPage={5}
                />
              </Container>
            ) : (
              ""
            )}
          </>}

          <Container style={{ marginBottom: '8%' }}>
            <TabContext value={value}>
              <div className="justify-content-center justify-content-md-start  EventsList EventsList-pd">
                <div className={registeredDancers?.length > 0 ? "eventListw" : "eventListw my-2 my-md-5"}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{}} >
                    <Tab label="Upcoming" value="3" sx={{ fontSize: '22px', color: '#424242', width: ' 175px', }} />
                    <Tab label="Open" value="1" sx={{ fontSize: '22px', color: '#424242', width: ' 175px', }} />
                    <Tab label="Closed" value="4" sx={{ fontSize: '22px', color: '#424242', width: ' 175px', }} />
                    <Tab label="Results" value="2" sx={{ fontSize: '22px', color: '#424242', width: ' 175px', }} />
                  </TabList>
                </div>
              </div>
              <div className="">
                <form></form>
                <TabPanel value="1" style={{ padding: '0px 0px 50px 0px', }}>
                  <Card eventMode="open" />
                </TabPanel>
                <TabPanel value="2" style={{ padding: '0px 0px 50px 0px', }}>
                  <Card eventMode="result" />
                </TabPanel>
                <TabPanel value="3">
                  <Card eventMode="upcoming" />
                </TabPanel>
                <TabPanel value="4" style={{ padding: '0px 0px 50px 0px', }}>
                  <Card eventMode="closed" />
                </TabPanel>
              </div>
            </TabContext>
          </Container>
          <Help />
        </div>
      </div>}
    </div>
  );
};
