import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

import Loader from "../../Components/Loader/Loader";
import { serviceConsumer } from "../../network/ServiceConsumer";

import { accessTillPayment } from "../../helper/CreateDispatches";
import Radio from "../../Components/Radio/Radio";
import DeleteStripeAccount from "../StripeComponents/DeleteStripeAccount";
import StripeCard from "../StripeComponents/StripeCard";
import StripeDescription from "../StripeComponents/StripeDescription";
import AddStripeAccount from "../StripeComponents/AddStripeAccount";

const PaymentFies = (props) => {
  const [cards, setCards] = useState([]);
  const [errorconn, seterrorconn] = useState("");
  const [loading, setloading] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [codeProcessing, setCodeProcessing] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  let EventID = localStorage.getItem("ID");
  const page = localStorage.getItem("page");
  const TAB = localStorage.getItem("page");

  // only for link account, the code from stripe is sent to backend
  const sendCodeToBackend = async (code) => {
    setloading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stripe/link-connect-account`;
      const response = await serviceConsumer("POST", url, { code });
      if (response?.success) {
        window.location.reload();
      }
      setloading(false);
    } catch (err) {
      setloading(false);
    } finally {
      setCodeProcessing(false);
    }
  };

  useEffect(() => {
    if (!codeProcessing) {
      page > TAB && dispatchFunction();
      getCardDetails();
      getEventDetails();
    }
  }, [codeProcessing]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const codeParam = searchParams.get("code"); //got on link account
    const successParam = searchParams.get("success"); //got on create account
    // since on refresh in sendCodeToBackend,the params shouldnt again call the fn,we remove it. codeParam is only for link account
    if (codeParam) {
      searchParams.delete("code");
      const newUrl = `${location.pathname}`;
      window.history.replaceState(null, "", newUrl);
      sendCodeToBackend(codeParam);
    } else {
      // since there is a glitch, the codeprocessing is set to be true initially, and if params doesnt contain "code" set back to false.
      // this is why useEffect to call apis is connected to codeprocessing
      setCodeProcessing(false);
    }
    if (successParam) {
      searchParams.delete("success");
      const newUrl = `${location.pathname}`;
      window.history.replaceState(null, "", newUrl);
      window.location.reload();
    }
    if (searchParams.get("id")) {
      searchParams.delete("id"); // Remove the 'id' query parameter
      const newUrl = `${location.pathname}?${searchParams.toString()}`; // Construct the new URL without the 'id' parameter
      navigate(newUrl); // Update the URL
    }
  }, [location.search, location.pathname, navigate]);

  const getCardDetails = async () => {
    setloading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stripe/list-connect-accounts`;
      const response = await serviceConsumer("POST", url);
      setCards(response?.result);
    } catch (err) {}
    setloading(false);
  };

  // get data from back
  const getEventDetails = async () => {
    try {
      setloading(true);
      if (EventID) {
        const url =
          props.type === "feis"
            ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`
            : `${process.env.REACT_APP_BASE_URL}/grade-exam/${EventID}`;
        const response = await serviceConsumer("GET", url);
        localStorage.setItem(
          "page",
          props.type === "feis"
            ? response.Feis?.page
            : response.grade_exams?.page
        );
        const connAccId =
          props.type === "feis"
            ? response.Feis?.connectedAccountId
            : response.grade_exams?.connectedAccountId;
        setSelectedCardId(connAccId);
      }
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  if (props.status === false) {
    seterrorconn("ON boarding failed please do on Boarding");
  }

  const handleRadioChange = async (cardId) => {
    const type = props.type === "feis" ? "feis" : "grade-exam";
    const url = `${process.env.REACT_APP_BASE_URL}/${type}/update-connected-account`;
    const payload = {
      id: EventID,
      connectedAccountId: cardId,
    };
    setloading(true);
    try {
      const response = await serviceConsumer("POST", url, payload);
      setSelectedCardId(cardId);
    } catch (err) {}
    setloading(false);
  };

  const dispatchFunction = (next) => {
    accessTillPayment(dispatch);
  };

  // submitHandler
  const connectNextHandler = async (e) => {
    try {
      e.preventDefault();
      const payload = {
        page:
          localStorage.getItem("page") > 3 ? localStorage.getItem("page") : 3,
        connectedAccountId: selectedCardId,
      };
      dispatchFunction(true);
      localStorage.setItem("TAB", "4");
      if (Number(localStorage.getItem("page")) < 3) {
        localStorage.setItem("page", 3);
      }
      props.setValue("4");
      searchParams.delete("id");
      const url =
        props.type === "feis"
          ? `${process.env.REACT_APP_BASE_URL}/feis/${EventID} `
          : `${process.env.REACT_APP_BASE_URL} /grade-exam/${EventID} `;
      const response = await serviceConsumer("PUT", url, payload);
      if (response.success === true) {
        props.setValue("4");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container className="px-0">
      {loading && <Loader />}
      <form className="form form-label-right" autoComplete="off">
        <div>
          <div>
            <StripeDescription />
            <AddStripeAccount
              cards={cards}
              seterrorconn={seterrorconn}
              setLoading={setloading}
              createOrEdit
            />
            {cards.map(
              (card, index) =>
                card.externalAccount.currency &&
                card.externalAccount.last4 &&
                !errorconn && (
                  <div
                    key={index}
                    className="d-flex align-items-center gap-3 mt-3"
                  >
                    <Radio
                      checked={selectedCardId === card?._id}
                      onChange={() => handleRadioChange(card?._id)}
                    />
                    <StripeCard card={card} />
                    <DeleteStripeAccount
                      card={card}
                      getCardDetails={getCardDetails}
                    />
                  </div>
                )
            )}

            {errorconn && (
              <div
                className="mt-5 justify-content-center errorPay"
                style={{ width: "fit-content" }}
              >
                <Alert severity="error" style={{ justifyContent: "center" }}>
                  {errorconn}
                </Alert>
              </div>
            )}
            <div className="mt-3 mb-5">
              <button
                onClick={connectNextHandler}
                className={!errorconn && selectedCardId ? "filled" : "disabled"}
                type="submit"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </Container>
  );
};

export default PaymentFies;
