import React, { useEffect, useState } from 'react';
import Help from '../../Components/Help/Help';
import HeaderOrg from '../Headers/HeaderOrg';
import './DashboardOrg.css';
import Tab from '@mui/material/Tab';
import { TabPanel } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Events from './Events';
import Closed from './Closed';
import Button from '../../Components/Button/Button';
import CreateEventModal from '../../Components/Modals/CreateEventModal';
import Popover from '@mui/material/Popover';
import { ResetLocalStoragePagination, setLocalOnOrgdashboardLoading } from '../../helper/setLocalStorage';

const DashboardOrg = () => {
  const [value, setValue] = useState('1');
  const [anchorEl, setAnchorEl] = useState(null);
  const [createFeiseanna, setCreateFeiseanna] = useState(false);
  const [createGrade, setCreateGrade] = useState(false);
  localStorage.removeItem("closed")

  //popover handlers of create event btn
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //create event click handlers
  const feiseannaClickHandler = () => {
    setCreateFeiseanna(!createFeiseanna);
  };

  const gradeClickHandler = () => {
    setCreateGrade(!createGrade);
  };

  //tab change handler
  const handleChange = (event, value) => {
    setValue(value);
    ResetLocalStoragePagination();
  };

  useEffect(() => {
    setLocalOnOrgdashboardLoading();
  }, []);

  const fname = localStorage.getItem('userFirstName');

  return (
    <>
      <HeaderOrg />
      <div className="db-container">
        <p className="hi-name mb-0">Hi, {fname}!</p>
        <div className="db-tab-container">
          <TabContext value={value}>
            <TabList className="db-tabs-div d-flex " onChange={handleChange} aria-label="lab API tabs example" >
              <Tab className="db-tab" label="Events" value="1" />
              <Tab className="db-tab" label="Closed events" value="2" />
              <div className="flex-grow-1 d-flex justify-content-end align-items-center align-items-sm-start db-dropdown">
                <div classname="flex-grow-1 ">
                  <Button className="filled" onClick={handleClick}>Create event</Button>
                  <Popover
                    className='dashboardPopover'
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    sx={{ marginTop: '5px', }}
                  >
                    <div className=" ps-3 py-2 create-event-opt" onClick={feiseannaClickHandler}>Feiseanna</div>
                    <div className=" ps-3 py-2 create-event-opt" onClick={gradeClickHandler}> Grade exam</div>
                  </Popover>
                </div>
              </div>
            </TabList>
            <div className="hr-org-title" style={{ marginTop: '-2px' }} />
            <TabPanel value="1">
              <Events />
            </TabPanel>
            <TabPanel value="2">
              <Closed />
            </TabPanel>
          </TabContext>
          {createFeiseanna && (<CreateEventModal modalState={createFeiseanna} clickHandler={feiseannaClickHandler} eventType="feiseanna" />)}
          {createGrade && (<CreateEventModal modalState={createGrade} clickHandler={gradeClickHandler} eventType="grade" />
          )}
        </div>
      </div>
      <Help />
    </>
  );
};

export default DashboardOrg;
