import React, { useEffect, useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import useGoogle from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import closeIcon from '../../../Assets/close-icon.svg';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import * as Yup from 'yup';
import AddIcon from '@mui/icons-material/Add';
import { serviceConsumer } from '../../../network/ServiceConsumer';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MODAL } from '../../../redux/actionTypes/modalActionType';
import { getAddressObject } from '../../../helper/getAddressObject';
import LoadScript from '../../Maps/LoadScript';
import { Form, Formik, useFormikContext, label, Field, ErrorMessage, } from 'formik';
import Loader from '../../Loader/Loader';

const AccomodationModel = ({ modalState, action, showAdjucator, setaccomodationdata, accomodationData, from, isManage, }) => {
  const formik = useFormikContext();
  const dispatch = useDispatch();
  const modal = useSelector((state) => state.modalReducer);
  const [showList, setShowList] = useState(false);
  const [imgError, setImgErr] = useState('');
  const [logo, setLogo] = useState('');
  const [upl, setUpl] = useState('');
  const [value, setValue] = useState('');
  const [lat, setLat] = useState();
  const [lon, setLon] = useState();

  const [loading, setLoading] = useState(false);
  const [adress, setAddress] = useState();
  const [img, setimg] = useState(false);
  const [text, setText] = useState('');

  let EventID = isManage ? modal.eventId : localStorage.getItem('ID');

  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading, } = useGoogle(
    {
      apiKey: process.env.REACT_APP_GOOGLE_API,
      options: { componentRestrictions: { country: ['ca', 'USA'] }, },
    });

  const getDetails = (placeid, formik) => {
    const data = placePredictions.filter((item) => item.place_id === placeid);

    placesService?.getDetails(
      { placeId: data[0].place_id, },
      (placeDetails) => {
        setLat(placeDetails.geometry.location.lat().toString());
        setLon(placeDetails.geometry.location.lng().toString());

        const address = getAddressObject(placeDetails?.address_components);
        setValue(placeDetails?.name);

        formik.setFieldTouched('address', false);
        formik.setFieldValue('address', address?.city + ' ' + address?.region + ' ' + address?.country + ' ' + address?.postal_code + ' ');
        formik.setFieldValue('city', address?.city);
        formik.setFieldValue('state', address?.region);
        formik.setFieldValue('country', address?.country);
        formik.setFieldValue('postal_code', address?.postal_code);
        formik.setFieldTouched('postal_code', address?.city);
        formik.setFieldTouched('postal_code', address?.region);
        formik.setFieldTouched('postal_code', false);

        setAddress({
          address: placeDetails?.name,
          city: address?.city,
          province: address?.region,
          postalCode: address?.postal_code,
          lat: placeDetails.geometry.location.lat().toString(),
          lon: placeDetails.geometry.location.lng().toString(),
        });

        setShowList(false);

        // Check form validity
        const isFormValid = formik.isValid && Object.keys(formik.touched).some((field) => formik.touched[field]);
        // Perform any actions based on form validity
        if (isFormValid) {
          // Form is valid, perform desired actions
          return formik.isValid;
        } else {
          // Form is not valid, perform desired actions
          return formik.isValid;
        }
      }
    );
  };

  const addAcomodationInitialValues = {
    name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    url: '',
    photo: '',
    postal_code: '',
    description: '',
  };

  useEffect(() => {
    setAddress(formik?.values?.address);
  }, [formik?.values]);

  const addAcomodationValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .label('First Name:')
      .required('This field is required.')
      .nullable()
      .max(50),
    address: Yup.string()
      .trim()
      .label('Address:')
      .required('This field is required.')
      .nullable(),
    city: Yup.string()
      .trim()
      .label('Birth Year:')
      .required('This field is required.'),
    state: Yup.string()
      .trim()
      .label('School:')
      .required('This field is required.')
      .nullable(),
    country: Yup.string()
      .trim()
      .label('Country:')
      .required('This field is required.')
      .nullable(),
    postal_code: Yup.string()
      .trim()
      .label('Levels:')
      .required('This field is required.')
      .nullable(),
    description: Yup.string()
      .trim()
      .required('This field is required.')
      .nullable(),

    photo: Yup.mixed().required('File is required'),
  });

  const addAcomodationHandler = async (values, formikHelpers) => {
    const payload = {
      name: values.name,
      photo: logo,
      description: text,
      address: values.address,
      country: values.country,
      postal_code: values.postal_code,
      url: values.url,
      eventId: EventID,
      city: values.city,
      state: values.state,
      latitude: lat,
      longitude: lon,
    };
    setLoading(true);
    try {
      setLoading(true);
      const url =
        from !== 'grade'
          ? `${process.env.REACT_APP_BASE_URL}/feis/accomdations`
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/accomdations`;

      const response = await serviceConsumer('POST', url, payload);
      if (response.success === true) {
        setLoading(false);
        isManage ? openAccListHandler() : action();
        setaccomodationdata(true);
        accomodationData();
      }
      action();
    } catch (error) {
      action();
      setLoading(false);
    }
  };

  const imgInputRef = useRef(null);

  const handleFileSelect = async (event, form) => {
    const selectedLogo = event.target.files[0];
    const logo = event.target;

    // Process the selected file or perform any operations here
    if (
      selectedLogo.type === 'image/png' ||
      selectedLogo.type === 'image/svg' ||
      selectedLogo.type === 'image/jpg' ||
      selectedLogo.type === 'image/jpeg'
    ) {
      setImgErr('');

      if (selectedLogo) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = async () => {
            if (img.width === 1024 && img.height === 683) {
              try {
                setLoading(true);
                let formData = new FormData();
                formData.append('image', event.target.files[0]);
                const url = `${process.env.REACT_APP_BASE_URL}/feis/upload`;
                const response = await serviceConsumer('POST', url, formData);
                if (response.url) {
                  setLogo(response.url);
                  if (response.url) {
                    setUpl('Uploaded successfully');
                    setimg(true);
                  }
                }
                form.setFieldValue('photo', event.target.files[0]?.name);
                setLoading(false);
              } catch (error) {
                setLoading(false);
                setimg(true);
              }
            } else {
              setImgErr(
                'The uploaded photo should have dimensions of 1024x683 pixels.'
              );
              setimg(true);
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(selectedLogo);
      }
    } else {
      setImgErr(
        'Please upload a file with the extension .jpg, .png, jpeg or .svg.'
      );
      setimg(false);
    }
  };

  const handleLOgo = () => {
    imgInputRef.current.click();
  };

  const modalCloseHandler = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: '',
        eventId: '',
      },
    });
  };

  const openAccListHandler = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: 'Accomodation',
        eventId: modal.eventId,
      },
    });
  };

  // to maintain 500 word limit
  const handleTextChange = (event, formik) => {
    const enteredText = event.target.value;
    const words = enteredText.trim().split(/\s+/);
    if (words.length <= 500) {
      formik.setFieldValue('description', enteredText);
      setText(enteredText);
    }
  };

  return (
    <div style={{ padding: '100px', overflow: 'scroll' }}>
      {loading && <Loader />}
      {showAdjucator === false && (
        <Modal
          className="addDancerScroll"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}
          open={isManage ? true : modalState}
        >
          <Box>
            <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
              <div className="main-div">
                <div className="close-div mt-4 mb-1">
                  <img src={closeIcon} alt="close-icon" className="close-icon" onClick={() => { isManage ? modalCloseHandler() : action(); }} />
                </div>
                <p className="color-subtitle text-center mb-4" style={{ marginTop: '16px' }}>Add Accomodation</p>
                <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
                  <Formik
                    initialValues={addAcomodationInitialValues}
                    validationSchema={addAcomodationValidationSchema}
                    onSubmit={addAcomodationHandler}
                    validateOnChange={true}
                    validateOnBlur={true}
                  >
                    {(formik) => (
                      <Form className="form form-label-right" autoComplete="off">
                        <input type="file" ref={imgInputRef} style={{ display: 'none' }} onChange={(e) => handleFileSelect(e, formik)} />
                        <div className={formik.errors.name && formik.touched.name ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.name && formik.touched.name ? 'label-head-org-error' : 'label-head-org'}>
                            Name Accommodation:
                          </label>
                          <Field
                            className={formik.errors.name && formik.touched.name ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="name"
                            placeholder="Name"
                          />
                          <ErrorMessage className="auth-error" name="name" component="div" />
                        </div>

                        <div className={formik.errors.address && formik.touched.address ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.address && formik.touched.address ? 'label-head-org-error' : 'label-head-org'}>
                            Address:
                          </label>
                          <Field
                            className={formik.errors.address && formik.touched.address ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={value}
                            onChange={(evt) => {
                              setValue(evt.target.value);
                              setAddress(null);
                              getPlacePredictions({ input: evt.target.value });
                              if (!isPlacePredictionsLoading) setShowList(true);
                              formik.setFieldValue('address', evt.target.value);
                            }}
                          />
                          {value !== '' && showList && placePredictions.length > 0 && (
                            <div className="google-autosuggestion-list">
                              <ListGroup dataSource={placePredictions}>
                                {placePredictions.map((item) => (
                                  <ListGroupItem onClick={() => getDetails(item?.place_id, formik)}>
                                    {item.description}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          )}
                          <ErrorMessage className="auth-error" name="address" component="div" />
                        </div>
                        <div className={formik.errors.city && formik.touched.city ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.city && formik.touched.city ? 'label-head-org-error' : 'label-head-org'}>
                            City:
                          </label>
                          <Field
                            className={formik.errors.city && formik.touched.city ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="city"
                            placeholder="City"
                          />
                          <ErrorMessage className="auth-error" name="city" component="div" />
                        </div>
                        <div className={formik.errors.state && formik.touched.state ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.state && formik.touched.state ? 'label-head-org-error' : 'label-head-org'}>
                            State/Provice:
                          </label>
                          <Field
                            className={formik.errors.state && formik.touched.state ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="state"
                            placeholder="State"
                          />
                          <ErrorMessage className="auth-error" name="state" component="div" />
                        </div>
                        <div className={formik.errors.country && formik.touched.country ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.country && formik.touched.country ? 'label-head-org-error' : 'label-head-org'}>
                            Country:
                          </label>
                          <Field
                            className={formik.errors.country && formik.touched.country ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="country"
                            placeholder="Country"
                          />
                          <ErrorMessage className="auth-error" name="country" component="div" />
                        </div>
                        <div className={formik.errors.postal_code && formik.touched.postal_code ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.postal_code && formik.touched.postal_code ? 'label-head-org-error' : 'label-head-org'}>
                            Postal /Zipcode:
                          </label>
                          <Field
                            className={formik.errors.postal_code && formik.touched.postal_code ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="postal_code"
                            placeholder="Postal code"
                          />
                          <ErrorMessage className="auth-error" name="postal_code" component="div" />
                        </div>
                        {lat && lon && (
                          <LoadScript lat={lat} lon={lon} address={formik.values.address} region={formik.values.address} zipcode={formik.values.address} />
                        )}
                        <div className={formik.errors.url && formik.touched.url ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.url && formik.touched.url ? 'label-head-org-error' : 'label-head-org'}>
                            Add URL:
                          </label>
                          <Field
                            className={formik.errors.url && formik.touched.url ? 'input-box-org-error' : 'input-box-org'}
                            type="text"
                            name="url"
                            placeholder="URL"
                          />
                        </div>

                        <div className={formik.errors.description && formik.touched.description ? 'd-grid' : 'input-control d-grid'}>
                          <label className={formik.errors.description && formik.touched.description ? 'label-head-org-error' : 'label-head-org'}>
                            Description:
                          </label>
                          <Field
                            className={formik.errors.description && formik.touched.description ? 'input-box-org-des-error' : 'input-text-accomodation-des'}
                            as="textarea"
                            name="description"
                            placeholder="Description"
                            value={text}
                            onChange={(e) => handleTextChange(e, formik)}
                          />
                          <ErrorMessage className="auth-error" name="description" component="div" />
                          <span className="input-text-area-modal-length">
                            {!text?.length ? 500 : 500 - (text?.trim()?.split(/\s+/)?.length || 0)}
                          </span>
                        </div>

                        <div className={formik.errors.name && formik.touched.name ? 'd-grid' : 'input-control d-grid'}>
                          <label className="label-head-org">Photo:</label>
                          <Button
                            variant="outlined"
                            color="primary"
                            className="plusIconbtn"
                            sx={{
                              fontSize: '16px',
                              width: 'fit-content',
                              border: '2px solid',
                              '&:hover': {
                                border: '2px solid #fbb040',
                                background: ' #fbb040 ',
                                color: '#ffffff',
                                borderRadius: '2px solid #fbb040',
                              },
                            }}
                            onClick={handleLOgo}
                          >
                            <AddIcon id="plusICon" />
                            <span style={{ marginTop: '2px' }}>Add Photo</span>
                          </Button>
                          <span className="error mt-2" style={{ width: '248px' }}>{imgError}</span>
                          {upl && !imgError && (<span className="auth-suc">{upl}</span>)}
                        </div>
                        <div className="my-3 d-flex justify-content-center">
                          <button
                            className={formik.isValid && formik.dirty && adress ? 'updateChange' : 'updateChangeDisabled'}
                            style={{ fontWeight: '500' }}
                            type="submit"
                          >
                            Add accommodation
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default AccomodationModel;
