import React, { useEffect, useState } from 'react';
import '../GradeEventstyle.css';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import SyllabusOrg from './SyllabusOrg';
import Collaboratorsorg from './Collaboratorsorg';
import HeaderOrg from '../Headers/HeaderOrg';
import Help from '../../Components/Help/Help';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Back from '../../Components/Back/Back';
import { serviceConsumer } from '../../network/ServiceConsumer';
import VenueFies from '../CreateFeisEvent/VenueFies';
import FeesFies from '../CreateFeisEvent/FeesFies';
import PaymentFies from '../CreateFeisEvent/PaymentFies';
import { backHandler } from '../../helper/CreateEventBack';
import AboutFies from '../CreateFeisEvent/AboutFies';
import EventDetailsFies from '../CreateFeisEvent/EventDetailsFies';

const GradeOrg = () => {
  const navigate = useNavigate();
  const [params, SetParams] = useSearchParams();
  const [titleLoading, setTitleLoading] = useState(true)
  const [eventName, setEventName] = useState("")
  const ID = params.get('id') ? params.get('id') : localStorage.getItem("ID");
  const success = params.get('success');
  let TabNumber = localStorage.getItem('TAB');
  let pageNumber = localStorage.getItem('page');
  const tabValue = ID && TabNumber ? TabNumber : "1"
  const [value, setValue] = useState(tabValue);

  const handleChange = (event, newValue) => {
    localStorage.setItem('TAB', newValue);
    setValue(newValue);
  };
  const getEventDetails = async () => {
    try {
      setTitleLoading(true)
      if (ID) {
        const url = `${process.env.REACT_APP_BASE_URL}/grade-exam/${ID}`;
        const response = await serviceConsumer('GET', url);
        setEventName(response.grade_exams.name);
      }
      setTitleLoading(false)
    } catch (error) {
      setTitleLoading(false)
    }
  };

  useEffect(() => {
    getEventDetails()
    return () => {
      localStorage.removeItem('prevEvent');
    }
  }, [])


  return (
    <div>
      <HeaderOrg />
      <div className="FiesOrgMain">
        <div className="headingOrg">
          <div className="d-flex backContainer" style={{ color: '#68AD3F', cursor: 'pointer' }}>
            <Back onBackClick={() => backHandler(ID, navigate, eventName, "grade")} />
            {!titleLoading && <div className="organizerExam" style={{}}>{eventName ? eventName : "New Grade Exam"}</div>}
          </div>
        </div>
      </div>
      <div id="gradeMainTab">
        <TabContext value={value} style={{ padding: '0px' }} className="GradeOrgTab">
          <TabList onChange={handleChange} aria-label="lab API tabs example" className="GradeOrgTabList" >
            <Tab value="1" className="GradeOrgTabb" label="Event Details" />
            <Tab value="2" className={Number(pageNumber) + 1 >= 2 ? 'GradeOrgTabb' : 'pe-none'} label="Venue Details" />
            <Tab value="3" className={Number(pageNumber) + 1 >= 3 ? 'GradeOrgTabb' : 'pe-none'} label="Payment" />
            <Tab value="4" className={Number(pageNumber) + 1 >= 4 ? 'GradeOrgTabb' : 'pe-none'} label="Fees" />
            <Tab value="5" className={Number(pageNumber) + 1 >= 5 ? 'GradeOrgTabb' : 'pe-none'} label="Syllabus" />
            <Tab value="6" className={Number(pageNumber) + 1 >= 6 ? 'GradeOrgTabb' : 'pe-none'} label="About" />
            <Tab value="7" className={Number(pageNumber) + 1 >= 7 ? 'GradeOrgTabb' : 'pe-none'} label="Collaborators" />
          </TabList>
          <div className="hr-org-title" style={{ marginTop: '-2px' }} />
          <div className="create-feis-content">
            <TabPanel value="1" id="TabSize" className="Tabpanel">
              <EventDetailsFies setValue={setValue} setEventName={setEventName} type="grade" />
            </TabPanel>
            <TabPanel value="2" id="TabSize" >
              <VenueFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="3" id="TabSize">
              <PaymentFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="4" id="TabSize">
              <FeesFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="5" id="TabSize">
              <SyllabusOrg setValue={setValue} />
            </TabPanel>
            <TabPanel value="6" id="TabSize">
              <AboutFies setValue={setValue} type="grade" />
            </TabPanel>
            <TabPanel value="7" id="TabSize">
              <Collaboratorsorg setValue={setValue} />
            </TabPanel>
          </div>
        </TabContext>
        <Help />
      </div>
    </div>
  );
};

export default GradeOrg;
