export const percentageData = [
  {
    key: '40',
    value: '40',
  },
  {
    key: '50',
    value: '50',
  },
  {
    key: '60',
    value: '60',
  },
];

export const MinNumAward = [
  {
    key: '3',
    value: '3',
  },
  {
    key: '4',
    value: '4',
  },
  {
    key: '5',
    value: '5',
  },
];

export const gradeCapData = [
  {
    key: 'No Cap',
    value: 'No Cap',
  },
  {
    key: 'Global Cap',
    value: 'Global Cap',
  },
];

export const feisCapData = [
  {
    key: 'No Cap',
    value: 'No Cap',
  },
  {
    key: 'Global Cap',
    value: 'Global Cap',
  },
  {
    key: 'Level Cap',
    value: 'Level Cap',
  },
];

export const lavelCap = [
  {
    key: 'Grades',
    subKey: '(Beginner, Advanced Beginner, Novice, Prizewinner)',
    value: 'Grades(Beginner, Advanced Beginner, Novice, Prizewinner)',
  },
  {
    key: 'Champ',
    subKey: '(Preliminary and Open Championships)',
    value: 'Champ(Preliminary and Open Championships)',
  },
];