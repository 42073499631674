import React, { useRef, useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Loader from '../../Components/Loader/Loader';
import { useNavigate } from 'react-router-dom';
import feisLogo from '../../Assets/feis-logo.svg';
import './style.css';
import axios from 'axios';
import FormikControl from '../../Components/formik/FormikControl';
import { useDispatch, useSelector } from 'react-redux';
import { SIGNIN } from '../../redux/actionTypes/authActionType';
import { ToastContainer, toast } from 'react-toastify';
import Help from '../../Components/Help/Help';
import { ShowToastMessage } from '../../helper/ShowToastMessage';
import { emailValidation, loggedInPwdValidation } from '../../helper/Validations';
// import NewBrandInfo from '../../Components/NewBrandInfo/NewBrandInfo';

export const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selector = useSelector((state) => state);

  //get event details to pass to the event page
  const getEventDetails = async (path, accessToken, name) => {
    const feisurl = `${process.env.REACT_APP_BASE_URL}/feis/${localStorage.getItem('eventId')}`;
    const gradeurl = `${process.env.REACT_APP_BASE_URL}/grade-exam/${localStorage.getItem('eventId')}`;
    const url = localStorage.getItem('eventType') === 'Feiseanna' ? feisurl : gradeurl;
    const config = {
      headers: {
        Authorization: 'Basic ' + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
        accesstoken: localStorage.getItem('accessToken'),
      },
    };

    try {
      const response = await axios.get(url, config);
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('userFirstName', name);
      const event =
        localStorage.getItem('eventType') === 'Feiseanna'
          ? response?.data?.Feis
          : response?.data?.grade_exams;
      if (localStorage.getItem('isCompetitor') === 'true') {
        navigate(`${localStorage.getItem('path')}`, { state: { event: event, from: localStorage.getItem('from') }, replace: true, });
      } else {
        navigate(`${localStorage.getItem('path')}`, {
          state: { event: event, from: localStorage.getItem('from') }, replace: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //navigate to login page
  const goToSignUpPage = () => {
    navigate('/signup');
  };
  const goToForgetPasswordPage = () => {
    navigate('/forgot-password');
  };

  const signInFormik = useRef();

  //Initial values for formik
  const signInInitialValues = {
    email: '',
    password: '',
  };

  //Validation Schema for Formik
  const signInValidationSchema = Yup.object().shape({
    email: emailValidation,
    password: loggedInPwdValidation,
  });

  //API for parent login
  const signIn = async (values, formikHelpers) => {
    let value = { ...values, email: values.email.toLowerCase() };
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/login`;
      const config = {
        headers: {
          Authorization: 'Basic ' + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
        },
      };
      const response = await axios.post(url, value, config);
      if (response.data.success === true) {
        formikHelpers.setSubmitting(false);
        setLoading(false);
        const name =
          response.data.fname.charAt(0).toUpperCase() +
          response.data.fname.slice(1, response.data.fname.length);
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('userFirstName', name);
        localStorage.setItem('isTeacher', response?.data?.teacher)
        if (localStorage.getItem("deletedEvent") === "deleted") {
          navigate("/temporary-registration")
        }
        else if (localStorage.getItem("redirectFromOrg") === "redirect") {
          navigate(localStorage.getItem("orgPath"), { replace: true })
        }
        //if login redirected from an event redirect to that event
        else if (localStorage.getItem('path')) {
          let path = localStorage.getItem('path');
          getEventDetails(path, response.data.accessToken, name);
        } else {
          navigate('/parent', { replace: true });
        }

        setTimeout(() => {
          localStorage.clear();
        }, 86400000);
        dispatch({
          type: SIGNIN,
          payload: response.data,
        });
      } else {
        setLoading(false);
        toast.dismiss();
        setError();
        ShowToastMessage("error", response.data.message)
      }
    } catch (error) {
      setLoading(false);
      setError();
      ShowToastMessage("error", error.response.data.message)
      formikHelpers.setSubmitting(false);
    }
  };
  const changeHandler = (e, name, setFieldValue) => {
    setFieldValue(name, e.target.value);
    setError('');
  };

  //setting document Title
  useEffect(() => {
    document.title = 'Feis.Link - Home';
  }, []);

  return (
    <>
      {loading && <Loader />}
      {/* <NewBrandInfo /> */}
      <div className="d-flex flex-column justify-content-between" style={{ height: '100vh' }}>
        <div>
          <img src={feisLogo} alt="" className="m-4 feis-logo" onClick={() => navigate('/')} />
        </div>
        <div className="d-flex justify-content-center">
          <Formik
            initialValues={signInInitialValues}
            validationSchema={signInValidationSchema}
            onSubmit={signIn}
            innerRef={signInFormik}
          >
            {(formik) => (
              <Form className="form form-label-right" autoComplete="off">
                <div className="login-form-signIn">
                  <div className="email-form-signIn">
                    <h4 className="title-signin">Parent Log In</h4>
                    <br />
                    <div>
                      <FormikControl
                        className="email-password"
                        control="input"
                        type="email"
                        name="email"
                        placeHolder="Email"
                        style={{ padding: '9px 20px' }}
                        onChange={(e) => { changeHandler(e, 'email', formik.setFieldValue); }}
                      />
                      <FormikControl
                        className="email-password"
                        control="input"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeHolder="Password"
                        style={{ padding: '9px 20px' }}
                        onChange={(e) => { changeHandler(e, 'password', formik.setFieldValue); }}
                      />
                    </div>
                    {error && <div className="error">{error}</div>}
                    <br />
                    <div>
                      <button className="filled mt-0">Login</button>
                    </div>
                    <br /><hr /><br />
                    <div className=" sign-up-forgot-pwd-div d-flex justify-content-between">
                      <div className="sign-up-forgot-pwd-btn" onClick={() => { goToForgetPasswordPage(); }}>Forgot your password?</div>
                      <div
                        className="sign-up-forgot-pwd-btn"
                        style={{ color: '#68AD3F', fontWeight: 700, }}
                        onClick={() => { goToSignUpPage(); }}
                      >
                        Sign up instead
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <Help />
        <ToastContainer />
      </div>
    </>
  );
};

export default SignIn;
