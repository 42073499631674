import moment from "moment";
import { formatDate } from "../../helper/formatDate";

export const getServiceFee = (amount) => {
  let serviceAmount = 0;
  if (amount <= 100) {
    serviceAmount = 10;
  } else if (amount > 100 && amount <= 200) {
    serviceAmount = Math.round(amount / 10);
  } else serviceAmount = 20;

  return serviceAmount;
};

export const getEditServiceFee = (amount) => {
  return Number(Number((amount / 20).toFixed(2)));
};

export const getFamilyFess = (eventDetails) => {
  if (eventDetails) {
    const familyLess = eventDetails?.fees[0]?.earlyFee?.familyLess;
    const lateFee = eventDetails?.fees[0]?.lateFee?.additionalFee;

    let familyfee = eventDetails.fees[0]?.familyfee;
    const now = moment().startOf("day");

    const beforeDate = moment(
      formatDate(eventDetails?.fees[0]?.earlyFee?.ifDateIsBefore)
    ).startOf("day");
    if (now.isSameOrBefore(beforeDate)) {
      familyfee -= familyLess;
    }
    const afterDate = moment(
      formatDate(eventDetails?.fees[0]?.lateFee?.afterDate)
    ).startOf("day");

    console.log("TODAY", now);
    console.log("BEFORE DATE", beforeDate);
    console.log("DISCOUNT APPLIED", now.isSameOrBefore(beforeDate));
    console.log("AFTER DATE", afterDate);
    console.log("EXTRA FEE APPLIED", now.isSameOrAfter(afterDate));

    if (now.isSameOrAfter(afterDate)) {
      familyfee += lateFee;
    }
    return familyfee;
  }
  return 0;
};
