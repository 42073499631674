export const routesArray = [
  '/parent',
  '/add-dancers',
  '/my-account',
  '/parent/account/profile',
  '/parent/transaction-history',
  '/feis/:name',
  '/register-dancers',
  '/checkout',
  '/parent/feis/register/payment/success',
  '/edit-feis/:name',
  '/feis-edit-dancers/:id',
  '/checkout',
  '/parent/feis/edit-register/payment/success',
  '/grade/:id',
  '/register-grade-exam',
  '/checkout',
  '/parent/grade/register/payment/success',
  '/edit-grade/:name',
  '/grade-edit-dancers/:id',
  '/checkout',
  '/parent/grade/edit-register/payment/success',
  '/results',
  '/results/:name',
  'view-results/:name',
  '/competitors/:name',
  '/organizer-create-grade',
  '/organizer-create-feisanna',
  '/organizer-create-grade',
  '/organiser-dasboard/createevent-grade/finalGradeDetails',
  '/organiser-dasboard/createevent-feisanna/finalfiesDetails',
  '/previous-events',
  '/organizer-dashboard',
  '/organizer-manage-feis',
  '/organizer-manage-grade',
  '/competitorss/:name',
  '/candidate/:name',
  '/edit-competitors/:name',
  '/my-account-org',
  '/organizer-account',
  '/organizer-Transaction',
  '/edit-register/:name',
  '/merge-split/:name',
  '/merge-split-comp/:name',
  '/email',
  '/event-details',
  '/check-in/:name',
  '/result-status/:name',
  '/tabulate/:name',
  '/complete-results/:name',
  '/score-checkin-download/:name',
  '/transaction-history-org/:name',
];
