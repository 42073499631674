import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import AdjucatorModel from '../../Components/Modals/OrganizerModel/AdjucatorModel';
import { Form, Formik } from 'formik';
import { serviceConsumer } from '../../network/ServiceConsumer';
import { useNavigate } from 'react-router-dom';
import MusicianModel from '../../Components/Modals/OrganizerModel/MusicianModel';
import AccomodationModel from '../../Components/Modals/OrganizerModel/AccomodationModel';
import Loader from '../../Components/Loader/Loader';
import { useDispatch } from 'react-redux';
import AddSponserModel from '../../Components/Modals/OrganizerModel/AddSponserModel';
import MainDiv from '../../Components/AddedAdjdiv/MainDiv';
import AccomodationDiv from '../../Components/AddedAdjdiv/AccomodationDiv';
import AddButton from '../../Components/AddButton/AddButton';
import * as Yup from 'yup';
import { accessTillSyllabus } from '../../helper/CreateDispatches';

const CollaboratorFies = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    accessTillSyllabus(dispatch)
  }, []);

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openModalMusician, setOpenModalMusician] = useState(false);
  const [openModalAccomodation, setopenModelAccomodation] = useState(false);
  const [openModalsponser, setopenModelsponser] = useState(false);
  const [adjucatorIds, setadjucatorIds] = useState([]);
  const [sponserIds, setSponsersIds] = useState([]);
  const [musicianId, setMusicianId] = useState([]);
  const [accomdationsids, setAccomodationIds] = useState([]);

  const [getAdd, setgetAdd] = useState([]);
  const [getmus, setgetmus] = useState([]);
  const [adj, setAdj] = useState([]);
  const [adjucator, setAdjucator] = useState([]);
  const [accomodation, setaccomodation] = useState([]);
  const [getaccomadationdata, setaccomodationdata] = useState(false);
  const [mus, setmus] = useState([]);
  const [spn, setspn] = useState([]);
  const [sponsor, setSponser] = useState([]);
  const [musician, setMusician] = useState([]);
  const [eventDetails, setEventDetails] = useState([]);
  const [loading, setLoading] = useState();
  const [acccmodataionVal, setaccomodationVal] = useState([]);
  const [initialRender, setInitialRender] = useState(true);

  const [showAdjucator, setShowAdjucator] = useState(false);
  let EventID = localStorage.getItem('ID');

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModalMusician = () => {
    setOpenModalMusician(!openModalMusician);
  };

  const handleOpenModelAccomodation = () => {
    setopenModelAccomodation(!openModalAccomodation);
  };

  const handleOpenModelSponser = () => {
    setopenModelsponser(!openModalsponser);
  };

  const getAdjucators = async () => {
    try {
      if (!initialRender) {
        setLoading(true);
      }
      const url = `${process.env.REACT_APP_BASE_URL}/people/${EventID}/adjudicators`;
      const response = await serviceConsumer('GET', url);
      setAdj(response.people);
      setMusicianId((prev) => [...prev, response?.people?._id]);
      setgetAdd(response.person);
      const arr = response.people.map((men) => men._id);
      setadjucatorIds(arr);

      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getMusician = async () => {
    try {
      if (!initialRender) {
        setLoading(true);
      }
      const url = `${process.env.REACT_APP_BASE_URL}/people/${EventID}/musicians`;
      const response = await serviceConsumer('GET', url);
      setMusicianId((prev) => [...prev, response?.people?._id]);
      const musArr = response.people.map((men) => men._id);
      setMusicianId(musArr);
      setmus(response.people);

      setgetmus(response.person);
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getSponser = async () => {
    try {
      if (!initialRender) {
        setLoading(true);
      }
      const url = `${process.env.REACT_APP_BASE_URL}/people/${EventID}/sponsors`;
      const response = await serviceConsumer('GET', url);
      setspn(response.people);
      const musArr = response.people.map((men) => men._id);
      setSponsersIds(musArr);
      setSponser(response.person);
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async (id, from) => {
    if (from === 'adjucators') {
      try {
        const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/delete/${EventID}`;
        await serviceConsumer('DELETE', url);
        getAdjucators();
      } catch (error) {
        setLoading(false);
      }
      setLoading(true);
      const newArray = adjucator?.splice(id, 1);
      setAdjucator(newArray);
    } else if (from === 'musician') {
      const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/delete/${EventID}`;
      await serviceConsumer('DELETE', url);
      getMusician();
      const newArray = musician.splice(id, 1);
      setMusician(newArray);
      setLoading(true);
    } else if (from === 'sponser') {
      const url = `${process.env.REACT_APP_BASE_URL}/people/${id}/delete/${EventID}`;
      await serviceConsumer('DELETE', url);
      getSponser();
      setLoading(true);
    } else if (from === 'acccmodataion') {
      setLoading(true);
      try {
        setLoading(true);
        if (EventID) {
          const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}/del/accomdations/${id}`;
          const response = await serviceConsumer('DELETE', url);
          setaccomodationdata(!acccmodataionVal);
          accomodationData();
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const getEventDetails = async () => {
    try {
      if (!initialRender) {
        setLoading(true);
      }
      if (EventID) {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`;
        const response = await serviceConsumer('GET', url);
        setEventDetails(response.Feis);
        setgetAdd(response.Feis.adjudicators);
      }
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }

    if (musician?.length > 0) {
      try {
        setLoading(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    if (sponsor?.length > 0) {
      try {
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const accomodationData = async () => {
    try {
      setLoading(true);
      if (EventID) {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/accomdations/${EventID}`;
        const response = await serviceConsumer('GET', url);
        const accArr = response.Accomdations.map((men) => men._id);
        setAccomodationIds(accArr);
        setaccomodationVal(response.Accomdations || []);
        setaccomodation(response.Feis);
        setgetAdd(response.Feis.adjudicators);
      }
      if (!initialRender) {
        setLoading(false);
      }
    } catch (error) {
      if (!initialRender) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setInitialRender(false);
    setLoading(true);
    Promise.all([getAdjucators(), getMusician(), getSponser(), getEventDetails(), accomodationData()]).then(res => setLoading(false)).catch(err => setLoading(false))
  }, [])

  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => { getAdjucators(); }, 300);
    }
  }, [adjucator]);

  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => { getMusician(); }, 300);
    }
  }, [musician]);

  useEffect(() => {
    if (!initialRender) {
      setTimeout(() => { getSponser(); }, 300);
    }
  }, [sponsor]);

  useEffect(() => {
    if (!initialRender) {
      getEventDetails();
    }
  }, [adjucator, musician, sponsor, getaccomadationdata]);

  // submitHandler
  const finishHandler = async () => {
    let val = {
      adjudicators: adjucatorIds.filter(item => item != null),
      sponsors: sponserIds.filter(item => item != null),
      musicians: musicianId.filter(item => item != null),
      page: 7,
    };
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/feis/${EventID}`;
      const response = await serviceConsumer('PUT', url, val);
      if (response.success === true) {
        localStorage.setItem('page', 7);
        localStorage.removeItem('previousEvent');
        navigate('/organiser-dasboard/createevent-feisanna/finalfiesDetails');
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const collaboratorInitialValues = {
  };

  return (
    <div className="AboutOrg">
      <Container className="px-0">
        {loading && <Loader />}
        <Formik
          initialValues={collaboratorInitialValues}
        >
          {() => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="syllabusMain">
                <div className="">
                  <div className="detailsHeaders">
                    <h4 className="eventPage">Complete collaborators</h4>
                    <p className="eventDescription"> Adjudicators, Sponsors, And others.</p>
                  </div>
                  <label className="eventGradeColabLabel">Adjudicators:</label>
                  <br />
                  {adj?.length > 0 &&
                    adj.map((adjucators, index) => {
                      return (
                        <>
                          <MainDiv
                            name={adjucators?.name}
                            location={adjucators?.location}
                            state={adjucators?.state}
                            index={index}
                            photo={adjucators?.photo}
                            setAdjucator={setAdjucator}
                            adjucator={adjucator}
                            adj={adj}
                            setAdj={setAdj}
                            onDelete={onDelete}
                            id={adjucators._id}
                            from={'adjucators'}
                          />
                        </>
                      );
                    })}
                  <AddButton className="plusIconbtn" onClick={handleOpenModal}>Add</AddButton>
                  <br />
                  <label className="eventGradeColabLabel ">Musicians:</label>
                  <br />
                  {mus?.length > 0 &&
                    mus.map((musician, index) => {
                      return (
                        <>
                          <MainDiv
                            name={musician?.name}
                            location={musician?.location}
                            index={index}
                            photo={musician?.photo}
                            setMusician={setMusician}
                            onDelete={onDelete}
                            id={musician._id}
                            from={'musician'}
                          />
                        </>
                      );
                    })}
                  <AddButton className="plusIconbtn" onClick={handleOpenModalMusician}>Add</AddButton>
                  <br />
                  <label className="eventGradeColabLabel mt-3">
                    Accommodations:
                  </label>
                  <br />
                  {acccmodataionVal?.length > 0 &&
                    acccmodataionVal?.map((accomodation, index) => {
                      return (
                        <>
                          <AccomodationDiv
                            name={accomodation?.name}
                            location={accomodation?.location}
                            index={index}
                            photo={accomodation?.photo}
                            city={accomodation?.city}
                            country={accomodation?.country}
                            state={accomodation?.state}
                            setSponser={setSponser}
                            from={'acccmodataion'}
                            onDelete={onDelete}
                            id={accomodation._id}
                          />
                        </>
                      );
                    })}
                  {acccmodataionVal?.length < 5 && (
                    <AddButton className="plusIconbtn" onClick={handleOpenModelAccomodation}>Add</AddButton>
                  )}
                  <br />
                  <label className="eventGradeColabLabel mt-3"> Sponsors:{' '}</label>
                  <br />
                  {spn?.length > 0 &&
                    spn?.map((sponser, index) => {
                      return (
                        <>
                          <MainDiv
                            name={sponser?.name}
                            location={sponser?.location}
                            index={index}
                            photo={sponser?.photo}
                            sponsor={sponsor}
                            setSponser={setSponser}
                            from={'sponser'}
                            id={sponser._id}
                            onDelete={onDelete}
                          />
                        </>
                      );
                    })}
                  <AddButton className="plusIconbtn" onClick={handleOpenModelSponser}>Add</AddButton>
                  <div className="mt-3 mb-5">
                    <button onClick={finishHandler} className="filled"> Finish </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
      {openModal && (
        <AdjucatorModel
          modalState={openModal}
          action={handleOpenModal}
          showAdjucator={showAdjucator}
          setAdj={setAdj}
          setAdjucator={setAdjucator}
          getAdjucators={getAdjucators}
          setadjucatorIds={setadjucatorIds}
          adjucator={adjucator}
          adj={adj}
          from="feis"
        />
      )}
      {openModalMusician && (
        <MusicianModel
          modalState={openModalMusician}
          setOpenModalMusician={setOpenModalMusician}
          action={handleOpenModalMusician}
          showAdjucator={showAdjucator}
          getMusician={getMusician}
          mus={mus}
          musician={musician}
          setMusician={setMusician}
          from="feis"
        />
      )}
      {openModalAccomodation && (
        <AccomodationModel
          modalState={openModalAccomodation}
          action={handleOpenModelAccomodation}
          showAdjucator={showAdjucator}
          setaccomodationdata={setaccomodationdata}
          setgetAdd={setgetAdd}
          accomodationData={accomodationData}
        />
      )}
      {openModalsponser && (
        //instead of SponserModel added AddSponserModel
        <AddSponserModel
          action={handleOpenModelSponser}
          modalState={handleOpenModelSponser}
          showAdjucator={showAdjucator}
          setopenModelsponser={setopenModelsponser}
          sponsor={sponsor}
          getSponser={getSponser}
          from="feis"
        />
      )}
    </div>
  );
};

export default CollaboratorFies;
