import React from 'react';
import successImage from '../../../../../Assets/addedCompetitor.svg';
import Modal from '@mui/material/Modal';
import closeIcon from '../../../../../Assets/close-icon.svg';
import { useDispatch } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';

const AddedCompetitor = ({ modalState, schedule }) => {
  const ManageType = localStorage.getItem('Manage');

  const dispatch = useDispatch();
  const modalCloseHandler = () => {
    dispatch({
      type: SET_MODAL,
      payload: {
        modal: '',
        eventProp: '',
      },
    });
  };
  return (
    <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', }} open={modalState}>
      <div className="org-modal-div">
        <div className="close-div mb-1">
          <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
        </div>
        <div className="d-flex flex-column align-items-center added-comp">
          <img src={successImage} style={{ width: '170px', height: '172px' }} alt="" />
          {ManageType == 'feis' ? (
            <p style={{ fontSize: '22px', fontWeight: '500', color: '#323232', textAlign: 'center', }} className="mt-5">
              {schedule ? "Schedule published successfully!" : "Competitor Added Successfully!"}
            </p>
          ) : (
            <p style={{ fontSize: '22px', fontWeight: '500', color: '#323232', textAlign: 'center', }} className="mt-5">
              Candidate Added Successfully!
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddedCompetitor;
