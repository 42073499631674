import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import closeIcon from "../../../Assets/close-icon.svg";
import photoPlaceholder from "../../../Assets/photoPlaceholder.png"

const AcommodationModal = ({ accomodation, setOpen, open }) => {
    const handleClose = () => setOpen(false);
    const goToOpenMaps = (latitude, longitude) => {
        const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(mapsUrl, '_blank');
    };
    const dynamicUrl = accomodation?.url;
    const redirectHandler = () => {
        const formattedUrl = dynamicUrl && dynamicUrl.startsWith('http') ? dynamicUrl : `https://${dynamicUrl}`;
        window.open(formattedUrl, '_blank');
    }
    return (
        <Modal
            open={open}
            className="addDancerScroll d-flex justify-content-center align-items-center"
        >
            <Box className="addDancerScrollBox">
                <div className="acc-outer-div" >
                    <div className="acc-div">
                        <div className="d-flex">
                            <div className="col-11">
                                <p style={{ fontweight: "400", fontSize: "24px", color: "#323232", marginBottom: "0" }}>{accomodation?.name}</p>
                                <p style={{ fontweight: "400", fontSize: "18px", color: "#68AD3F", borderRadius: "2px solid white", }}>{accomodation?.address}</p>
                            </div>
                            <div className="d-flex flex-grow-1 align-items-start justify-content-end">
                                <img src={closeIcon} alt="close-icon" className="close-icon" onClick={handleClose} />
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-md-row  gap-2">
                            <div className="col-6 offset-3 offset-md-0 mb-5 mb-md-0 col-md-6 col-lg-6 ">
                                <img style={{ width: "100%" }} src={accomodation?.photo ? accomodation?.photo : photoPlaceholder} alt="logo" />
                            </div>
                            <div className="col-md-6 col-lg-6 overflow-scroll acc-desc" >
                                <div style={{ textAlign: "justify", color: "#2d2d2d" }}>{accomodation?.description}</div>
                            </div>
                        </div>
                        <div className="d-flex offset-md-6 my-2 align-items-center">
                            <button className="outlined mx-md-2 my-2" onClick={() => { goToOpenMaps(accomodation?.latitude, accomodation?.longitude); }}>Open in Maps</button>
                            {dynamicUrl && <a className="text-btn ms-2" onClick={redirectHandler} target="_blank" >
                                Website
                            </a>}

                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default AcommodationModal;