import React, { useState, useEffect } from "react";
import TabPanel from "@mui/lab/TabPanel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import AddButton from "../../Components/AddButton/AddButton"
import Loader from "../../Components/Loader/Loader";
import { serviceConsumer } from "../../network/ServiceConsumer"
import EditDancerModal from "../../Components/Modals/EditDancerModal";
import AddDancerModal from "../../Components/Modals/AddDancerModal";
import "./Style.css";
import ConfirmDeleteModal from "../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal";

const Dancers = () => {
  const [allDancer, setAllDancer] = useState([]);
  const [editDancerDetails, setEditDancerDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setopenModalEdit] = useState(false);
  const [dancer, setDancer] = useState();
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [dancerId, setDancerId] = useState(false)

  const UpdateDancer = (status = false) => {
    if (status) getAllEvents();
  };

  const actionEdit = () => {
    setopenModalEdit(!openModalEdit);
  };

  const getAllEvents = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/account/dancers`;
    try {
      const response = await serviceConsumer("GET", url)
      setAllDancer(response.dancers)
    } catch (error) {
    }
  };

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const DeleteDancer = async (id) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/account/dancers/${id}`;
      const response = await serviceConsumer("DELETE", url);
      getAllEvents();
      setOpenDeleteModal(false)
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [editDancerDetails]);

  const editIconClickHandler = (dancer) => {
    setopenModalEdit(!openModalEdit);
    setDancer(dancer);
    let allDancerArr = [...allDancer];

    const filtereddancer = allDancerArr.filter((dancerId) => {
      if (dancerId._id === dancer._id) {
        setEditDancerDetails(dancerId);
      }
    });
    setAllDancer(allDancerArr);
  };

  const openModalHandler = (dancerId) => {
    setOpenDeleteModal(true)
    setDancerId(dancerId)
  }

  return (
    <TabPanel value="3">
      {loading && <Loader />}
      <div>
        <h4 className="title-signin grey-42"> Dancers</h4>
        <p className="grey-42">Manage your dancers.</p>
        <hr /> <br />
      </div>
      {allDancer.map((dancer) => {
        return (
          <div className="dancers-profile">
            <div className="d-flex justify-content-between">
              <p className="dancer-name">{dancer.fname} {dancer.lname}</p>
              <button style={{ border: "none", background: "white", float: "right", display: "flex", gap: "5px", }}>
                <DeleteOutlineOutlinedIcon
                  className="deleteIcon-dancer"
                  onClick={() => openModalHandler(dancer._id)}
                />
                <EditIcon
                  className="editIcon-dancer"
                  onClick={() => { editIconClickHandler(dancer); }}
                />
              </button>
            </div>
            <p className="m-1 mb-2 dancer-bday">{dancer.birthday}</p>
            <p className="m-1 dancer-school">{dancer.school}</p>
          </div>
        );
      })}
      <AddButton className={allDancer.length <= 4 ? "plusIconbtn mt-4 mb-5" : "updateChangeDisabled mt-4 mb-5"} onClick={handleOpenModal}>
        {allDancer.length > 0 ? "Add dancers" : "Add dancer"}
      </AddButton>

      {dancer && (
        <EditDancerModal
          modalState={openModalEdit}
          edit={editIconClickHandler}
          action={actionEdit}
          dancer={dancer}
          setDancer={setDancer}
          editDancerDetails={editDancerDetails}
        />
      )}
      {openDeleteModal && <ConfirmDeleteModal
        modalState={openDeleteModal}
        confirm={() => DeleteDancer(dancerId)}
        cancel={() => setOpenDeleteModal(false)}
        dancerPage />}

      <AddDancerModal
        modalState={openModal}
        action={handleOpenModal}
        UpdateDancer={UpdateDancer}
      />
    </TabPanel>
  );
};

export default Dancers;
