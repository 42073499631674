import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import photoPlaceholder from '../../Assets/photoPlaceholder.png';
const AccomodationDiv = ({ name, onDelete, country, photo, from, id, }) => {
  return (
      <div className="AddedAdj-dec ">
        <div className="leftDivAdj ">
          <div className="adjImg-adj">
            <div style={{ height: "100px" }}>
              <img src={photo || photoPlaceholder} />
            </div>
            <div className="adjDes">
              <span className="adjNAme">{name}</span>
              <span className="adjCoun"style={{ textTransform: 'uppercase' }}>{country}</span>
            </div>
          </div>
        </div>
        <div className="rightDivAdj">
          {from != 'golive' && ( <DeleteOutlineIcon onClick={() => {onDelete(id, from);}}/>)}
        </div>
      </div>
  );
};

export default AccomodationDiv;
