import React, { useState, useEffect } from "react";
import ParentHeader from "../ParentHeader/ParentHeader";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "./style.css";
import ViewResultsTable from "./ViewResultsTable";
import { serviceConsumer } from "../../network/ServiceConsumer";
import CommentBox from "./CommentBox";
import Help from "../../Components/Help/Help";
import { groupAndSortComp, sortCompetitions } from "../../helper/SortSyllabus";
import { formatDate } from "../../helper/formatDate";
import { levelsArray } from "../../helper/DataArrays";

const EventResults = () => {
  const [allDancers, setAllDancers] = useState([]);
  const [selectedDancerId, setSelectedDancerId] = useState("");
  const [competitions, setCompetitions] = useState([]);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState("");
  const [results, setResults] = useState([]);
  const [scoresArr, setScoresArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [event, setEvent] = useState();
  const [rounds, setRounds] = useState(0);
  const [adjudicators, setAdjudicators] = useState("");
  const [tableDataKey, setTableDataKey] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [dancerDetail, setDancerDetail] = useState();
  const [competition, setCompetition] = useState();
  const [filteredComp, setFilteredComp] = useState();
  const [unfilteredSortedComps, setUnfilteredSortedComps] = useState();
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [level, setLevel] = useState("All");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("id");
  const isUserLoggedIn = localStorage.getItem("accessToken");

  useEffect(() => {
    setInitialLoading(true);
    window.scroll(0, 0);
    getInitialDetails();
  }, []);

  useEffect(() => {
    eventId && selectedCompetitionId != "" && getResults();
  }, [selectedCompetitionId]);

  useEffect(() => {
    isUserLoggedIn &&
      results?.forEach((item) => {
        if (item.dancer_id === selectedDancerId) {
          setScoresArr(item?.scores);
          setDancerDetail(item);
        }
      });
  }, [results]);

  //button handler
  const backToViewEvent = () => {
    window.history.back();
  };

  const getInitialDetails = async () => {
    try {
      const eventUrl = isUserLoggedIn
        ? `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`
        : `${process.env.REACT_APP_BASE_URL}/feis/single/${eventId}`;
      const eventResponse = await serviceConsumer("GET", eventUrl);
      setEvent(eventResponse.Feis);
      document.title = `Feis.Link - ${eventResponse?.Feis?.name}`;
      let filteredDancers;
      //dancer list shows only for a logged in user
      if (isUserLoggedIn) {
        const dancersUrl = `${process.env.REACT_APP_BASE_URL}/account/get-registered-dancers?feisId=${eventId}`;
        const dancersResponse = await serviceConsumer("GET", dancersUrl);
        filteredDancers = dancersResponse.dancers.filter(
          (dan) => dan.isRegistered
        );
        setAllDancers(filteredDancers);
        setSelectedDancerId(filteredDancers[0]?._id);
      }
      const competitionsUrl = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}/competition`;
      const competitionsResponse = await serviceConsumer(
        "GET",
        competitionsUrl
      );
      const sortedCompetitions = groupAndSortComp(
        competitionsResponse?.competitions
      );
      console.log(sortedCompetitions);
      setUnfilteredSortedComps(sortedCompetitions);
      if (isUserLoggedIn && (selectedDancerId || filteredDancers[0]?._id))
        setCompetitionDropdown(sortedCompetitions, filteredDancers[0]?._id);
      else setCompetitionDropdown(sortedCompetitions);
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
    }
  };

  //filter the results in which the selected dancer has participated and the result is published
  const setCompetitionDropdown = (competitions, dancerId = "") => {
    //for logged in users, filter for the selected dancer along with published results
    let filteredCompetitions;
    if (dancerId)
      filteredCompetitions = competitions.filter(
        (comp) =>
          comp.results_published === true && comp.dancers.includes(dancerId)
      );
    else
      filteredCompetitions = competitions.filter(
        (comp) => comp.results_published === true
      );
    setCompetitions(filteredCompetitions);
    setCompStates(filteredCompetitions, "all");
  };

  const setCompStates = (comp, level) => {
    setLevel(level);
    setFilteredComp(comp); //initially when all level is selected
    setActiveAccordionKey(comp.length > 0 && comp[0]._id);
    setCompetitionDetails(comp.length > 0 && comp[0]);
  };

  //get results when competition id is selected
  const getResults = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/scores/${selectedCompetitionId}`;
    try {
      setLoading(true);
      const response = await serviceConsumer("GET", url);
      setResults(response?.results);
      setError("");
      if (response?.results?.length === 0) {
        setError(response?.message);
        setScoresArr([]);
      }
      setScoresArrFn(selectedDancerId, response?.results);
      setTimeout(() => setLoading(false), 500);
    } catch (error) {
      setLoading(false);
    }
  };

  const selectDancerHandler = (e) => {
    setSelectedDancerId(e.target.value);
    setCompetitionDropdown(unfilteredSortedComps, e.target.value);
    setScoresArrFn(e.target.value, results); //whenever dancer changed, scoresArr is changed
  };

  const handleAccordionSelect = (compId) => {
    setActiveAccordionKey(compId === activeAccordionKey ? null : compId);
    let currentCompetition = competitions.find((item) => item._id === compId);
    setCompetitionDetails(currentCompetition);
  };

  const setCompetitionDetails = (competition) => {
    setCompetition(competition);
    setSelectedCompetitionId(competition?._id);
    setRounds(competition?.rounds);
    setAdjudicators(competition?.adjudicators);
    setTableDataKey((prevKey) => prevKey + 1);
  };

  const setScoresArrFn = (competitorId, passedResults) => {
    const dancerResult = passedResults.find(
      (item) => item?.dancer_id === competitorId
    );
    if (dancerResult) {
      setScoresArr(dancerResult?.scores);
      setDancerDetail(dancerResult);
    } else {
      setScoresArr([]);
      setDancerDetail({});
    }
  };
  const getLevelArray = () => {
    const compLevels = competitions.map((item) => item.group);
    const result = levelsArray.filter((item) => compLevels.includes(item));
    return ["ALL", ...result];
  };

  const levelChangeHandler = (e) => {
    const selectedLevel = e.target.value.toLowerCase();
    if (selectedLevel === "all") {
      setCompStates(competitions, e.target.value);
    } else {
      let filteredComp = [];
      competitions.forEach((item) => {
        if (item.group.toLowerCase() === selectedLevel) filteredComp.push(item);
      });
      setCompStates(filteredComp, e.target.value);
    }
  };

  return (
    <div style={{ backgroundColor: "#FAFAFA" }}>
      {loading && <Loader />}
      {initialLoading && <Loader />}
      <ParentHeader />
      {!initialLoading && (
        <>
          <div className="d-flex justify-content-center  view-header">
            <div className="d-flex  flex-grow-1 ">
              <div className="flex-grow-1">
                <div
                  className="d-flex align-items-center"
                  onClick={backToViewEvent}
                  style={{
                    color: "#68AD3F",
                    cursor: "pointer",
                    width: " min-content",
                  }}
                >
                  <div>
                    {" "}
                    <NavigateNextIcon
                      style={{ transform: "rotate(180deg)" }}
                    />{" "}
                  </div>
                  <div
                    style={{
                      marginLeft: "5px",
                      fontWeight: "500",
                      fontSize: "16px",
                      marginTop: "2px",
                    }}
                  >
                    Back
                  </div>
                </div>
                <div className="d-flex header-mobile">
                  <div className="d-flex flex-grow-1 align-items-center">
                    <img
                      src={event?.logo}
                      className="event-img-view"
                      alt="logo"
                    />
                    <div className="registerevent-details d-flex flex-grow-1 align-items-center">
                      <div>
                        <p className="title m-1">
                          {event?.eventType === "Feiseanna"
                            ? "Feisanna"
                            : "Grade Exam"}
                        </p>
                        <p className="event-name m-1">{event?.name || "NA"}</p>
                        <p className="reg-date-open m-1">
                          {" "}
                          {moment(formatDate(event?.feis_date)).format(
                            "MMMM Do, YYYY"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="view-body pb-5 " style={{ minHeight: "95vh" }}>
            {isUserLoggedIn && allDancers?.length > 0 && (
              <>
                <p className="pageHeaders">Results dancer</p>
                <hr className="mt-0" />
                <form>
                  <select
                    className="resultSelect mt-3"
                    value={selectedDancerId}
                    onChange={selectDancerHandler}
                  >
                    {allDancers?.map((dancer) => (
                      <option value={dancer._id}>
                        {dancer?.fname}&nbsp;{dancer?.lname}
                      </option>
                    ))}
                  </select>
                </form>
              </>
            )}
            <div>
              {!isUserLoggedIn && (
                <div className="d-md-flex justify-content-between mt-5">
                  <div>
                    <div className="search-label">Search:</div>
                    <input
                      type="text"
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      placeholder="Search..."
                      className="resultInput"
                    />
                  </div>
                  <div>
                    <div className="search-label">Filter by level:</div>
                    <select
                      className="resultSelect mt-3 mt-md-0"
                      onChange={(e) => levelChangeHandler(e)}
                      value={level}
                    >
                      {getLevelArray()?.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              {filteredComp?.map((comp) => {
                if (
                  comp?.code?.toLowerCase().includes(searchInput?.toLowerCase())
                ) {
                  return (
                    <Accordion className="my-5" activeKey={activeAccordionKey}>
                      <Accordion.Item
                        eventKey={comp._id}
                        className="result-accordion-item"
                      >
                        <Accordion.Header className="result-accordion">
                          <div className="d-flex flex-grow-1 justify-content-between  flex-row align-items-center">
                            <div className="d-md-flex col-6 col-md-8">
                              <div className="comp-code m-0 col-md-7">
                                {comp?.code} - {comp?.description}
                              </div>
                              <div className=" comp-code my-0 col-md-5">
                                Dancers Competed:{" "}
                                {comp?.participatedDancers?.length}
                              </div>
                            </div>
                            <div className="d-flex justify-content-end col-4">
                              <div
                                className="comp-code"
                                style={{ minWidth: "45px" }}
                                onClick={() => handleAccordionSelect(comp._id)}
                              >
                                {" "}
                                {activeAccordionKey === comp._id
                                  ? "Hide"
                                  : "Show"}
                              </div>
                            </div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body className="result-accordion-body">
                          <CommentBox
                            scoresArr={scoresArr}
                            loading={loading}
                            adjudicators={adjudicators}
                            dancerDetail={dancerDetail}
                            selectedDancerId={selectedDancerId}
                          />
                          {results.length > 0 && (
                            <>
                              {" "}
                              <p className="pageHeaders mt-5">Top Places</p>
                              <hr className="mt-0" />
                            </>
                          )}
                          {error && (
                            <div
                              className="m-5"
                              style={{ textAlign: "center", color: "red" }}
                            >
                              {error}
                            </div>
                          )}
                          {!loading && (
                            <ViewResultsTable
                              sortedResults={results}
                              rounds={rounds}
                              adjudicators={adjudicators}
                              keyProp={tableDataKey}
                              compId={selectedCompetitionId}
                              event={event}
                              competition={competition}
                              dancerDetail={dancerDetail}
                              selectedDancerId={selectedDancerId}
                            />
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                }
              })}
              {!loading && !initialLoading && !competitions.length && (
                <div
                  className="m-5"
                  style={{ textAlign: "center", color: "red" }}
                >
                  Results are not published yet.
                </div>
              )}
              <Help />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventResults;
