import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import "../../../Components/Table.css"
import { CalculateCount } from './CalculateCount';

function ThreeJudgeResult({ sortedResults, rounds, keyProp, completeResult, event }) {
    let judgeCount = [1, 2, 3]
    const countOfTopPlaces = event && CalculateCount(event, sortedResults)
    const finalResult =  sortedResults.slice(0, countOfTopPlaces)
    return (
        <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "30px" }} key={keyProp}>
            <table className="table my_table competitorTable">
                <tbody>
                    <tr className="header-row">
                        <td className="text-center p-25-5 w-3"></td>
                        <td className="text-center p-25-5 w-5"></td>
                        <td className="mx-5 p-25-5 w-11"></td>
                        {judgeCount.map((item, index) => {
                            return <td key={uuidv4()} className="p-25-5 text-center w-15">{sortedResults[0]?.scores[index] ? sortedResults[0]?.scores[index]?.judge_details?.name : "-"}</td>
                        })}
                    </tr>
                    {rounds === 1 &&
                        <>
                            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                                <td className="w-3 text-center">Final Place</td>
                                <td className="w-5 text-center">Total Points</td>
                                <td className="w-11">Competitor</td>
                                {judgeCount.map((item) => {
                                    return <td className="grey-42 p-25-5 w-15 text-center">
                                        <div className="d-flex justify-content-center align-items-center gap-1">
                                            <div className="col-3">1</div>
                                            <div className="col-3">Place</div>
                                            <div className="col-6">Points</div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                            {finalResult?.map((dancer) => {
                                return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                    <td className="text-center mx-5 py-4 w-3 grey-30">{dancer.overallPosition}</td>
                                    <td className="text-center mx-5 py-4 w-7 grey-30" >{dancer.irish_points}</td>
                                    <td className="mx-5 w-11 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                                    {judgeCount.map((item, index) => {

                                        return <td className="text-center w-13 p-25-5 grey-30">
                                            <div className="d-flex justify-content-center gap-1">
                                                <div className="col-3">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[0]?.score : "-"}</div>
                                                <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.position : "-"}</div>
                                                <div className="col-7">{dancer?.scores[index] ? dancer?.scores[index]?.irish_points : "-"}</div>
                                            </div>
                                        </td>
                                    })}
                                </tr>
                            })}
                        </>}
                    {rounds === 2 && <>
                        <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                            <td className="w-3 text-center">Final Place</td>
                            <td className="w-7 text-center">Total Points</td>
                            <td className="w-9 ">Competitor</td>
                            {judgeCount.map((item) => {
                                return <td className="grey-42 text-center w-18 p-25-5">
                                    <div className="d-flex justify-content-center align-items-center gap-1">
                                        <div className="col-3">1</div>
                                        <div className="col-3">2</div>
                                        <div className="col-2">Place</div>
                                        <div className="col-4">Points</div>
                                    </div>
                                </td>
                            })}
                        </tr>
                        {finalResult?.map((dancer) => {
                            return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                <td className="text-center mx-5 py-4 w-3 grey-30">{dancer?.overallPosition}</td>
                                <td className="text-center mx-5 py-4 w-3 grey-30">{dancer?.irish_points}</td>
                                <td className="mx-5 w-9 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                                {judgeCount.map((item, index) => {

                                    return <td className="grey-30 p-25-5 w-20 text-center">
                                        <div className="d-flex justify-content-center gap-1">
                                            <div className="col-3">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[0]?.score : "-"}</div>
                                            <div className="col-3">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[1]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.position : "-"}</div>
                                            <div className="col-4">{dancer?.scores[index] ? dancer?.scores[index]?.irish_points : "-"}</div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                        })}
                    </>}
                    {rounds === 3 && <>
                        <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
                            <td className='w2 text-center'>Final Place</td>
                            <td className='w5 text-center'>Total Points</td>
                            <td className='w9'>Competitor</td>
                            {judgeCount.map((item) => {
                                return <td key={uuidv4()} className="grey-42 p-25-5 w-11 text-center">
                                    <div className="d-flex justify-content-around align-items-center gap-1">
                                        <div className="col-2">1</div>
                                        <div className="col-2">2</div>
                                        <div className="col-2">3</div>
                                        <div className="col-2">Place</div>
                                        <div className="col-4">Points</div></div>
                                </td>
                            })}
                        </tr>
                        {finalResult?.map((dancer) => {
                            return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                                <td className="text-center mx-5 py-4 w-2 grey-30" >{dancer?.overallPosition}</td>
                                <td className="text-center mx-5 py-4 w-5 grey-30" >{dancer?.irish_points}</td>
                                <td className="mx-5 w-9 grey-30"><div>{dancer?.dancer_fname} &nbsp;{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                                {judgeCount.map((item, index) => {
                                    return <td key={uuidv4()} className="text-center w-11 p-25-5 grey-30">
                                        <div className="d-flex justify-content-around align-items-center gap-1">
                                            <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[0]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[1]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.scores_by_round[2]?.score : "-"}</div>
                                            <div className="col-2">{dancer?.scores[index] ? dancer?.scores[index]?.position : "-"}</div>
                                            <div className="col-4">{dancer?.scores[index] ? dancer?.scores[index]?.irish_points : "-"}</div>
                                        </div>
                                    </td>
                                })}
                            </tr>
                        })}
                    </>}
                </tbody>
            </table>
        </div >
    )
}

export default ThreeJudgeResult