import React from 'react'
import { sortGrades } from '../../helper/SortSyllabus'

const GradeTable = ({ grades }) => {
    const sortedGrades = sortGrades(grades)
    return (
        <>
            <div className="pt-2">
                <div className="pageHeaders">Grades</div>
                <hr className="mt-0" />
                <div>
                    <table className="table my_table">
                        <tbody>
                            <tr className="rounded-top" style={{ background: "#EAEAEA", color: "#424242" }}>
                                <td style={{ padding: "0.25rem 0.5rem", background: "#EAEAEA" }}>GRADE</td>
                                <td style={{ padding: "0.25rem 0.5rem", background: "#EAEAEA" }}>Price</td>
                            </tr>

                            {sortedGrades?.map((grade) => (
                                <tr style={{ background: "#FFFFFF", color: "#303030" }}>
                                    <td>{grade.grade_name}</td>
                                    <td>${grade.grade_fee}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default GradeTable