import React, { useEffect, useState } from "react";
import "./style.css";
import ParentHeader from "../ParentHeader/ParentHeader";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import axios from "axios";
import Loader from "../../Components/Loader/Loader";
import PaginationNew from "../../Components/Pagination/PaginationNew";
import Help from "../../Components/Help/Help";
import { formatAmount } from "../../helper/formatAmount";
import { formatDate } from "../../helper/formatDate";


const TransactionHistory = () => {

  const [loading, setLoading] = useState(false);
  const [transData, setTransdata] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)

  //back button handler
  const backToViewEvent = () => {
    window.history.back();
  };

  //get transaction events when page changes(5 events for each page)
  const getAllTransaction = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/account/transactions`;
    const config = {
      headers: {
        Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
        accesstoken: localStorage.getItem("accessToken"),
      },
    };

    try {
      setLoading(true);
      const response = await axios.post(url, { page, limit: 10 }, config);
      let totalData = response?.data?.totalCount;
      let totalPage = Math.ceil(Number(totalData / 10))
      setTotalPages(totalPage)
      let startindex = page % 10 === 0 ? page - 9 : (Math.floor(page / 10)) * 10 + 1
      setStartIndex(startindex)
      startindex + 9 > totalPage ? setEndIndex(totalPage) : setEndIndex(startindex + 9)
      let data = response?.data?.result?.results;
      setTransdata(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTransaction();
  }, [page]);

  return (
    <div>
      <ParentHeader />
      <div className="trans-container">
        {loading && <Loader />}
        <div className="heading">
          <div className="d-flex align-items-center" style={{ color: "#68AD3F" }}>
            <div style={{ width: " min-content", cursor: "pointer" }} onClick={backToViewEvent}><NavigateNextIcon style={{ transform: "rotate(180deg)" }} /></div>
            <div className="pe-4" style={{ fontWeight: 500, fontSize: "16px", cursor: "pointer" }} onClick={backToViewEvent}>Back</div>
            <div
              className="back"
              style={{ color: "#424242", justifyContent: "center", alignItems: "center", fontSize: "24px", cursor: "default", }}
            >
              Transaction History
            </div>
          </div>
        </div>
        <div className="border-btm"></div>
        <div className="margin-top"></div>
        <center>
          {transData.length === 0 && !loading && (
            <p className="my-5 d-flex justify-content-center align-items-center">No Transaction history found</p>
          )}
          {transData.map((trans) => {
            return (
              <>
                <div className="trans-events text-left" key={trans._id} data-value={trans._id}>
                  <div className="web-trans-list">
                    <img src={trans.result.logo} className="event-img" alt="logo" />
                    <div className="">
                      <p className="title mb-2">{trans.event_type === "feis" ? "Feisanna" : "Grade-Exam"}</p>
                      <p className=" m-0 trans-event-title">Register On {trans.result.name}</p>
                      <p className="trans-event-date mt-2  mb-0 ">
                        {trans.createdAt ? moment(formatDate(trans?.createdAt)).format("MMM Do, YYYY") : "NA"}
                        -{trans?.result.venue_city}{trans?.result?.state}
                      </p>
                    </div>
                  </div>
                  <div className="event-mobile" data-value={trans._id}>
                    <div className="event-mobile-div">
                      <div>
                        <img src={trans.result.logo} className="event-img" alt="logo" />
                      </div>
                      <div>
                        <p className="title mb-2">{trans.event_type === "feis" ? "Feisanna" : "Grade-Exam"}</p>
                        <p className=" m-0 trans-event-title">Register On {trans.result.name}</p>
                        <p className="trans-event-date mt-2 mb-1">
                          {trans.createdAt ? moment(formatDate(trans?.createdAt)).format("MMM Do, YYYY") : "NA"}-{trans?.result.venue_city}
                          {trans?.result?.state}{trans.venue_city}, {trans.state}
                        </p>
                      </div>
                    </div>
                    <div className="reg-close-trans trans-event-amt d-flex align-items-center justify-content-end">
                      <div style={{ color: "#68AD3F" }}>${trans.paidAmount && formatAmount(trans.paidAmount)}</div>
                    </div>
                  </div>
                  <p className="web-btn mb-0">${trans.paidAmount && formatAmount(trans.paidAmount)}</p>
                </div>
              </>
            );
          })}
          <PaginationNew
            loading={loading}
            startIndex={startIndex}
            endIndex={endIndex}
            totalPages={totalPages}
            page={page}
            setPage={val => setPage(val)}
            setStartIndex={val => setStartIndex(val)}
            setEndIndex={val => setEndIndex(val)}
            dataPerPage={10}
          />
        </center>
      </div>
      <Help />
    </div>
  );
};

export default TransactionHistory;
