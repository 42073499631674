import React from 'react'
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddButton = (props) => {
    let margintop = props.from === "collab" ? "10px" : "20px"
    return (
        <Button
            variant="outlined"
            color="primary"
            className={props.className}
            sx={{
                mb: "20px",
                mt: margintop,
                border: "2px solid",
                fontSize: "16px",
                border: "2px solid",
                boxShadow: "none",
                "&:hover": {
                    border: "2px solid #fbb040",
                    background: " #fbb040 ",
                    color: "#ffffff",
                    borderRadius: "2px solid #fbb040",
                },
            }}
            onClick={props.onClick}
        >
            <AddIcon id="plusICon" />
            <span style={{ marginTop: "2px" }}>{props.children}</span>
        </Button>
    )
}

export default AddButton