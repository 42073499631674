import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import closeIcon from '../../../Assets/close-icon.svg';
import FormikControl from '../../formik/FormikControl';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import AddMusicianModel from './AddMusicianModel';
import { serviceConsumer } from '../../../network/ServiceConsumer';
import AddButton from '../../AddButton/AddButton';

const MusicianModel = ({ modalState, action, showAdjucator, getMusician, musician, from, }) => {
  let EventID = localStorage.getItem('ID');
  const [musiciantData, setMusicianData] = useState([]);
  const [OpenModalAdjucator, setOpenModalAdjucator] = useState(false);
  const [gradePage, setGradePage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAdj, setShowAdj] = useState(showAdjucator);

  useEffect(() => {
    if (from !== 'grade') {
      setGradePage(true);
    }
    getMusicianData();
  }, []);

  const getMusicianData = async () => {
    try {
      setLoading(true);
      const url =
        from !== 'grade'
          ? `${process.env.REACT_APP_BASE_URL}/people/musician`
          : `${process.env.REACT_APP_BASE_URL}/people/examiner`;
      const response = await serviceConsumer('GET', url);
      setMusicianData(response.people);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const accData = musiciantData.map((acc) => {
    return {
      key: acc.name,
      value: acc._id,
    };
  });

  const handleOpenModalAdjucator = () => {
    setOpenModalAdjucator(!OpenModalAdjucator);
  };

  const closeParentModel = (val) => {
    setShowAdj(val);
    showAdjucator = true;
    if (showAdjucator === true) {
      setShowAdj(true);
    }
    setOpenModalAdjucator(OpenModalAdjucator);
    if (showAdjucator === true) {
    }
  };

  const addDancerInitialValues = {
    Adjudicator: '',
  };

  let adjudicators = [];

  const addDancerValidationSchema = Yup.object().shape({
    Adjudicator: Yup.string()
      .trim()
      .label('First Name:')
      .required('This field is required.')
      .nullable()
      .max(50),
  });

  // submit Handler
  const addAdjucatorHandler = async (values, formikHelpers) => {
    const payload = {
      eventId: EventID,
      peopleId: values.Adjudicator,
    };
    try {
      setLoading(true);
      const url =
        from !== 'grade'
          ? `${process.env.REACT_APP_BASE_URL}/people/musician `
          : `${process.env.REACT_APP_BASE_URL}/grade-exam/examiner `;
      const response = await serviceConsumer('POST', url, payload);
      if (response.success === true) {
        action();
        getMusician()
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const AddAdjdis = (val) => {
    setShowAdj(val);
  };

  return (
    <div style={{ padding: '100px', overflow: 'scroll' }}>
      {showAdj === false && (
        <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={modalState}>
          <Box>
            <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
              <div className="main-div-adj">
                <div className="close-div mt-4 mb-1">
                  <img src={closeIcon} alt="close-icon" className="close-icon" onClick={action} />
                </div>
                <p className="color-subtitle text-center mb-4">{from !== 'grade' ? 'Add Musician' : 'Add Examiner'}</p>
                <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }} >
                  <Formik
                    initialValues={addDancerInitialValues}
                    validationSchema={addDancerValidationSchema}
                    onSubmit={addAdjucatorHandler}
                  >
                    {(formik) => (
                      <Form className="form form-label-right" autoComplete="off">
                        <div className={formik.errors?.Adjudicator && formik.touched?.Adjudicator ? '' : 'input-control'}>
                          <label
                            className={formik.touched?.Adjudicator && formik.errors?.Adjudicator ? 'select-label-error' : 'select-label'}
                            htmlFor="input-box-org-select"
                          >
                            {from !== 'grade' ? 'Musician:' : 'Examiner:'}
                          </label>
                          <FormikControl
                            className={formik.touched?.Adjudicator && formik.errors?.Adjudicator ? 'input-box-org-select-error' : 'input-box-org-select'}
                            control="select"
                            name="Adjudicator"
                            options={accData}
                            showAs="Select"
                            style={{ color: 'black' }}
                            id="AddAdjucator"
                            setFieldValue={formik.setFieldValue}
                          />
                        </div>
                        <span className="DidnontFind">
                          {from !== 'grade' ? `Didn't find the Musician` : `Didn't find the Examiner`}
                        </span>
                        <div className="d-flex justify-content-center mt-4 ">
                          <AddButton className="plusIconbtn" onClick={closeParentModel} from="collab">
                            {from !== 'grade' ? 'Add new musician' : 'Add new examiner'}
                          </AddButton>
                        </div>
                        <div className="mb-5 d-flex justify-content-center">
                          <button
                            className={formik.isValid && formik.dirty ? 'filled' : 'disabled'}
                            type="submit"
                            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                          >
                            Continue
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {showAdj === true && (
        <AddMusicianModel
          modalState={handleOpenModalAdjucator}
          action={action}
          showAdj={showAdj}
          AddAdjdis={AddAdjdis}
          adjudicators={adjudicators}
          musician={musician}
          getMusician={getMusician}
          from={gradePage ? '' : 'grade'}
        />
      )}
    </div>
  );
};

export default MusicianModel;
