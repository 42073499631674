import React, { useEffect } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useDispatch, useSelector } from "react-redux";

import "./Style.css";
import ParentHeader from "../../Parent/ParentHeader/ParentHeader";
import Profile from "./Profile";
import Password from "./Password";
import Dancers from "./Dancers";
import Help from "../../Components/Help/Help";

const Account = () => {
  const dispatch = useDispatch();
  const tabIndex = useSelector((state) => state.authReducer.tabIndex);

  const handleChange = (value) => {
    dispatch({
      type: "TAB_CHANGE",
      payload: value,
    });
  };

  useEffect(() => {
    document.title = "Feis.Link - My account"
  }, [])

  return (
    <div>
      <ParentHeader />
      <div className="account-flow">
        <h3 className="grey-42">My account</h3>
        <div className="account-flow">
          <TabContext sx={{ borderBottom: "1px solid Gray" }} value={tabIndex}>
            <TabList className="account-tab" aria-label="lab API tabs example">
              <Tab onClick={() => handleChange("1")} value="1" style={{ opacity: 0.6 }} label="Profile" />
              <Tab onClick={() => handleChange("2")} value="2" style={{ opacity: 0.6 }} label="Password" />
              <Tab onClick={() => handleChange("3")} value="3" style={{ opacity: 0.6 }} label="Dancers" />
            </TabList>
            <div className="tabs  mt-4">
              <Profile />
              <Password />
              <Dancers />
            </div>
          </TabContext>
        </div>
      </div>
      <Help />
    </div>
  );
};

export default Account;
