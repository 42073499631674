import React, { useRef, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import closeIcon from '../../../Assets/close-icon.svg';
import { serviceConsumer } from '../../../network/ServiceConsumer';
import * as Yup from 'yup';
import { Form, Formik, Field, ErrorMessage, } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { handlePhotoUpload } from '../../../helper/handleUploads';

const AddAdjucatorModel = ({ action, AddAdjdis, getAdjucators, handleOpenModal, }) => {
  const [imgError, setImgErr] = useState('');
  const [logo, setLogo] = useState('');
  const [upl, setUpl] = useState('');
  const [loading, setLoading] = useState(false);
  const imgInputRef = useRef(null);
  const handleLOgo = () => {
    imgInputRef.current.click();
  };
  let EventID = localStorage.getItem('ID');

  //Initial values for formik
  const addDancerInitialValues = {
    name: '',
    location: '',
    type: 'adjudicator',
    photo: '',
  };

  const addDancerValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .label('First Name:')
      .required('This field is required.')
      .nullable()
      .max(50),
    location: Yup.string()
      .trim()
      .label('Last Name:')
      .required('This field is required.')
      .nullable()
      .max(50),
  });
  const disAddAj = () => {
    AddAdjdis(false);
  };
  
  const handleFileSelect = async (event) => {
    handlePhotoUpload(event, setLoading, setLogo, setUpl, setImgErr);
  };

  const addDancer = async (values, formikHelpers) => {
    const addAdjucator = {
      name: values.name,
      location: values.location,
      type: values.type,
      photo: logo,
      eventId: EventID,
    };
    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_BASE_URL}/people/adjudicator`;
      const response = await serviceConsumer('POST', url, addAdjucator);
      response.success && disAddAj();
      getAdjucators();
      handleOpenModal();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleOpenModal();
    }
  };

  return (
    <Modal className="addDancerScroll" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }} open={action}>
      <Box>
        <div style={{ maxHeight: '100vh', padding: '5px 100vw', overflow: 'scroll', }}>
          <div className="main-div-add-adj">
            <div className="close-div mt-4 mb-1">
              <img src={closeIcon} alt="close-icon" className="close-icon" onClick={disAddAj} />
            </div>
            <p className="color-subtitle text-center mb-4" style={{ marginTop: '40px' }}>Add Adjudicator</p>
            <div className="d-flex align-items-center justify-content-center" style={{ background: 'white,borderRadius: 14px;' }}>
              <Formik
                initialValues={addDancerInitialValues}
                validationSchema={addDancerValidationSchema}
                onSubmit={addDancer}
              >
                {(formik) => (
                  <Form className="form form-label-right" autoComplete="off">
                    <div className={formik.errors.name && formik.touched.name ? 'd-grid' : 'input-control d-grid'}>
                      <label className={formik.errors.name && formik.touched.name ? 'label-head-org-error' : 'label-head-org'}>
                        Full name:{' '}
                      </label>
                      <Field
                        className={formik.errors.name && formik.touched.name ? 'input-box-org-error' : 'input-box-org'}
                        type="text"
                        name="name"
                        placeholder="Name"
                      />
                      <ErrorMessage className="auth-error" name="name" component="div" />
                    </div>
                    <input type="file" ref={imgInputRef} style={{ display: 'none' }} onChange={handleFileSelect} />
                    <div className={formik.errors.location && formik.touched.location ? 'd-grid' : 'input-control d-grid'}>
                      <label className={formik.errors.location && formik.touched.location ? 'label-head-org-error' : 'label-head-org'}>
                        Location:
                      </label>
                      <Field
                        className={formik.errors.location && formik.touched.location ? 'input-box-org-error' : 'input-box-org'}
                        type="text"
                        name="location"
                        placeholder="Location"
                      />
                      <ErrorMessage className="auth-error" name="location" component="div" />
                    </div>
                    <div className="d-grid">
                      <label className="label-head-org" style={{ marginLeft: '1px' }} >Photo:</label>
                      <Button
                        onClick={handleLOgo}
                        variant="outlined"
                        color="primary"
                        className="plusIconbtn"
                        sx={{
                          border: '2px solid',
                          padding: '9px 11px',
                          fontSize: '16px',
                          border: '2px solid',
                          width: 'fit-content',
                          height: '42px',
                          '&:hover': {
                            border: '2px solid #fbb040',
                            background: ' #fbb040 ',
                            color: '#ffffff',
                            borderRadius: '2px solid #fbb040',
                          },
                        }}
                      >
                        <AddIcon id="plusICon" />
                        <span style={{ marginTop: '2px' }}>Add Photo</span>
                      </Button>
                      {imgError && (<span className="error mt-2" style={{ width: '248px' }}>{imgError}</span>)}
                      {upl && <span className="auth-suc">{upl}</span>}
                    </div>
                    <div className="mb-5 d-flex justify-content-center">
                      <button className={formik.isValid && formik.dirty ? 'updateChange' : 'updateChangeDisabled'}
                        style={{ fontWeight: '500', height: '42px', padding: '9px 11px', height: '42px', }}
                        type="submit"
                        disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                      >
                        Add adjudicator
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddAdjucatorModel;
