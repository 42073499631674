import React from 'react'

const LevelButton = ({ level, data, dancer, index, handleClick, selectedLevels }) => {
    return (
        <div key={level} className="btn-group btn-group-toggle action " data-toggle="buttons">
            {data?.filter((item) => item?.ages?.some(age => dancer?.ages?.value?.includes(age))).length > 0 &&
                <label style={{ background: "#EAEAEA", marginRight: "10px", }}>
                    <button
                        className={selectedLevels[index] === level ? "btnActive" : "btn"}
                        onClick={() => handleClick(level, index)}
                    >
                        {level}
                    </button>
                </label>
            }
        </div>
    )
}

export default LevelButton