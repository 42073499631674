import React, { useState, useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useDispatch } from "react-redux";

import feisLogo from "../../Assets/feis-logo.svg";
import "./style.css";
import FormikControl from "../../Components/formik/FormikControl";
import Loader from "../../Components/Loader/Loader";
import { SIGNUP } from "../../redux/actionTypes/authActionType";
import Help from "../../Components/Help/Help";
import { regionData } from "../../helper/DataArrays";
import { countryValidation, emailValidation, fnameValidation, lnameValidation, passwordValidation, repeatPwdValidation } from "../../helper/Validations";
import { serviceConsumer } from "../../network/ServiceConsumer";

const AddAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Eerror, SetErerror] = useState([]);
  const [sUser, SetsUser] = useState([]);

  const changeHandler = (e, name, setFieldValue) => {
    setFieldValue(name, e.target.value);
    SetErerror("");
  };

  const loginHandler = () => {
    navigate("/login");
  };
  //Initial values for formik
  const signUpInitialValues = {
    fname: "",
    lname: "",
    email: "",
    country: "",
    password: "",
    repeatPassword: "",
  };

  //Validation Schema for Formik
  const signUpValidationSchema = Yup.object().shape({
    fname: fnameValidation,
    lname: lnameValidation,
    email: emailValidation,
    country: countryValidation,
    password: passwordValidation,
    repeatPassword: repeatPwdValidation,
  });

  const signUp = async (values, formikHelpers) => {
    setLoading(true);
    let value = { ...values, email: values.email.toLowerCase() };
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/sign-up`;
      const response = await serviceConsumer("POST", url, value);

      formikHelpers.setSubmitting(false);
      SetsUser(response.message);

      setLoading(false);
      localStorage.setItem("accessToken", response.accessToken);
      localStorage.setItem("userFirstName", response.fname);

      dispatch({
        type: SIGNUP,
        payload: response.data,
      });
      if (localStorage.getItem("redirectFromOrg") === "redirect") {
        navigate(localStorage.getItem("orgPath"), { replace: true })
      } else
        navigate("/add-dancers", {
          replace: true,
        });
    } catch (error) {
      SetErerror(error.messages);
      setLoading(false);
      formikHelpers.setSubmitting(false);
    }
  };

  useEffect(() => {
    document.title = "Feis.Link - Sign Up"
  }, [])

  return (
    <div>
      {loading && <Loader />}
      <div>
        <img src={feisLogo} alt="" className="m-4 feis-logo" onClick={() => navigate("/")} />
      </div>
      <br />
      <Container className="d-flex justify-content-center" style={{ paddingBottom: "50px" }}>
        <Formik
          initialValues={signUpInitialValues}
          validationSchema={signUpValidationSchema}
          onSubmit={signUp}
        >
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="login-form-signIn">
                <div className="email-form-signIn">
                  <h4 className="title-signin"> Sign Up</h4>
                  <br />
                  <div>
                    <FormikControl
                      control="input"
                      type="text"
                      name="fname"
                      placeholder="Parent's First Name"
                      onChange={(e) => { changeHandler(e, "fname", formik.setFieldValue); }}
                      maxlength={30}
                    />
                    <FormikControl
                      control="input"
                      type="text"
                      name="lname"
                      placeholder="Parent's Last Name"
                      onChange={(e) => { changeHandler(e, "lname", formik.setFieldValue); }}
                      maxlength={30}
                    />
                    <FormikControl
                      control="input"
                      type="email"
                      name="email"
                      placeholder="Parent's Email"
                      onChange={(e) => { changeHandler(e, "email", formik.setFieldValue); }}
                    />
                    <span className="error">{Eerror}</span>
                    <div style={{ padding: "5px" }} />
                    <FormikControl
                      className="formikSelectSign "
                      control="select"
                      name="country"
                      options={regionData}
                      showAs="Select Region"
                      id="selectField"
                      style={{ color: "black" }}
                      setFieldValue={formik.setFieldValue}
                      onChange={(e) => { changeHandler(e, "country", formik.setFieldValue); }}
                      isModification={false}
                    />
                    <FormikControl
                      control="input"
                      type="password"
                      name="password"
                      placeholder="Choose a Password"
                      onChange={(e) => { changeHandler(e, "password", formik.setFieldValue); }}
                    />
                    <FormikControl
                      control="input"
                      type="password"
                      name="repeatPassword"
                      placeholder="Repeat Password"
                      onChange={(e) => { changeHandler(e, "repeatPassword", formik.setFieldValue); }}
                    />
                    <div className="mt-4 mb-4">
                      <p style={{ color: "#323232" }} className="signUpAlr">
                        Already have an account?{" "}
                        <span onClick={loginHandler} style={{ color: "#68AD3F", fontWeight: 700, cursor: "pointer", }}>
                          Login
                        </span>
                      </p>
                    </div>
                    {sUser}
                    <div className="d-flex ">
                      <button className="filled" type="submit">Next</button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <Help />
      </Container>
    </div>
  );
};

export default AddAccount;
