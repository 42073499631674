import React, { useState, useEffect } from "react";
import ParentHeader from "../../Parent/ParentHeader/ParentHeader";
import { useLocation, useNavigate } from "react-router-dom";
import RegisterInFiesanaaEvent from "./RegisterInFiesanaaEvent";
import Loader from "../../Components/Loader/Loader";
import { serviceConsumer } from "../../network/ServiceConsumer";
import { agesCode } from "../../helper/DataArrays";
import "./style.css";
import { filterCompBasedOnAgesAndTeams, sortLevels } from "../../helper/RegFlowHelpers";
import RegisterTitle from "./Components/RegisterTitle";
import LevelButton from "./Components/LevelButton";
import CompetitionCheckBox from "./Components/CompetitionCheckBox";
import { getIsTeacher } from "../../helper/getIsTeacher";

const RegisterFiesanaaEvent = () => {
  const [initialLoading, setInitialLoading] = useState(true)
  const [loading, setLoading] = useState(false);
  const [showPaymentPage, setshowPaymentPage] = useState(false);
  const [error, setError] = useState("");
  const [eventDetails, setEventDetails] = useState();

  // all dancer in an event
  const [allDancer, setAllDancer] = useState([]);
  const [dancersWithoutAge, setDancersWithoutAge] = useState([])

  // grouped level wise competitions list for a dancer
  const [levelData, setLevelData] = useState();

  // selected level's competitions list for all dancer
  const [selectedLevelData, setSelectedLevelData] = useState([]);

  // for each dancer selected level
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [eligibleDancers, setEligibleDancers] = useState([])

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get('id');
  const type = searchParams.get("type")
  const navigate = useNavigate()

  const backToViewEvent = () => {
    window.history.back();
  };

  const handleClick = async (level, index) => {
    const newArr = [...selectedLevels];
    newArr[index] = level;
    setSelectedLevels(newArr)
  };

  const errormsg = (err) => {
    setLoading(false);
    setError(err);
  };

  const clientSecret = (secret) => {
    setLoading(false);
  };

  const setshowPaymentPageHandler = (val) => {
    setLoading(true);
    setshowPaymentPage(val);
  };

  const getAllDancers = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/account/dancers`;
    try {
      setInitialLoading(true);
      setLoading(true);
      const response = await serviceConsumer('GET', url);
      setInitialLoading(false);
      setDancersWithoutAge(response.dancers)
      setTimeout(() => setLoading(false), 500);
    } catch (error) {
      setInitialLoading(false);
      setLoading(false)
    }
  };

  const updateDancersWithAges = (dancers) => {
    return dancers.map(item => {
      const dancerAge = new Date(eventDetails?.feis_date).getFullYear() - Number(item.birthday)
      const dancerAgeToCompare = dancerAge < 5 ? 5 : dancerAge > 22 ? 23 : dancerAge;
      const ageObject = agesCode.find(age => age.key === (dancerAgeToCompare).toString())
      return ({ ...item, ages: ageObject })
    })
  }
  const backToRegisterGradeEventFromErrorPage = () => {
    setshowPaymentPage(false);
    setError("");
  };

  //  to get eventDetails on back
  const getEventDetails = async () => {
    setInitialLoading(true);
    try {
      if (eventId) {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`;
        const response = await serviceConsumer('GET', url);
        setEventDetails(response.Feis);
      }
    } catch (error) {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    getEventDetails()
    getAllDancers();
    getIsTeacher(setLoading)
    document.title = "Feis.Link - Register Dancers"
  }, []);

  useEffect(() => {
    const dancersUpdatedWithAges = updateDancersWithAges(dancersWithoutAge)
    setAllDancer(dancersUpdatedWithAges);
  }, [dancersWithoutAge, eventDetails])

  const getLevelCompetitions = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/feis/levelwise/competitions/${eventId}`;
    const res = await serviceConsumer('GET', url);
    const levelDataResponse = res.result
    const sortedLevels = sortLevels(levelDataResponse)
    if (res) setLevelData(sortedLevels)
  }

  useEffect(() => {
    if (eventDetails) {
      getLevelCompetitions()
    }
  }, [eventDetails]);

  useEffect(() => {
    const eligibleDancerArr = []
    allDancer.forEach(dancer => {
      let isEligible = Object.values(levelData).some((dataArr) =>
        dataArr.some((obj) => obj.ages.some((age => dancer?.ages?.value?.includes(age) && (obj.gender === dancer.gender || obj.gender === "Boys & Girls" || "Both")))))
      if (isEligible) eligibleDancerArr.push(dancer)
    })
    setEligibleDancers(eligibleDancerArr)
  }, [levelData])


  const checkBoxSelectHandler = async (competitionId, dancerDetail, index, price, level) => {
    const dancer = selectedLevelData[index]
    if (dancer?.competitions?.find(item => item === competitionId)) {
      deleteItemHandler(competitionId, dancerDetail?._id, index, level)
    } else {
      setLoading(true)
      const updatedComp = dancer?.competitions ? [...dancer.competitions, competitionId] : [competitionId];

      const newLevelItem = levelData[level]?.find(item => item._id === competitionId);

      // grouped competition list based on level
      const updatedLevelPriceData = dancer?.levelPriceData && dancer?.levelPriceData[level]
        ? { ...dancer?.levelPriceData, [level]: [...dancer?.levelPriceData[level], newLevelItem] }
        : dancer?.levelPriceData
          ? { ...dancer?.levelPriceData, [level]: [newLevelItem] }
          : { [level]: [newLevelItem] }

      const data = {
        _id: dancerDetail?._id,
        event_id: eventId,
        competitions: updatedComp,
        levelPriceData: updatedLevelPriceData
      };

      const newArr = [...selectedLevelData];
      newArr[index] = data;

      setSelectedLevelData(newArr);
      setTimeout(() => { setLoading(false) }, 300);
    }
  };

  const deleteItemHandler = (competitionId, dancerId, index, level) => {
    setLoading(true)
    const dancer = selectedLevelData[index]

    const competitionsNew = dancer?.competitions?.filter(competition => competition !== competitionId)

    // grouped competition list based on level
    const updatedLevelPriceData = dancer?.levelPriceData && dancer?.levelPriceData[level]
      ? { ...dancer?.levelPriceData, [level]: [...dancer?.levelPriceData[level]?.filter(it => it._id !== competitionId)] }
      : dancer?.levelPriceData
        ? delete dancer?.levelPriceData[level]
        : {}

    const data = {
      competitions: competitionsNew,
      _id: dancerId,
      event_id: eventId,
      levelPriceData: updatedLevelPriceData
    };

    const newArr = [...selectedLevelData];
    newArr[index] = data;

    setSelectedLevelData(newArr);
    setTimeout(() => {
      setLoading(false)
    }, 300);
  };

  useEffect(() => {
    const arr = eligibleDancers?.map(dancer => dancer.levels.toUpperCase())
    setSelectedLevels(arr)
  }, [eligibleDancers])

  return (
    <div>
      <ParentHeader />
      {loading && <Loader />}
      {initialLoading && <Loader />}
      {!initialLoading && <div className="parent-register row">
        <div className="col-md-7">
          {error && (
            <>
              <RegisterTitle eventDetails={eventDetails} onClick={backToRegisterGradeEventFromErrorPage} />
              <hr className="mt-0 " />
              <div className="col-md-8 offset-md-2 offset-1 col-10 my-5" >
                <center><div style={{ color: "red" }}>{error}</div></center>
              </div>
            </>
          )}

          {!showPaymentPage && !error && (
            <>
              <RegisterTitle eventDetails={eventDetails} onClick={backToViewEvent} />
              <hr className="mt-0 " />
              <div className="reg-left-container">
                {eligibleDancers.map((dancer, index) => {
                  return (
                    <div className="col-md-8 offset-md-2 offset-1 col-10 my-5" key={dancer._id}>
                      <h5> Register dancer:&nbsp;<span style={{ color: "#68ad3f" }}>{dancer.fname} {dancer.lname}</span> </h5>
                      <hr className="mt-0 mb-4" />
                      <h6 style={{ color: "#323232", fontSize: "12px", }} className="my-2"> Choose Levels:</h6>
                      <div className="buttonGroup">
                        {/* for each level in levelData show a level button */}
                        {levelData && Object.entries(levelData).map(([level, data]) =>
                          filterCompBasedOnAgesAndTeams(level, dancer, levelData)?.length > 0 && level !== "SPECIALS" && <LevelButton level={level} data={data} dancer={dancer} index={index} handleClick={handleClick} selectedLevels={selectedLevels} />
                        )}
                      </div>
                      {/* Check if selected dancer level is in selectedLevels */}
                      {selectedLevels[index] && filterCompBasedOnAgesAndTeams(selectedLevels[index], dancer, levelData)?.length > 0 && (
                        <div>
                          <p className="mt-3 mb-2" style={{ fontSize: "12px", fontWeight: "500", color: "#68AD3F", }}>
                            {selectedLevels[index]}
                          </p>
                          <div style={{ border: "2px solid rgb(234, 234, 234)", width: "fit-content", }}>
                            {selectedLevels[index] && filterCompBasedOnAgesAndTeams(selectedLevels[index], dancer, levelData).map((item) => {
                              // const group = item.group.toLowerCase() === "specials" ? item.levels[0] : item.group;
                              const group = item.group;
                              if (group === selectedLevels[index] && group !== "SPECIALS") {
                                return <CompetitionCheckBox item={item} index={index} dancer={dancer} group={group} checkBoxSelectHandler={checkBoxSelectHandler} selectedLevelData={selectedLevelData} />
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {(Object.keys(levelData)?.includes("SPECIALS") && filterCompBasedOnAgesAndTeams("SPECIALS", dancer, levelData)?.length > 0 &&
                        <div>
                          <p className="mt-3 mb-2" style={{ fontSize: "12px", fontWeight: "500", color: "#68AD3F", }}>
                            SPECIALS
                          </p>
                          <div style={{ border: "2px solid rgb(234, 234, 234)", width: "fit-content", }}>
                            {selectedLevels[index] && filterCompBasedOnAgesAndTeams("SPECIALS", dancer, levelData)?.map((item) => {
                              return <CompetitionCheckBox item={item} index={index} dancer={dancer} group={"SPECIALS"} checkBoxSelectHandler={checkBoxSelectHandler} selectedLevelData={selectedLevelData} />
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>

        <div className="col-md-5 d-flex flex-column right-calc-div" >
          <RegisterInFiesanaaEvent
            selectedLevelData={selectedLevelData}
            allDancer={allDancer}
            eventDetails={eventDetails}
            deleteItemHandler={deleteItemHandler}
            setshowPaymentPage={setshowPaymentPageHandler}
            clientSecret={clientSecret}
            errormsg={errormsg}
            showPaymentPage={showPaymentPage}
          />
        </div>
      </div>}
    </div>
  );
};

export default RegisterFiesanaaEvent;
