import React from 'react'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Back.css"

const Back = ({ onBackClick, from, fies }) => {
    const backClickHandler = () => {
        window.history.back()
    }
    let marginTitle = from === "single" ? "3px" : "0px";
    return (
            <div className="d-flex align-items-center" style={{ marginTop: marginTitle }} >
                <NavigateNextIcon style={{ cursor: "pointer",transform: "rotate(180deg)", color: " #68AD3F", marginLeft: "-5px", marginTop: "-1px" }} onClick={!onBackClick ? backClickHandler : onBackClick} className="icon" />
                <div className="back-nav cursor-pointer" onClick={!onBackClick ? backClickHandler : onBackClick}>
                    Back
                </div>
            </div>
    )
}

export default Back