import * as Yup from 'yup';
export const fnameValidation = Yup.string()
  .trim()
  .label("fname")
  .required("This field is required.")
  .nullable()
  .max(30, "Maximum characters allowed 30")

export const lnameValidation = Yup.string()
  .trim()
  .label("lname")
  .required("This field is required.")
  .nullable()
  .max(30, "Maximum characters allowed 30")

export const countryValidation = Yup.string()
  .trim()
  .label("Region")
  .required("Please select a region.")
  .nullable()

export const emailValidation = Yup.string()
  .trim()
  .label('Email')
  .required('This field is required.')
  .email('Please enter a valid email address.')
  .max(100)


export const passwordValidation = Yup.string()
  .trim()
  .label("password")
  .nullable()
  .required("This field is required")
  .min(8, "Length must be greater than 8")
  .matches(/[A-Z]/, " Must contain an uppercase")
  .matches(/[a-z]/, "Must contain a lowercase")
  .matches(/[^\w]/, "Must contain a special character")
  .matches(/[0-9]/, "Must contain a number")


export const loggedInPwdValidation = Yup.string()
  .trim()
  .label('Password')
  .nullable()
  .required('This field is required.')

export const repeatPwdValidation = Yup.string()
  .trim()
  .oneOf([Yup.ref("password"), null], "Passwords do not match.")
  .label("repeatPassword")
  .nullable()
  .required("This field is required.")


export const venueDetailsValidationSchema = Yup.object().shape({
  venue_name: Yup.string()
    .trim()
    .label('Venue Name:')
    .required('Please fill in the required information.')
    .nullable()
    .max(50),
  city: Yup.string()
    .trim()
    .label('Venue City:')
    .required('Please fill in the required information.')
    .nullable()
    .max(50),
  venue: Yup.string()
    .trim()
    .label('Venue:')
    .required('Please fill in the required information.'),
  state: Yup.string()
    .trim()
    .label('State:')
    .required('Please fill in the required information.')
    .nullable(),
  country: Yup.string()
    .trim()
    .label('County:')
    .required('Please fill in the required information.')
    .nullable(),
  postal_code: Yup.string()
    .trim()
    .label('Postal COde:')
    .required('Please fill in the required information.')
    .nullable(),
});

export const createEventDetailValidationSchema = (feisDateField, feisDateMessage) =>
  Yup.object().shape({
    name: Yup.string().required('Please fill in the required information.'),
    contact_email: Yup.string()
      .email('Please enter a valid email address.')
      .required('Please fill in the required information.'),
    region: Yup.string().required('Please Select a Region.'),
    cap: Yup.object().shape({
      capType: Yup.string().required('Please Select a Cap.'),
      value: Yup.string().when('capType', {
        is: (val) => val === 'Global Cap',
        then: Yup.string().required('Value is required.'),
      }),
      grades: Yup.string().when('capType', {
        is: 'Level Cap',
        then: Yup.string(),
      }),
      champ: Yup.string().when('capType', {
        is: 'Level Cap',
        then: Yup.string(),
      }),
    }),
    opening_date: Yup.date().required('Please fill in the required information.'),
    closing_date: Yup.date()
      .test({
        test: (closing_date, context) => {
          const { opening_date } = context.parent;
          if (!!closing_date && !!opening_date) {
            return closing_date.getTime() >= opening_date.getTime();
          }
          return true;
        },
        message: 'Closing date must be greater than opening date.',
      })
      .required('Please fill in the required information.'),
    [feisDateField]: Yup.date()
      .test({
        test: (date, context) => {
          const { closing_date } = context.parent;
          if (!!date && !!closing_date) {
            return date.getTime() >= closing_date.getTime();
          }
          return true;
        },
        message: feisDateMessage,
      })
      .test({
        test: (date, context) => {
          const { opening_date } = context.parent;
          if (!!date && !!opening_date) {
            return date.getTime() >= opening_date.getTime();
          }
          return true;
        },
        message: 'Feis date must be greater than the opening date.',
      })
      .required('Please fill in the required information.'),
  });

