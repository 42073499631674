import React from 'react';
import Input from './Input';
import Textarea from './Textarea';
import Select from './Select';
import RadioButtons from './RadioButtons';
import CheckboxGroup from './CheckboxGroup';
import DatePickerField from './DatePickerField';

function FormikControl(props) {
  const { control, ...rest } = props;
  switch (control) {
    case 'input':
      return <Input {...rest} />;
    case 'textarea':
      return <Textarea {...rest} />;
    case 'select':
      return (
        <Select
          customStyles={customStyles}
          {...rest}
        />
      );
    case 'radio':
      return <RadioButtons {...rest} />;
    case 'checkbox':
      return <CheckboxGroup {...rest} />;
    case 'date':
      return <DatePickerField {...rest} />;
    default:
      return null;
  }
}

const customStyles = {
  Option: {
    width: 30,
  },
};

export default FormikControl;
