import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import "../../../Components/Table.css"
import { CalculateCount } from './CalculateCount';

const OneJudgeResult = ({ sortedResults, rounds, completeResult, event }) => {
  const countOfTopPlaces = event && CalculateCount(event, sortedResults, 1)
  const finalResult = sortedResults.slice(0, countOfTopPlaces)

  const JudgeTableRow = (judgeName) => {
    return <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
      <td className="text-center grey-42 p-25-5 w-7"></td>
      <td className="mx-5 grey-42 p-25-5 w-25" ></td>
      <td className="text-center grey-42 p-25-5 w-40">{sortedResults[0]?.scores[0]?.judge_details?.name}</td>
    </tr>
  }
  return (
    <>
      {rounds === 1 && <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "30px" }}>
        <table className="table my_table competitorTable ">
          <tbody>
            <tr className="header-row">
              <td className="text-center p-25-5 w-7">Final Place</td>
              <td className="mx-5 p-25-5 w-25">Competitor</td>
              <td className="p-25-5 text-center w-40">{sortedResults[0]?.scores[0]?.judge_details?.name}</td>
            </tr>
            {finalResult?.map((dancer, index) => {
              return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                <td className="text-center mx-5 py-4 w-7 grey-30">{dancer?.overallPosition}</td>
                <td className="mx-5 w-25 grey-30"><div>{dancer?.dancer_fname}&nbsp;{dancer?.dancer_lname} <div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                <td className="text-center w-40 grey-30">{dancer?.scores[0]?.score}</td>
              </tr>
            })}
          </tbody>
        </table>
      </div>}
      {rounds === 2 && <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "30px" }}>
        <table className="table my_table competitorTable ">
          <tbody>
            <JudgeTableRow judgeName={sortedResults[0]?.scores[0]?.judge_details?.name} />
            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
              <td className="w-7 text-center">Final Place</td>
              <td className="mx-5 grey-42 p-25-5 w-25" >Competitor</td>
              <td className="grey-42 p-25-5 w-40 text-center">
                <div className="d-flex justify-content-center gap-2">
                  <div className="col-2">1</div>
                  <div className="col-2">2</div>
                  <div className="col-2">Place</div>
                </div>
              </td>
            </tr>
            {finalResult?.map((dancer, index) => {
              return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                <td className="text-center mx-5 py-4 w-7 grey-30">{dancer?.overallPosition}</td>
                <td className="mx-5 w-25 grey-30"><div>{dancer?.dancer_fname}{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                <td className="w-40 grey-30 text-center">
                  <div className="d-flex justify-content-center gap-2">
                    <div className="col-2">{dancer?.scores[0]?.scores_by_round[0]?.score}</div>
                    <div className="col-2">{dancer?.scores[0]?.scores_by_round[1]?.score}</div>
                    <div className="col-2">{dancer?.scores[0]?.position}</div>
                  </div>
                </td>

              </tr>
            })}
          </tbody>
        </table>
      </div>}
      {rounds === 3 && <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "30px" }}>
        <table className="table my_table competitorTable ">
          <tbody>
            <JudgeTableRow judgeName={sortedResults[0]?.scores[0]?.judge_details?.name} />
            <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
              <td className="w-9 text-center">Final Place</td>
              <td className="mx-5 grey-42 p-25-5 w-25" style={{ color: "#424242", padding: "0.25rem 0.5rem", width: "25%" }}>Competitor</td>
              <td className="grey-42 p-25-5 w-40 text-center">
                <div className="d-flex justify-content-center gap-2">
                  <div className="col-2">1</div>
                  <div className="col-2">2</div>
                  <div className="col-2">3</div>
                  <div className="col-2">Place</div>
                </div>
              </td>

            </tr>
            {finalResult?.map((dancer, index) => {
              return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
                <td className="text-center mx-5 py-4 w-7 grey-30" >{dancer?.overallPosition}</td>
                <td className="mx-5 w-25 grey-30" ><div>{dancer?.dancer_fname}{dancer?.dancer_lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
                <td className="text-center grey-42 p-25-5 w-40">
                  <div className="d-flex justify-content-center gap-2">
                    <div className="col-2">{dancer?.scores[0]?.scores_by_round[0]?.score}</div>
                    <div className="col-2">{dancer?.scores[0]?.scores_by_round[1]?.score}</div>
                    <div className="col-2">{dancer?.scores[0]?.scores_by_round[2]?.score}</div>
                    <div className="col-2">{dancer?.scores[0]?.position}</div>
                  </div>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>}
    </>
  )
}

export default OneJudgeResult