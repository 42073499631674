export const accessTillEventDetails = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: false,
            Payment: false,
            Fees: false,
            syllabus: false,
            About: false,
            colloborators: false,
        },
    });
}

export const accessTillVenueDetails = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: true,
            Payment: false,
            Fees: false,
            syllabus: false,
            About: false,
            colloborators: false,
        },
    });

}
export const accessTillPayment = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: true,
            Payment: true,
            Fees: false,
            syllabus: false,
            About: false,
            colloborators: false,
        },
    });

}
export const accessTillFees = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: true,
            Payment: true,
            Fees: true,
            syllabus: false,
            About: false,
            colloborators: false,
        },
    });

}
export const accessTillSyllabus = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: true,
            Payment: true,
            Fees: true,
            syllabus: true,
            About: false,
            colloborators: false,
        },
    });
}
export const accessTillAbout = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: true,
            Payment: true,
            Fees: true,
            syllabus: true,
            About: true,
            colloborators: false,
        },
    });
}

export const accessAllTabs = (dispatch) => {
    dispatch({
        type: 'ORG_TAB',
        payload: {
            Event_Details: true,
            Venue_Details: true,
            Payment: true,
            Fees: true,
            syllabus: true,
            About: true,
            colloborators: true,
        },
    });
}

export const openOrCloseModal = (dispatch, modalName, eventId) => {
    dispatch({
        type: 'SET_MODAL',
        payload: {
            modal: modalName,
            eventId: eventId,
        },
    });
}