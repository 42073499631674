import moment from "moment";
import closeIcon from "../../../../Assets/close-icon.svg"

export const addTimeToComp = (competition, startTime, endTime, tableData, columnKey) => {
    // Find the competition in the table data and update its start and end time
    try {
        const updatedTableData = Object.keys(tableData).reduce((acc, columnKey1) => {
            const updatedColumnItems = tableData[columnKey1].map(item => {
                if (((item.code === "Break Time" && competition.code === "Break Time") && (columnKey === columnKey1))) {
                    return { ...item, startTime, endTime };
                } else if (item.competitionId === competition?._id || item.competitionId === competition?.competitionId) {
                    return { ...item, dancerCount: competition?.dancerCount, rounds: competition?.rounds, startTime, endTime }
                }
                return item;
            });
            acc[columnKey1] = updatedColumnItems;
            return acc;
        }, {});
        let start = updatedTableData[columnKey][0]?.startTime
        let updatedCol = setTimingsWithinCol(updatedTableData[columnKey], start)
        updatedTableData[columnKey] = updatedCol
        return updatedTableData;
    } catch (err) {
        console.log(err)
    }
}

export const removeStageHandler = (index, tableData, evokeFilteredComp) => {
    try {
        let tableDataCopy = { ...tableData };
        const tableDataKeys = Object.keys(tableDataCopy)
        const columnKeyToBeRemoved = tableDataKeys[index]
        tableDataCopy[columnKeyToBeRemoved].forEach(item => {
            if (item.code !== "Break Time") {
                tableDataCopy["Events_Events"].push(item)
            }
        })
        delete tableDataCopy[columnKeyToBeRemoved]
        evokeFilteredComp(tableDataCopy)
        return tableDataCopy;
    }
    catch (err) {
        console.log(err)
    }
}

//remove competition from a stage and adds to the Events column
export const removeCompFromStage = (columnKey, item, index, tableData, setTableData, evokeFilteredComp) => {
    try {
        const eventsColumn = [...tableData["Events_Events"]]
        eventsColumn.push(item)
        const newColumnItems = [...tableData[columnKey]];
        const startTime = tableData[columnKey][0]?.startTime
        newColumnItems.splice(index, 1);
        const timeAlteredCol = setTimingsWithinCol(newColumnItems, startTime)
        if (item.code !== "Break Time") {
            const updatedTableData = {
                ...tableData,
                [columnKey]: timeAlteredCol,
                Events_Events: eventsColumn
            };
            evokeFilteredComp(updatedTableData)
            setTableData(prevData => ({
                ...prevData,
                [columnKey]: timeAlteredCol,
                Events_Events: eventsColumn
            }));
        } else {
            setTableData(prevData => ({
                ...prevData,
                [columnKey]: timeAlteredCol,
            }));
        }
    } catch (err) {
        console.log(err)
    }
}

export const getHeadingTd = (columnKey, columnIndex, stickyStyle, nonStickyStyle, removeStageModalHandler) => {
    // to store id and stage names as keys, they are mixed up to
    try {
        const columnName = columnKey.split("_")[0]
        const columnId = columnKey.split("_")[1]
        return (
            <td key={columnKey} style={columnIndex === 0 ? stickyStyle : nonStickyStyle}>
                {columnIndex === 0
                    ? <div className="p-2 scheduleMakerColumn">
                        {columnName}
                    </div >
                    : <div className="p-2 d-flex justify-content-between scheduleMakerColumn">
                        <div> {columnName}</div>
                        <img src={closeIcon} style={{ cursor: "pointer" }} onClick={() => removeStageModalHandler(columnIndex, columnName, columnId)} />
                    </div>}
            </td>
        )
    } catch (err) {
        console.log(err)
    }
}



export const setTimingsWithinCol = (newColumnItems, startTime) => {
    try {
        const timeAlteredCol = newColumnItems?.map((item, index) => {
            let startMoment = moment(item.startTime, 'h:mm A');
            let endMoment = moment(item.endTime, 'h:mm A');
            let differenceInMinutes = endMoment.diff(startMoment, 'minutes');
            item.startTime = index === 0 ? startTime : newColumnItems[index - 1].endTime;
            const endTime = moment(item.startTime, 'hh:mm A').add(differenceInMinutes, 'minutes');
            item.endTime = endTime.format('h:mm A')
            return item
        })
        return timeAlteredCol
    } catch (err) {
        console.log(err)
    }
}

export const getDraggableItemClass = (columnIndex, snapshot, index, compCode) => {
    let classNames = "draggableItem";
    if (columnIndex === 0 && index === 0) {
        classNames += " stickyFirst";
    } else if (columnIndex === 0 && index === 1) {
        classNames += " stickySecond";
    }
    if (columnIndex === 0 || compCode === "Break Time") {
        classNames += " justify-content-center";
    }
    if (snapshot.draggingOverWith) {
        classNames += " dragging";
    }
    return classNames
}

export const calculateCompDuration = (item) => {
    try {
        const competitors = item?.dancers?.length
        const r1DancersPerTime = item?.dancerCount[0]?.numberOfDancers || 2
        const r2DancersPerTime = item?.dancerCount[1]?.numberOfDancers || 2
        let totalDuration;
        if (item?.rounds == 1) {
            totalDuration = (competitors / r1DancersPerTime) * 2.25

        } else if (item?.rounds == 2) {
            const r1Duration = (competitors / r1DancersPerTime) * 2.25
            const r2Duration = (competitors / r2DancersPerTime) * 2
            totalDuration = r1Duration + r2Duration
        }
        else if (item?.rounds == 3) {
            const r1Duration = (competitors / r1DancersPerTime) * 2.25
            const r2Duration = (competitors / r2DancersPerTime) * 2
            const r3Duration = competitors * 2
            totalDuration = r1Duration + r2Duration + r3Duration
        }
        return Math.ceil(totalDuration)
    } catch (err) {
        console.log(err)
    }
}

export const formatDataForCSV = (tableData) => {
    // Create headers for each stage
    const data = getCSVData(tableData)

    const headers = data.flatMap(item => [
        "", item.stageName, ""
    ]);
    const header2 = data.flatMap(item => ["Competition", "Competitors", "Timing"])


    // Map timings to rows with competition ID, start time, and end time
    const rows = [];
    const maxCompetitions = Math.max(...data.map(item => item.timings.length));
    rows.push(header2.join(','));

    for (let i = 0; i < maxCompetitions; i++) {
        const row = data.flatMap(item => {
            const timing = item.timings[i] || {};
            const startTime = timing.startTime || '';
            const endTime = timing.endTime || '';
            const timeRange = startTime && endTime ? `${startTime}-${endTime}` : '';
            const dancerCount = timing?.compCode ? (timing?.dancerCount || "-") : ""
            return [
                timing.compCode || '',
                dancerCount,
                timeRange,
            ];
        });
        rows.push(row.join(','));
    }
    // Combine headers and rows
    const csvContent = [
        headers.join(','),
        ...rows
    ].join('\n');

    return csvContent;
};

export const downloadCSV = (csvContent, filename) => {
    try {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    } catch (err) {
        console.log(err)
    }
};

export const getSchedulePayload = (tableData) => {
    try {
        let data = [];
        Object.keys(tableData)?.forEach(stageIdWithName => {
            if (stageIdWithName !== "Events_Events") {
                const stageId = stageIdWithName.split("_")[1]
                const currStageComps = tableData[stageIdWithName];
                const timingArray = currStageComps.map(comp => {
                    if (Object.keys(comp).length !== 0) {
                        if (comp.code === "Break Time") return {
                            type: "break",
                            startTime: comp.startTime,
                            endTime: comp.endTime,
                        }
                        else return {
                            type: "competition",
                            competitionId: comp.competitionId,
                            startTime: comp.startTime,
                            endTime: comp.endTime,
                            dancerCount: comp.dancerCount
                        }
                    }
                })
                const perStageDetail = { _id: stageId, timings: timingArray }
                data.push(perStageDetail)
            }
        })
        return data
    } catch (err) {
        console.log(err)
    }
}

export const getCSVData = (tableData) => {
    try {
        let data = [];
        Object.keys(tableData).forEach(stageIdWithName => {

            if (stageIdWithName !== "Events_Events") {
                const stageName = stageIdWithName.split("_")[0]
                const currStageComps = tableData[stageIdWithName];
                const timingArray = currStageComps.map(comp => {
                    if (Object.keys(comp).length !== 0) {
                        console.log(comp)
                        if (comp.code === "Break Time") return {
                            type: "break",
                            startTime: comp.startTime,
                            endTime: comp.endTime,
                            compCode: comp.code,
                        }
                        else return {
                            type: "competition",
                            compCode: comp.code,
                            startTime: comp.startTime,
                            endTime: comp.endTime,
                            dancerCount: comp.dancers.length
                        }
                    }
                })
                const perStageDetail = { stageName: stageName, timings: timingArray }
                data.push(perStageDetail)
            }
        })
        return data
    } catch (err) {
        console.log(err)
    }
}

export const getAgeValue = (age) => {
    if (age === "22 AND OVER") return 22.5; // Treating "22 AND OVER" as 22.5
    return parseInt(age.replace("U", ""), 10);
  };