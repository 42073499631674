import React from 'react'
import moment from "moment";
import { useNavigate } from "react-router-dom";
import favIcon from "../../Assets/favicon.ico"
import { NavigationHelper } from './NavigationHelper';
import { formatDate } from '../../helper/formatDate';

const MobileCard = ({ event, from, isHomePage }) => {
    const navigate = useNavigate()

    let eventDate = event?.eventType === "Feiseanna" ? event?.feis_date : event?.exam_date;
    const registerBtn = isHomePage ? "Register Now" : "Register";
    const btnName = from === "open" ? registerBtn : from === "closed" ? "Event Details" : from === "result" ? " View Results" : "View Details"
    const registrationDetailDate = from === "upcoming" ? event?.opening_date : event?.closing_date
    const registrationDetail = from === "open" ? "closes" : from === "closed" ? "closed" : from === "result" ? "closed" : "starts"


    const goToViewEvent = () => {
        NavigationHelper(event, from, navigate);
    };

    return (
        <div className="event-mobile" data-value={event._id}>
            <>
                <div className="event-mobile-div" onClick={() => goToViewEvent()}>
                    <div>
                        <img src={event.logo || favIcon} className="event-img" alt="logo" />
                    </div>
                    <div>
                        <p className="title m-0">{event.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
                        <p className="event-name m-0"> {event.name || "NA"} </p>
                        <p className="reg-date mt-2   ">
                            {eventDate ? moment(formatDate(eventDate)).format(" MMMM Do, YYYY") : "NA"}{" "}- {event.venue_city}, {event.state}
                        </p>
                    </div>
                </div>
                <div className="reg-close">
                    <div className="mobile-registration">
                        <p className="reg-date-mobile m-0"> {`Registration ${registrationDetail}:`}</p>
                        <p className="reg-date-close m-0">
                            {registrationDetailDate ? moment(formatDate(registrationDetailDate)).format("MMM Do, YYYY") : "NA"}
                        </p>
                    </div>
                    <div>
                        <button
                            className="mob-register-btn filled mt-1"
                            onClick={() => goToViewEvent()}
                        >
                            {btnName}
                        </button>
                    </div>
                </div>
            </>
        </div>
    )
}

export default MobileCard