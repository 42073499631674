import React from 'react'
import help from "../../Assets/Help.svg"
import "./Help.css"

const Help = () => {
    return (
        <a id="mailIcon" href="mailto:info@feis.link" >
            <div className='helpImageDiv'>
                <img src={help} alt="help" />
            </div>
        </a>
    )
}

export default Help