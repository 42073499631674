import React from 'react'

const StripeDescription = () => {
    return (
        <div className="detailsHeaders">
            <h4 className="eventPage">Payment</h4>
            <div className="eventDescription">
                Stripe is used to process payments and distribute payments. Login to Stripe or create a new Stripe account. Upto 3 accounts can be added.
            </div>
        </div>
    )
}

export default StripeDescription
