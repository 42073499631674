import React from 'react'
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { v4 as uuidv4 } from "uuid"
import { findSuffixes } from '../../helper/findSuffixes';

const CommentBox = ({ scoresArr, loading, adjudicators, dancerDetail, selectedDancerId }) => {
    return (
        <div key={uuidv4()}>
            {adjudicators > 1 && scoresArr?.length > 0 && <div className="d-flex mt-3 event-points font-weight-bold">
                <>
                    <span>Your Place:&nbsp;</span>
                    <span>{findSuffixes(dancerDetail?.overallPosition)}</span>
                </>
                <>
                    <span className="ms-3">Total Points:&nbsp;</span>
                    <span>{dancerDetail?.irish_points}</span>
                </>
            </div>}
            {!loading && scoresArr?.map((dancer, dancerIndex) =>
                <>
                    <Box
                        className="adjudicatorCard web-result-card mt-3 mb-5    "
                        sx={{ boxShadow: 1 }}
                        style={{
                            width: "100%",
                            height: "auto !important",
                            background: "#FFFFFF",
                            borderRadius: "4px",
                            boxShadow: "0px 15px 28px rgba(0, 0, 0, 0.05)",
                            padding: " 10px",
                        }}
                    >
                        <div className="d-flex flex-grow-1">
                            <div>
                                <Avatar
                                    src={dancer?.judge_details?.photo}
                                    style={{
                                        height: "65.14px",
                                        width: "65.14px",
                                        margin: "5px",
                                    }}
                                    alt="logo"
                                />
                            </div>
                            <div className="flex-grow-1">
                                <div className="event-judge-name mx-md-3" >{dancer?.judge_details?.name}{adjudicators > 1 && `:  ${findSuffixes(dancer?.position)}: ${dancer?.irish_points} points`}</div>
                                {dancer?.scores_by_round?.map((score_detail, scoreIndex) => {
                                    return < div className="d-flex flex-column flex-md-row" >
                                        <div className="mx-md-3 d-flex flex-column justify-content-center flex-grow-1">
                                            <div className="d-flex event-points flex-column flex-md-row " >
                                                <div className='col-md-3'>{`Round ${score_detail?.round}: ${score_detail?.score}`}</div>
                                                <div className='col-md-3'>Place: <span></span>{dancer?.rank_by_round[scoreIndex].rank}</div>
                                                <div > <span style={{ fontWeight: "500" }}>Comments:</span>  {score_detail?.comments ? score_detail?.comments : "N/A"} </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                )}
                            </div>
                        </div>
                    </Box>
                    <Box
                        className="adjudicatorCard mob-result-card mt-3 mb-5    "
                        sx={{ boxShadow: 1 }}
                        style={{
                            width: "100%",
                            height: "auto !important",
                            background: "#FFFFFF",
                            borderRadius: "4px",
                            boxShadow: "0px 15px 28px rgba(0, 0, 0, 0.05)",
                            padding: " 10px",
                        }}
                    >
                        <div >
                            <div className="d-flex align-items-center   ">
                                <Avatar
                                    src={dancer?.judge_details?.photo}
                                    style={{
                                        height: "65.14px",
                                        width: "65.14px",
                                        margin: "5px",
                                    }}
                                    alt="logo"
                                />
                                <div >
                                    <div className=" event-judge-name-mob mx-md-3" >{dancer?.judge_details?.name}</div>
                                    <div className="judge-final-score-mob">{adjudicators > 1 && `${findSuffixes(dancer?.position)} - ${dancer?.irish_points} points`}</div>
                                </div>
                            </div>

                            <div className="flex-grow-1">
                                {dancer?.scores_by_round?.map((score_detail, scoreIndex) => {
                                    return < div className="d-flex flex-column flex-md-row mb-3"  >
                                        <div className="mx-md-3 d-flex flex-column justify-content-center flex-grow-1">
                                            <div className="d-flex event-points flex-column flex-md-row " >
                                                <div className='col-md-3 font-weight-bold'>{`Round ${score_detail?.round}: ${findSuffixes(dancer?.rank_by_round[scoreIndex].rank)} - ${score_detail?.score}`}</div>
                                                <div > <span>Comments:</span>  {score_detail?.comments ? score_detail?.comments : "N/A"} </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                )}
                            </div>
                        </div>
                    </Box>
                </>
            )
            }
            {!loading && selectedDancerId && scoresArr?.length === 0 && <div className="my-5 d-flex justify-content-center text-danger">This competitior has not participated in this competition</div>}
        </div >
    )
}

export default CommentBox