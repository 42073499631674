import React from 'react'

const CompetitionCheckBox = ({ item, index, dancer, group, checkBoxSelectHandler, selectedLevelData }) => {

    const isChecked = (index, competitionId) => {
        if (selectedLevelData && selectedLevelData[index]) {
            const dancer = selectedLevelData[index];
            const competition = dancer?.competitions?.find(item => item === competitionId);
            if (competition) return true;
            else return false;
        } else return false;
    };

    return (
        <div
            className="btn-group btn-group-toggle action"
            data-toggle="buttons"
            style={{ margin: "7px" }}
            key={item?._id}
        >
            <label
                style={{ float: "left" }}
                className={isChecked(index, item._id) ? "btn activeBtn" : "btn"}
            >
                <input
                    type="checkbox"
                    checked={isChecked(index, item._id)}
                    onClick={(event) => checkBoxSelectHandler(item?._id, dancer, index, item?.price, group,)}
                />
                {item?.code} - {item?.description} ${item?.price} per dance
            </label>
        </div>
    )
}

export default CompetitionCheckBox