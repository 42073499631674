import React, { useState } from 'react'
import Popover from '@mui/material/Popover';
import Radio from '../../Components/Radio/Radio';
import filterIcon from "../../Assets/filter.svg"

const AwardTableFilters = ({ filter, setFilter }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <img className="cursor-pointer" src={filterIcon} alt="filter" onClick={handleFilterClick} />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleFilterClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ marginTop: '5px', marginLeft: 0 }}
            >
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("all")} >
                    All
                    <Radio className="mb-3" checked={filter === "all"} />
                </div>
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("award_collected")}>
                    Awards Collected
                    <Radio className="mb-3" checked={filter === "award_collected"} />
                </div>
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("award_not_collected")}>
                    Awards Not Collected
                    <Radio className="mb-3" checked={filter === "award_not_collected"} />
                </div>
                <div
                    className=" ps-3 py-2 px-3 d-flex align-items-center justify-content-between create-event-opt"
                    onClick={() => setFilter("results_not_published")}>
                    Results Not Published
                    <Radio className="mb-3" checked={filter === "results_not_published"} />
                </div>
            </Popover>
        </>
    )
}

export default AwardTableFilters
