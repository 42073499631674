import React, { useEffect, useState } from 'react'
import HeaderOrg from '../../../Headers/HeaderOrg'
import ManageTitle from '../../../../Components/TitleElements/ManageTitle'
import { useNavigate } from 'react-router-dom'
import { serviceConsumer } from '../../../../network/ServiceConsumer'
import Loader from '../../../../Components/Loader/Loader'
import Help from '../../../../Components/Help/Help'
import EditedDetails from './EditedDetails'
import EditRegisterSuccess from '../../../../Components/Modals/OrganizerModel/ManageFeis/Entries/EditRegisterSuccess'
import { agesCode } from '../../../../helper/DataArrays'
import { filterCompBasedOnAgesAndTeams, setSelectedDataFromLevelData, sortLevels } from '../../../../helper/RegFlowHelpers'
import LevelButton from '../../../../Parent/Register-Events/Components/LevelButton'
import CompetitionCheckBox from '../../../../Parent/Register-Events/Components/CompetitionCheckBox'
import { getIsTeacher } from '../../../../helper/getIsTeacher'


const EditRegister = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [eventDetails, setEventDetails] = useState();
  // const [prevServiceFee, setPrevServiceFee] = useState(0);
  const [prevTotal, setPrevTotal] = useState(0);
  const [prevFamilyFee, setPrevFamilyFee] = useState(0);
  const [initialLoading, setInitialLoading] = useState(false)
  const [enableButton, setEnableButton] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [error, setError] = useState("")
  const [modal, setModal] = useState("")
  const [total, setTotal] = useState(0);
  const [serviceFee, setServiceFee] = useState(0);
  const [prevDancerTotal, setPrevDancerTotal] = useState(0);


  // all dancer in an event
  const [allDancer, setAllDancer] = useState([]);

  // grouped level wise competitions list for a dancer
  const [levelData, setLevelData] = useState();

  // selected level's competitions list for all dancer, here one dancer in edit flow
  const [selectedLevelData, setSelectedLevelData] = useState([]);

  // selected competitions list for all dancer, here one dancer in edit flow
  const [previousCompetition, setPreviousCompetition] = useState([]);

  // for each dancer selected levels
  const [selectedLevels, setSelectedLevels] = useState([]);

  const searchParams = new URLSearchParams(window.location.search)
  const regId = searchParams.get('regId')
  const eventId = searchParams.get('id')

  const handleClick = async (level, index) => {
    const newArr = [...selectedLevels];
    newArr[index] = level;
    setSelectedLevels(newArr)
  };

  //  to get eventDetails on back
  const getEventDetails = async () => {
    setInitialLoading(true);
    try {
      if (eventId) {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/${eventId}`;
        const response = await serviceConsumer('GET', url);
        setEventDetails(response.Feis);
      }
    } catch (error) {
      navigate("/pageNotFound", { replace: true })
      setInitialLoading(false);
    }
  };

  const getRegistrationDetail = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/registration/regdetails/${regId}`;
    try {
      setInitialLoading(true);
      const response = await serviceConsumer('GET', url);
      if (response?.result?.length) {
        const uniqueComp = [...new Set(response.result[0]?.competitions)];
        const dancerAge = new Date().getFullYear() - Number(response?.result[0]?.dancerData?.birthday)
        const dancerAgeToCompare = dancerAge < 5 ? 5 : dancerAge > 22 ? 23 : dancerAge;
        const ageObject = agesCode.find(age => age.key === (dancerAgeToCompare).toString())
        setAllDancer([{
          _id: response.result[0]?.dancer_id,
          fname: response.result[0]?.dancerData?.fname,
          lname: response.result[0]?.dancerData?.lname,
          competitions: uniqueComp,
          ages: ageObject,
          gender:response.result[0]?.dancerData?.gender,
          levels: response.result[0]?.dancerData?.levels,
        }]);
        setSelectedLevels(response.result[0]?.levels ? [response.result[0]?.levels[0]] : []);
        setPrevFamilyFee(response.result[0]?.fee);
        setPrevTotal(response.result[0]?.allLevelAmount)
        setPrevDancerTotal(response.result[0]?.dancerTotal)
        // setPrevServiceFee(response.result[0]?.dancerServiceAmount)
        const data = {
          _id: response.result[0]?.dancer_id,
          event_id: eventId,
          competitions: uniqueComp,
        };
        setPreviousCompetition(uniqueComp)
        setSelectedLevelData([data]);
      }
      setInitialLoading(false);
    } catch (error) {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    getEventDetails()
    getRegistrationDetail()
    getIsTeacher(setLoading)
    document.title = "Feis.Link - Register Dancers"
  }, []);

  useEffect(() => {
    if (levelData) {
      setSelectedDataFromLevelData(levelData, allDancer, selectedLevelData, setSelectedLevelData)
    }
  }, [levelData]);

  const getLevelCompetitions = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/feis/levelwise/competitions/${eventId}`;
    const res = await serviceConsumer('GET', url);
    const levelDataResponse = res.result
    const sortedLevels = sortLevels(levelDataResponse)
    if (res) setLevelData(sortedLevels)
  }

  useEffect(() => {
    if (eventDetails) {
      getLevelCompetitions()
    }
  }, [eventDetails]);

  const checkBoxSelectHandler = async (competitionId, dancerDetail, index, price, level) => {
    const dancer = selectedLevelData[index]
    if (dancer?.competitions?.find(item => item === competitionId)) {
      deleteItemHandler(competitionId, dancerDetail?._id, index, level)
    } else {
      setLoading(true)
      const updatedComp = dancer?.competitions ? [...dancer.competitions, competitionId] : [competitionId];
      const newLevelItem = levelData[level]?.find(item => item._id === competitionId);

      // grouped competition list based on level
      const updatedLevelPriceData = dancer?.levelPriceData && dancer?.levelPriceData[level]
        ? { ...dancer?.levelPriceData, [level]: [...dancer?.levelPriceData[level], newLevelItem] }
        : dancer?.levelPriceData
          ? { ...dancer?.levelPriceData, [level]: [newLevelItem] }
          : { [level]: [newLevelItem] }

      const data = {
        _id: dancerDetail?._id,
        event_id: eventId,
        competitions: updatedComp,
        levelPriceData: updatedLevelPriceData
      };

      const newArr = [...selectedLevelData];
      newArr[index] = data;

      setSelectedLevelData(newArr);
      setTimeout(() => {
        setLoading(false)
      }, 300);
    }
  };

  const deleteItemHandler = (competitionId, dancerId, index, level) => {
    setLoading(true)
    const dancer = selectedLevelData[index];

    if (dancer?.competitions?.length === 1) {
      const newArr = [...selectedLevelData];
      newArr.splice(index, 1);

      setSelectedLevelData(newArr);
    } else {
      const competitionsNew = dancer?.competitions?.filter(competition => competition !== competitionId)

      // grouped competition list based on level
      const updatedLevelPriceData = dancer?.levelPriceData && dancer?.levelPriceData[level]
        ? { ...dancer?.levelPriceData, [level]: [...dancer?.levelPriceData[level]?.filter(it => it._id !== competitionId)] }
        : dancer?.levelPriceData
          ? delete dancer?.levelPriceData[level]
          : {}

      const data = {
        competitions: competitionsNew,
        _id: dancerId,
        event_id: eventId,
        levelPriceData: updatedLevelPriceData
      };

      const newArr = [...selectedLevelData];
      newArr[index] = data;

      setSelectedLevelData(newArr);
    }
    setTimeout(() => {
      setLoading(false)
    }, 300);
  };

  const saveChangeHandler = async () => {
    const amount = paymentArray[0]?.amount?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    try {
      const payload = {
        totalAmount: purchaseTotal + prevDancerTotal,
        serviceAmount: paymentArray[0].serviceFee,
        registration_id: regId,
        competitions: paymentArray[0].competitions,
        amount: paymentArray[0].amount,
        allLevelAmount: paymentArray[0].allLevelAmount,
        dancerTotal: purchaseTotal + prevDancerTotal,
        dancer_id: selectedLevelData[0]._id,
        dancerServiceAmount: paymentArray[0].dancerServiceAmount
      }
      const url = `${process.env.REACT_APP_BASE_URL}/feis/editcompetitor`;
      setLoading(true)
      const response = await serviceConsumer("POST", url, payload)
      if (response.success) {
        setModal("EditRegisterSuccess")
        setTimeout(() => { navigate(`/edit-competitors/${eventDetails?.name}?id=${eventId}`, { state: { eventName: eventDetails?.name } }) }, 3000)
      }
      setLoading(false)
    }
    catch (error) {
      setError(error.messages)
    }
    setLoading(false)
  }

  return (
    <>
      <HeaderOrg />
      {!initialLoading && <ManageTitle eventLogo={eventDetails?.logo} eventName={eventDetails?.name} eventType={eventDetails?.eventType} eventDate={eventDetails?.feis_date} className={enableButton ? "filled" : "disabled"} button="Save Changes" onClick={saveChangeHandler} />}
      {initialLoading && <Loader />}
      {loading && <Loader />}
      {!initialLoading && <div className="row edit-register-org" >
        <div className="col-md-7 leftDiv editOrg-leftDiv">
          {allDancer.map((dancer, index) => {
            return (
              <div className=" col-10 my-5" key={dancer._id} >
                <h5>
                  Register dancer:&nbsp;
                  <span style={{ color: "#68ad3f" }}>{dancer.fname} {dancer.lname}</span>
                </h5>
                <hr className="mt-0 mb-4" />
                <h6 style={{ color: "#323232", fontSize: "12px", }} className="my-2">
                  Choose Levels:
                </h6>
                <div className="buttonGroup">
                  {/* for each level in levelData show a level button */}
                  {levelData && Object.entries(levelData).map(([level, data]) =>
                    <LevelButton level={level} data={data} dancer={dancer} index={index} handleClick={handleClick} selectedLevels={selectedLevels} />
                  )}
                </div>

                {/* Check if selected dancer level is in selectedLevels */}
                {selectedLevels[index] && (
                  <div>
                    <p className="mt-3 mb-2" style={{ fontSize: "12px", fontWeight: "500", color: "#68AD3F", }}>
                      {selectedLevels[index]}
                    </p>
                    <div style={{ border: "2px solid rgb(234, 234, 234)", width: "fit-content", }}>
                      {/* check if slected */}
                      {levelData && filterCompBasedOnAgesAndTeams(selectedLevels[index], dancer, levelData)?.map((item) => {
                        // const group = item.group.toLowerCase() === "specials" ? item.levels[0] : item.group;
                        const group = item.group;
                        if (group === selectedLevels[index]) {
                          return <CompetitionCheckBox item={item} index={index} dancer={dancer} group={group} checkBoxSelectHandler={checkBoxSelectHandler} selectedLevelData={selectedLevelData} />
                        }
                      })}
                    </div>
                  </div>
                )}
                {(levelData && Object.keys(levelData)?.includes("SPECIALS") && filterCompBasedOnAgesAndTeams("SPECIALS", dancer, levelData)?.length > 0 &&
                  <div>
                    <p className="mt-3 mb-2" style={{ fontSize: "12px", fontWeight: "500", color: "#68AD3F", }}>
                      SPECIALS
                    </p>
                    <div style={{ border: "2px solid rgb(234, 234, 234)", width: "fit-content", }}>
                      {selectedLevels[index] && filterCompBasedOnAgesAndTeams("SPECIALS", dancer, levelData)?.map((item) => {
                        return <CompetitionCheckBox item={item} index={index} dancer={dancer} group={"SPECIALS"} checkBoxSelectHandler={checkBoxSelectHandler} selectedLevelData={selectedLevelData} />
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          className="col-md-5 d-flex flex-column "
          style={{ background: "#fafafa", minHeight: "92vh", height: "fit-content", overflowY: "auto" }}
        >
          <EditedDetails
            edit
            regId={regId}
            prevTotal={prevTotal}
            prevFamilyFee={prevFamilyFee}
            previousCompetition={previousCompetition}
            // prevServiceFee={prevServiceFee}
            prevDancerTotal={prevDancerTotal}
            selectedLevelData={selectedLevelData}
            allDancer={allDancer}
            eventDetails={eventDetails}
            deleteItemHandler={deleteItemHandler}
            setEnableButton={setEnableButton}
            setPaymentArray={setPaymentArray}
            purchaseTotal={purchaseTotal}
            setPurchaseTotal={setPurchaseTotal}
            setTotal={setTotal}
            serviceFee={serviceFee}
            setServiceFee={setServiceFee}
          />
        </div>
        <Help />
        {modal === "EditRegisterSuccess" && <EditRegisterSuccess modalState={true} eventName={eventDetails?.name} />}
      </div>}
    </>
  )
}

export default EditRegister