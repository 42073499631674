import React, { useState } from "react";
import OneJudgeResult from "./ResultTables/OneJudgeResult";
import ThreeJudgeResult from "./ResultTables/ThreeJudgeResult";
import NineJudgeResult from "./ResultTables/NineJudgeResult";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { getPrintHandler } from "../../helper/printHandlers";

const ViewResultsTable = ({ sortedResults, rounds, adjudicators, keyProp, compId, event, competition, dancerDetail, selectedDancerId }) => {
    const [loading, setLoading] = useState(false)

    const printResultsHandler = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/feis/results/download/pdf?compId=${compId}&dancerId=${dancerDetail?.dancer_id}`;
        getPrintHandler(url, setLoading)
    }

    return (
        <>
            {sortedResults && sortedResults.length > 0 && (
                <>
                    {loading && <Loader />}
                    {adjudicators === 1 && <OneJudgeResult sortedResults={sortedResults} rounds={rounds} keyProp={keyProp} event={event} />}
                    {adjudicators === 3 && <ThreeJudgeResult sortedResults={sortedResults} rounds={rounds} keyProp={keyProp} event={event} />}
                    {adjudicators === 9 && <NineJudgeResult sortedResults={sortedResults} rounds={rounds} keyProp={keyProp} event={event} />}
                </>
            )}
            {localStorage.getItem("accessToken") && selectedDancerId && <button className="filled" onClick={printResultsHandler}>Print Results</button>}
        </>
    );
};

export default ViewResultsTable;
