import React from 'react'
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import favIcon from "../../../Assets/favicon.ico"
import { formatDate } from '../../../helper/formatDate';

const RegisterTitle = ({ eventDetails, onClick, deleted }) => {
    return (
        <div className="offset-md-2 col-md-7 col-10 offset-1 pt-2 ">
            {!deleted && <div
                className="d-flex align-items-center"
                onClick={onClick}
                style={{ color: "#68AD3F", cursor: "pointer", width: "min-content;", }}
            >
                <div>
                    <NavigateNextIcon style={{ transform: "rotate(180deg)" }} />
                </div>
                <div className="back">Back</div>
            </div>}
            <div className="d-flex flex-grow-1 pb-2 align-items-center">
                <div>
                    <div></div>
                    <img src={eventDetails?.logo || favIcon} className="event-img-view" alt="logo" />
                </div>
                <div className="eventdetails" style={{ minWidth: "40rem !important" }}>
                    <p className="title m-1">{eventDetails?.eventType === "Feiseanna" ? "Feisanna" : "Grade Exam"}</p>
                    <p className="event-name m-1">{eventDetails?.name || "NA"}</p>
                    <p className="reg-date-open m-1">
                        {(eventDetails?.eventType === "Feiseanna" ? eventDetails?.feis_date : eventDetails?.exam_date)
                            ? moment(formatDate(eventDetails?.eventType === "Feiseanna" ? eventDetails?.feis_date : eventDetails?.exam_date)).format(" MMMM Do, YYYY")
                            : "NA"}{" "}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RegisterTitle