import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./Loader.css";

const Loader = ({ payment }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (payment) {
      const timer = setTimeout(() => {
        setShowLoader(true);
      }, 500); // Adjust the delay time as needed
      return () => clearTimeout(timer);
    } else {
      setShowLoader(true);
    }
  }, [payment]);

  const renderUI = () => {
    return (
      <div style={{ position: "absolute" }}>
        <div className={payment ? "page-loader-payment" : "page-loader"} id="page_loader">
          <CircularProgress color="inherit" />
          {payment && <div className="text-center d-block">Please wait while the payment is getting processed...</div>}
          <div className="lds-spinner">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
      </div>
    );
  };

  return showLoader ? renderUI() : null;
};

export default Loader;

