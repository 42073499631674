import React, { useRef } from 'react';

const NumberInput = ({ field }) => {
    const inputRef = useRef(null);

    const handleScroll = (event) => {
        if (inputRef.current === document.activeElement) {
            // If the number input is focused, blur it
            inputRef.current.blur();
        }
    };

    return (
        <input
            autoComplete="off"
            type="number"
            {...field}
            ref={inputRef}
            onWheel={handleScroll}
        />
    );
};

export default NumberInput;
