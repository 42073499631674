import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

import eventMobile from '../Assets/event-img-mobile.png';
import AutomaticIcon from '../Assets/AutomaticIcon.png';
import BetterIcon from '../Assets/BetterIcon.png';
import getPaidIcon from '../Assets/getPaidicon.png';
import EventsHeader from '../Assets/EventsHeader.png';

const MessageForOrganizers = () => {
    const navigate = useNavigate();

    const navigateToSignupOrg = () => {
        navigate('/signup-org');
    };

    return (
        <>
            <section className="mb-5 mt-5">
                <div className="bg-3"></div>
                <div className=" dashboard-organizer d-flex justify-content-center align-items-center">
                    <h3> ORGANIZERS</h3>
                </div>
            </section>

            <section className="mb-5 mt-5">
                <div className="WhyFeis ">
                    <Typography variant="h2" sx={{ textAlign: 'center', height: 'auto' }}> WHY FEIS.LINK?</Typography>
                    <div className="advantages-list">
                        <div className="col-sm-12 col-md-3 advantages-card">
                            <img src={AutomaticIcon} alt="smart" />
                            <p className="adv-title">Automatic</p>
                            <p className="adv-desc">
                                You’re running a Feis, you’ve got a lot to do - we know because we’ve been there. The whole purpose of Feis.link is to
                                make things easier for you, the organizer. We’ll advertise your Feis, create a website for your Feis, post the syllabus,
                                and competitor lists, handle all of the registrations, changes, and support emails. We’re here to lighten your load,
                                because running a Feis is hard enough.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 advantages-card">
                            <img src={getPaidIcon} alt="smart" className="getPaidIcon" />
                            <p className="adv-title">Get Paid</p>
                            <p className="adv-desc">
                                You set the price list for your Feis. As competitors register, funds are deposited directly into your account. Feis.link
                                charges a small percentage on each registration that is paid by the registrant – this covers the cost of credit card
                                processing fees. There are ZERO additional charges to you.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 advantages-card">
                            <img src={BetterIcon} alt="smart" />
                            <p className="adv-title">Better</p>
                            <p className="adv-desc">
                                A parent’s first impression of a Feis is often formed during the registration process. If it’s confusing, overly complex,
                                or difficult to make changes, it reflects poorly on the Feis. That’s why we’ve put a lot of thought and effort into our
                                method to make the experience as simple and straight forward as possible. Feis.link helps you make a strong first
                                impression with a strong registration process.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-1 mt-1">
                <div className=" event-links">
                    <div className="create-event">
                        <h2 style={{ color: '#000000' }}>CREATE YOUR EVENTS<br /> ON FEIS.LINK</h2><span className="border-line-2"></span>
                        <Button
                            disableRipple
                            variant="outlined"
                            color="primary"
                            id="create-evnt-btn-res"
                            sx={{
                                border: '4px solid #68AD3F',
                                fontWeight: 500,
                                fontSize: '22px',
                                lineHeight: '150%',
                                marginTop: '25px',
                                borderRadius: '6px',
                                '&:hover': {
                                    border: '4px solid #68AD3F',
                                    background: 'white',
                                },
                            }}
                            className="create-event-btn"
                            onClick={navigateToSignupOrg}
                        >
                            Create your event
                        </Button>
                    </div>
                    <div className=" event-section">
                        <img src={EventsHeader} className="events-image" alt="event" />
                    </div>
                    <div className="col-md-6 event-section-mobile">
                        <img src={eventMobile} className="events-image-mobile" alt="event" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default MessageForOrganizers