export const compTableSearch = (responseArray, searchInput, setTableArr) => {
    const tableArr = responseArray?.filter((searching) => {
        if (searchInput === '') {
            return searching;
        } else if (
            (searching?.dancerData?.fname?.toLowerCase().includes(searchInput?.toLowerCase())
                || searching?.dancerData?.lname?.toLowerCase().includes(searchInput?.toLowerCase())
                || searching?.dancerData?.school?.toLowerCase().includes(searchInput?.toLowerCase())
                || searching?.dancerData?.birthday?.includes(searchInput?.toLowerCase())
                || searching?.card_number?.toString().startsWith(searchInput.toString())
                || searching?.competitonData?.map((item) => item?.code).toString().toLowerCase().includes(searchInput.toLowerCase())
            )
        ) {
            return searching;
        }
    });
    setTableArr(tableArr);
}