import React from 'react'
import { v4 as uuidv4 } from 'uuid';
import "../../../Components/Table.css"
import { CalculateCount } from './CalculateCount';

function NineJudgeResult({ sortedResults, keyProp, completeResult, event }) {
  const refinedResults = sortedResults.map(dancer => {
    const refinedScores = [[], [], []]
    dancer?.scores?.forEach(item => {
      refinedScores[item?.scores_by_round[0]?.round - 1].push({ judge: item?.judge_details?.name, score: item?.scores_by_round[0]?.score, irish_points: item.irish_points });
    });
    return {
      cardNumber: dancer?.card_number?.card_number,
      fname: dancer?.dancer_fname,
      lname: dancer?.dancer_lname,
      school: dancer?.school,
      overallPosition: dancer?.overallPosition,
      overallScore: dancer?.irish_points,
      scores: refinedScores
    }
  })
  let roundCount = [1, 2, 3]
  const countOfTopPlaces = event && CalculateCount(event, sortedResults)
  const finalResult = completeResult === "true" ? refinedResults : refinedResults.slice(0, countOfTopPlaces)
  return (
    <div style={{ width: "100%", overflowX: "scroll", marginTop: "30px", marginBottom: "30px" }} key={keyProp}>
      <table className="table my_table competitorTable">
        <tbody>
          <tr className='header-row'>
            <td className="text-center p-25-5 w-3" > </td>
            <td className="text-center p-25-5 w-4"></td>
            <td className="mx-5 p-25-5 w-9"></td>
            {roundCount.map((item, index) => {
              return <td key={uuidv4()} className="p-25-5 text-center w-12">{`Round ${item}`}</td>
            })}
          </tr>
          <tr style={{ background: "#EAEAEA", fontSize: "14px", height: "31px" }}>
            <td className="w-3 text-center">Final Place</td>
            <td className="w-4 text-center">Total Irish Points</td>
            <td className="w-9">Competitor</td>
            {refinedResults[0]?.scores?.map((item, index) => {
              return <td key={uuidv4()} className="grey-42 p-25-5 w-12">
                <div className="d-flex justify-content-around px-2 ">
                  <div className="nineJudgeHead">{item[0] ? item[0]?.judge : "-"}</div>
                  <div className="nineJudgeHead">{item[1] ? item[1]?.judge : "-"}</div>
                  <div className="nineJudgeHead">{item[2] ? item[2]?.judge : "-"}</div>
                  <div className="nineJudgeHead"> Total Irish Points<br />{index === 0 ? "1st" : index === 1 ? "2nd" : "3rd"} Round </div></div>
              </td>
            })}
          </tr>
          {finalResult.map((dancer, index) => {
            return <tr key={uuidv4()} style={{ background: "#FFFFFF", fontSize: "12px", height: "31px" }}>
              <td className="text-center mx-5 py-4 w-3 grey-30">{dancer?.overallPosition}</td>
              <td className="text-center mx-5 py-4 w-7 grey-30">{dancer?.overallScore}</td>
              <td className="mx-5 w-9 grey-30"><div>{dancer?.fname} &nbsp;{dancer?.lname}<div style={{ opacity: "0.3" }}>{dancer?.school}</div></div></td>
              {refinedResults[index]?.scores?.map((item, index) => {
                return <td key={uuidv4()} className="grey-30 p-25-5 w-8">
                  <div className="d-flex justify-content-around px-2 ">
                    <div>{item[0] ? item[0]?.score : "-"}</div>
                    <div >{item[1] ? item[1]?.score : "-"}</div>
                    <div>{item[2] ? item[2]?.score : "-"}</div>
                    <div>{item[0]?.irish_points + item[1]?.irish_points + item[2]?.irish_points}</div>
                  </div>
                </td>
              })}
            </tr>
          })}

        </tbody>
      </table>
    </div >
  )
}

export default NineJudgeResult