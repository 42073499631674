import React, { useState, useEffect } from "react";
import "./style.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckOutFormGrade from "../../Parent/Payments/CheckOutFormGrade";

const PaymentGrade = (props) => {

  const [stripePromise, SetStripePromise] = useState(null);
  const [clientSecret, setclientSecret] = useState("");

  useEffect(() => {
    fetch("/config").then(async (e) => {
      const publishedkeys = `pk_test_wP7nnS43HzGxunFRs7MEXqpY009anuTPeL`;
      SetStripePromise(loadStripe(`${publishedkeys}`));
      setclientSecret(`${props.client}`);
    });
  }, []);

  const options = {
    clientSecret: `${clientSecret}`,
    appearance: {
      /*...*/
    },
  };
  return (
    <div className="col-md-8 offset-md-2 offset-1 col-10">
      <div className="creditCard">Payment method </div>
      <hr className="mt-0 " />

      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <div className="stripePage">
            <CheckOutFormGrade from={props.from} />
          </div>
        </Elements>
      )}
    </div>
  );
};

export default PaymentGrade;
