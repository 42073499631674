import React, { useState } from 'react'
import moment from 'moment';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { serviceConsumer } from '../../network/ServiceConsumer';
import ConfirmDeleteModal from '../../Components/Modals/OrganizerModel/ManageFeis/Tabulate/ConfirmDeleteModal';
import { ShowToastMessage } from '../../helper/ShowToastMessage';

const DeleteStripeAccount = ({ card, getCardDetails }) => {

    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const deleteStripeAccount = async () => {
        const payload = {
            id: card?._id,
            date: moment(new Date()).utc(true).format()
        }
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/stripe/account/delete`;
            const response = await serviceConsumer('POST', url, payload);
            setOpenDeleteModal(false)
            ShowToastMessage("success", response?.result)
            getCardDetails()
        } catch (error) {
            // setOpenDeleteModal(false)
            ShowToastMessage("error", error.messages)
        }
    };
    return (
        <>
            <div onClick={() => setOpenDeleteModal(true)} style={{ color: "red", cursor: "pointer" }}>
                <DeleteOutlineIcon />
            </div>
            {/* if dancerPage is mentioned, the confirmDeletemodal calls the confirm() function of that component.
            Reused from dancers.jsx*/}
            {openDeleteModal && <ConfirmDeleteModal
                modalState={openDeleteModal}
                confirm={() => deleteStripeAccount()}
                cancel={() => setOpenDeleteModal(false)}
                dancerPage />}
        </>
    )
}

export default DeleteStripeAccount
