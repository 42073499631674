import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

import smart from '../Assets/smart-icon.svg';
import time from '../Assets/time-icon.svg';
import star from '../Assets/star-icon.svg';
import PrizeImg1 from '../Assets/PrizeImg1.jpg';
import prizeImg2 from '../Assets/prizeImg2.jpg';
import prizeImg3 from '../Assets/prizeImg3.jpg';
import prizeImg4 from '../Assets/prizeImg4.jpg';

const MessageForParents = () => {
    const navigate = useNavigate();

    return (
        <>
            <section className="mb-5 mt-5">
                <div className="bg-3"></div>
                <div className="dashboard-organizer d-flex justify-content-center align-items-center">
                    <h3 style={{ fontWeight: 700 }}> DANCERS AND PARENTS</h3>
                </div>
                <section className="mb-5 mt-5 ">
                    <div className="advantages-list">
                        <div className="col-sm-12 col-md-3 advantages-card">
                            <img src={smart} alt="smart" />
                            <p className="adv-title">Smartest</p>
                            <p className="adv-desc">
                                You’ll know you are registering correctly as Feis.link will show you only the dances available to your dancer based on
                                their age and level – and nothing else! You will see only the regular dances, specials, and second chance dances your dancer
                                is eligible for. We make the programming smarter to make registering easier for you.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 advantages-card">
                            <img src={time} alt="smart" />
                            <p className="adv-title">Fastest</p>
                            <p className="adv-desc">
                                With just a few clicks you can select your dances and pay. Feis Registration has never been this simple or this fast.
                            </p>
                        </div>
                        <div className="col-sm-12 col-md-3 advantages-card">
                            <img src={star} alt="smart" />
                            <p className="adv-title">Awesomest</p>
                            <p className="adv-desc">
                                We know that’s not a word, but this is a Feis registration site, we have to have fun somehow! We’ve endeavoured to make
                                this the best feis registration site for you to use. We hope you agree with our claim to awesomest.
                            </p>
                        </div>
                    </div>
                </section>

                <div className="text-center lg-mb-3 md-mb-3 lg-mt-3 md-mt-3 sm-mb-1 sm">
                    <Button
                        variant="contained"
                        color="primary"
                        className="title-signup"
                        sx={{
                            color: 'white',
                            fontSize: '22px',
                            '&:hover': {
                                backgroundColor: '#68AD3F',
                            },
                        }}
                        onClick={() => navigate('/signup')}
                    >
                        Sign Up
                    </Button>
                </div>
            </section>
            {/* ------------------------section for kids image ------------------------------- */}
            <section>
                <div class="container imageGridContainer pt-5">
                    <div class="row  gridImageRow ">
                        <div class="  col-4 d-flex justify-content-center align-items-center">
                            <div style={{ height: '80%' }}>
                                <img className="gridImage1" src={PrizeImg1} alt="" />
                            </div>
                        </div>
                        <div class="col-4">
                            <div class=" row d-flex flex-column ">
                                <img className="gridImage" src={prizeImg2} alt="" />
                                <img className="gridImage" src={prizeImg3} alt="" style={{ marginTop: '0.7rem' }} />
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content center align-items-center me-">
                            <div style={{ height: '80%' }}>
                                <img className="gridImage1" src={prizeImg4} alt="" style={{ borderRadius: '6px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MessageForParents