import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loader from '../../Components/Loader/Loader';
import Button from '../../Components/Button/Button';
import "./Auth.css"

import { serviceConsumer } from "../../network/ServiceConsumer"
import { SIGNUP } from '../../redux/actionTypes/authActionType';
import { regionData } from '../../helper/DataArrays';

import feisLogo from "../../Assets/feis-logo.svg";
import Help from '../../Components/Help/Help';
import Back from '../../Components/Back/Back';
import { setLocalForOrganizerAuth } from '../../helper/setLocalStorage';
import { countryValidation, emailValidation, fnameValidation, lnameValidation, passwordValidation, repeatPwdValidation } from '../../helper/Validations';

const SignupOrg = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    //data array for select options
    const navigateToHomepageHandler = () => {
        navigate("/")
    }
    const redirectToLoginHandler = () => {
        navigate("/login-org", { replace: true })
    }

    //Initial values for formik
    const signupInitialValues = {
        fname: "",
        lname: "",
        email: "",
        country: "",
        password: "",
        repeatPassword: "",
    };

    //Validation Schema for Formik
    const signupValidationSchema = Yup.object().shape({
        fname: fnameValidation,
        lname: lnameValidation,
        email: emailValidation,
        country: countryValidation,
        password: passwordValidation,
        repeatPassword: repeatPwdValidation,
    });

    //form submit handler
    const signupHandler = async (values) => {
        setLoading(true)
        try {
            let value = { ...values, email: values.email.toLowerCase() };
            const url = `${process.env.REACT_APP_BASE_URL}/sign-up/organizer`;
            const response = await serviceConsumer("POST", url, value)
            if (response.success) {
                setLocalForOrganizerAuth(response)
                dispatch({
                    type: SIGNUP,
                    payload: response,
                });
                setError("")
                navigate("/organizer-dashboard", { replace: true })
            }
            setLoading(false)
        }
        catch (error) {
            setError(error.messages);
            setLoading(false)
        }
    }
    const changeHandler = (e, name, setFieldValue) => {
        setFieldValue(name, e.target.value);
        setError("");
    };


    return (
        <div className="d-flex flex-column justify-content-between p-4 auth-org" style={{ minHeight: "100vh" }}>
            {loading && <Loader />}
            <div>
                <img src={feisLogo} alt="feis-logo" onClick={navigateToHomepageHandler} className="feis-logo" />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center" >
                <Back />
                <p className="auth-title-org">Sign up</p>
                <Formik
                    initialValues={signupInitialValues}
                    validationSchema={signupValidationSchema}
                    onSubmit={signupHandler}
                >
                    {({ setFieldValue }) => {
                        return (
                            <Form autoComplete="off">
                                <div>
                                    <div className="d-flex flex-column">
                                        <label className="label-head">First Name:</label>
                                        <Field className="input-box" type="text" name="fname" placeholder="First Name" maxlength={30} />
                                        <ErrorMessage className="auth-error" name="fname" component="div" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="label-head">Last Name:</label>
                                        <Field className="input-box" type="text" name="lname" placeholder="Last Name" maxlength={30} />
                                        <ErrorMessage className="auth-error" name="lname" component="div" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="label-head">Email:</label>
                                        <Field className="input-box" type="email" name="email" placeholder="Email" onChange={(e) => {
                                            changeHandler(e, "email", setFieldValue);
                                        }} />
                                        <ErrorMessage className="auth-error" name="email" component="div" />
                                        {error && <div className="auth-error">{error}</div>}
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="label-head">Region:</label>
                                        <Field name="country" as="select" className="select-box">
                                            <option defaultValue hidden >Select</option>
                                            {regionData.map(region => <option value={region.value}>{region.value}</option>)}
                                        </Field>
                                        <ErrorMessage className="auth-error" name="country" component="div" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <label className="label-head">Password: </label>
                                        <Field className="input-box" type="password" name="password" placeholder="Password" />
                                        <ErrorMessage className="auth-error" name="password" component="div" />
                                    </div>
                                    <div className="d-flex flex-column ">
                                        <label className="label-head">Repeat Password: </label>
                                        <Field className="input-box" type="password" name="repeatPassword" placeholder="Repeat Password" />
                                        <ErrorMessage className="auth-error" name="repeatPassword" component="div" />
                                    </div>
                                    <div className="d-flex flex-grow-1 justify-content-center mt-3">
                                        <Button className="filled">Sign up</Button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <p className="text-button mt-3" onClick={redirectToLoginHandler}>Log in</p>
            </div>
            <Help />

        </div>
    )
}

export default SignupOrg