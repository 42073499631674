import React, { useState } from 'react'
import Modal from "@mui/material/Modal";
import closeIcon from "../../../../../Assets/close-icon.svg"
import { useDispatch } from 'react-redux';
import { SET_MODAL } from '../../../../../redux/actionTypes/modalActionType';
import Button from '../../../../Button/Button';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { serviceConsumer } from '../../../../../network/ServiceConsumer';
import Loader from "../../../../Loader/Loader";

const SplitModal = ({ modalState, tableArr, competitionId }) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")

    //formik initial values
    const splitInitialValues = {
        compName: "",
        compDescr: ""
    }

    //formik validations
    const splitValidationSchema = Yup.object().shape({
        compName: Yup.string()
            .trim()
            .label("First Name:")
            .required("This field is required.")
            .nullable()
            .max(30, "Maximum characters allowed 30"),
        compDescr: Yup.string()
            .trim()
            .label("First Name:")
            .required("This field is required.")
            .nullable(),
    })

    //formik submit handler
    const splitHandler = async (values) => {
        const checkedArray = tableArr.filter((item) => item.checked)
        const registrationIds = checkedArray.map((item => item._id))
        const payload = {
            origin_competition: competitionId,
            competition_new_code: values?.compName,
            competition_new_description: values?.compDescr,
            registrations: registrationIds,
        }
        try {
            const url = `${process.env.REACT_APP_BASE_URL}/feis/competition/split`
            setLoading(true)
            const response = await serviceConsumer("POST", url, payload)
            setSuccess("Competitors split successfully!!")
            setTimeout(() => window.location.reload(), 1000)
            setLoading(false)
        } catch (error) {
            setError(error.messages)
            setLoading(false)
        }
    }

    //modal close handler
    const modalCloseHandler = () => {
        dispatch({
            type: SET_MODAL,
            payload: {
                modal: "",
                eventProp: "",
            }
        })
    }

    return (
        <>
            {loading && <Loader />}
            <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
                <div className="org-modal-div split-modal-div" >
                    <div className="close-div mb-1">
                        <img src={closeIcon} alt="close-icon" className="close-icon" onClick={modalCloseHandler} />
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="split-form-div">
                            <p className="modal-org-title text-center my-2">Split into a new competition</p>
                            <p className="modal-org-subtitle split-subtitle">Create a new competition with the selected dancers.</p>
                            <div className="d-flex flex-column align-items-center ">
                                <Formik
                                    initialValues={splitInitialValues}
                                    validationSchema={splitValidationSchema}
                                    onSubmit={splitHandler}
                                >
                                    {({ dirty, isValid }) => {
                                        return (
                                            <Form autoComplete="off">
                                                <div>
                                                    <div className="d-flex flex-column " >
                                                        <label className="label-head">Competition Code:</label>
                                                        <Field className="split-input" type="text" name="compName" placeholder="Code" maxlength={30} />
                                                        <ErrorMessage className="auth-error" name="compName" component="div" />
                                                    </div>
                                                    <div className="d-flex flex-column ">
                                                        <label className="label-head">Description:</label>
                                                        <Field className="split-input" type="text" name="compDescr" placeholder="Description" />
                                                        <ErrorMessage className="auth-error" name="compDescr" component="div" />
                                                    </div>
                                                    {success && <div style={{ color: "green", fontSize: "12px" }}>{success}</div>}
                                                    {error && <div style={{ color: "red", fontSize: "12px" }}>{error}</div>}
                                                    <div className="d-flex flex-grow-1 justify-content-center mt-3">
                                                        <Button type="submit" className={isValid && dirty ? "filled mb-5" : "disabled mb-5"} > Split</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </>
    )
}

export default SplitModal