import React from 'react'
import Modal from "@mui/material/Modal";
import SuccessContent from '../../../../SuccessContent';

const EditRegisterSuccess = ({ modalState, eventName, deleted }) => {
    return (
        <Modal sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }} open={modalState}>
            <SuccessContent eventName={eventName} edit={true} deleted={deleted} />
        </Modal >
    )
}

export default EditRegisterSuccess