
import React, { useRef, useState, useEffect } from "react";
import { Form, Formik } from "formik";
import axios from "axios";
import { Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

import Loader from "../../Components/Loader/Loader";
import feisLogo from "../../Assets/feis-logo.svg";
import "./style.css";
import FormikControl from "../../Components/formik/FormikControl";
import Help from "../../Components/Help/Help";
import { passwordValidation, repeatPwdValidation } from "../../helper/Validations";
import { serviceConsumer } from "../../network/ServiceConsumer";

export const Confirmpasswords = () => {
  const [loading, setLoading] = useState(false);
  const [params, SetParams] = useSearchParams()
  const navigate = useNavigate()
  const createPasswordFormik = useRef();

  //Initial values for formik
  const confirmPasswordInitialValues = {
    password: "",
    confirmPassword: "",
  };

  //Validation Schema for Formik
  const confirmPasswordValidationSchema = Yup.object().shape({
    password: passwordValidation,
    confirmPassword:repeatPwdValidation,

  });


  const createNewPassword = async (values) => {
    const token = params.get("token")
    const id = params.get("id")
    const password = values
    const payload = {
      token: token,
      id: id,
      password: password.password
    }
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/reset-pw`;
      const config = {
        headers: {
          Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
          accesstoken: localStorage.getItem('accessToken')
        },
      }
      const response = await axios.post(url, payload, config);
      // const response = await serviceConsumer("POST",url,payload)
      if (response.status === 200) {
      // if (response.success) {
        navigate('/login')
      }
    } catch (error) {
    }
  }
  const homePage = () => {
    (navigate('/'))
  }

  useEffect(() => {
    document.title = "Feis.Link - Reset Password"
  }, [])

  return (
    <div className="d-flex flex-column justify-content-between" style={{ height: "100vh" }}>
      {loading && <Loader />}
      <div >
        <img src={feisLogo} alt="" className="m-4 cursor-pointer"  onClick={homePage} />
      </div>
      <div className="d-flex justify-content-center">
        <Formik
          initialValues={confirmPasswordInitialValues}
          validationSchema={confirmPasswordValidationSchema}
          onSubmit={createNewPassword}
          innerRef={createPasswordFormik}
        >
          {(formik) => (
            <Form className="form form-label-right" autoComplete="off">
              <div className="login-form-signIn">
                <div className="email-form-signIn">
                  <h4 className="title-signin">Reset Password</h4>
                  <br />
                  <div>
                    <FormikControl
                      control="input"
                      type="password"
                      name="password"
                      placeholder="Password"
                      style={{ padding: "9px 20px" }}
                    />
                    <FormikControl
                      className="email-password"
                      control="input"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm password"
                      style={{ padding: "9px 20px" }}
                    />
                  </div>
                  <br />
                  <button className="filled" type="submit">Confirm</button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Help />
    </div>
  );
};

export default Confirmpasswords;




