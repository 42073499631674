export const regionData = [
  {
    key: "Eastern Canada",
    value: "Eastern Canada",
  },
  {
    key: "Western Canada",
    value: "Western Canada",
  },
  {
    key: "Mid-America",
    value: "Mid-America",
  },
  {
    key: "Southern USA",
    value: "Southern USA",
  },
  {
    key: "Western USA",
    value: "Western USA",
  },
  {
    key: "Mid-Atlantic",
    value: "Mid-Atlantic",
  },
  {
    key: "New England",
    value: "New England",
  },
];

export const agesCode = [
  {
    key: '5',
    value: ['U5'],
  },
  {
    key: '6',
    value: ['U6'],
  },
  {
    key: '7',
    value: ['U7'],
  },
  {
    key: '8',
    value: ['U8'],
  },
  {
    key: '9',
    value: ['U9'],
  },
  {
    key: '10',
    value: ['U10'],
  },
  {
    key: '11',
    value: ['U11'],
  },
  {
    key: '12',
    value: ['U12'],
  },
  {
    key: '13',
    value: ['U13'],
  },
  {
    key: '14',
    value: ['U14'],
  },
  {
    key: '15',
    value: ['U15'],
  },
  {
    key: '16',
    value: ['U16'],
  },
  {
    key: '17',
    value: ['U17'],
  },
  {
    key: '18',
    value: ['U18'],
  },
  {
    key: '19',
    value: ['U19'],
  },
  {
    key: '20',
    value: ['U20', '19 AND OVER'],
  },
  {
    key: '21',
    value: ['U21', '19 AND OVER', '20 AND OVER'],
  },
  {
    key: '22',
    value: ['U22', '19 AND OVER', '20 AND OVER', '21 AND OVER'],
  },
  {
    key: '23',
    value: ['22 AND OVER', '19 AND OVER', '20 AND OVER', '21 AND OVER'],
  },
];
export const levelsArray = [
  "ALL",
  "BEGINNER",
  "ADVANCED BEGINNER",
  "NOVICE",
  "PRIZEWINNER",
  "PRELIMINARY CHAMPIONSHIPS",
  "OPEN CHAMPIONSHIPS",
  "TEAMS",
  "ARTS AND CULTURE",
  "Non-Level"
];