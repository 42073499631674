import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Button, } from "@mui/material";
import closeIcon from "../../Assets/close-icon.svg";
import "./AddDancerModal.css";
import FormikControl from "../formik/FormikControl";
import * as Yup from "yup";
import { Form, Formik, Field } from "formik";
import axios from "axios";
import Box from "@mui/material/Box";

const EditDancerModal = ({ modalState, edit, action, getAllEvents, dancer, setDancer, editDancerDetails }) => {
  const [dancerDetails, setDancerDetails] = useState(dancer);
  const [schools, setSchools] = useState([])

  //config for api calls
  const config = {
    headers: {
      Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
      accesstoken: localStorage.getItem("accessToken"),
    }
  }

  //getting select- data values for formik form
  const genderData = [
    {
      key: "Boys/Men",
      value: "Boys/Men",
    },
    {
      key: "Girls/Ladies",
      value: "Girls/Ladies",
    },
  ];
  const levelData = [
    {
      key: "Beginner",
      value: "Beginner",
    },
    {
      key: "Advanced Beginner",
      value: "Advanced Beginner",
    },
    {
      key: "Novice",
      value: "Novice",
    },
    {
      key: "Prizewinner",
      value: "Prizewinner",
    },
    {
      key: "Preliminary Championships",
      value: "Preliminary Championships",
    },
    {
      key: "Open Championships",
      value: "Open Championships",
    },
    {
      key: "Adult",
      value: "Adult"
    }
  ];
  const schoolData = schools?.map(school => { return { key: school?.schoolName, value: school?.schoolName } })

  //getting school data from api
  const getSchoolsList = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/account/getSchools`;
    try {
      const response = await axios.get(url, config);
      setSchools(response?.data?.result)
    }
    catch (error) {
    }
  }
  useEffect(() => {
    getSchoolsList()
  }, [])
  const schoolSelect = editDancerDetails.school

  //formik initial values
  const editDancerInitialValues = {
    fname: editDancerDetails.fname,
    lname: editDancerDetails.lname,
    birthday: editDancerDetails.birthday,
    gender: editDancerDetails.gender,
    school: editDancerDetails.school,
    levels: editDancerDetails.levels,
  };

  //formik validation schema
  const addDancerValidationSchema = Yup.object().shape({
    fname: Yup.string()
      .trim()
      .label("First name:")
      .required("This field is required")
      .nullable()
      .max(30, "Maximum characters allowed 30"),
    lname: Yup.string()
      .trim()
      .label("Last name:")
      .required("This field is required")
      .nullable()
      .max(30, "Maximum characters allowed 30"),
    birthday: Yup.number().nullable().required('This field is required')
      .min(1900, "invalid birth year").max(new Date().getFullYear(), "invalid birth year")
      .test(
        'no-special-characters',
        'invalid birth year',
        value => /^[0-9]+$/.test(value)
      )
      .test('is-four-digit', 'Must be a 4-digit number', (value) => value && value.toString().length === 4),

    gender: Yup.string().label("Gender:").required('This field is required').nullable(),
    school: Yup.string().label("School:").required('This field is required').nullable(),
    levels: Yup.string().label("Levels:").required('This field is required').nullable(),
  });

  //compare initial and formik values for button emnable/disable
  const isEqual = (formValues, initialValues) => {
    let equalState = Object.keys(formValues).every(k1 =>
      Object.keys(initialValues).some(k2 =>
        formValues[k1] == initialValues[k2]
      )
    )
    return equalState
  }

  //formik submit handler
  const editDancer = async (values, formikHelpers) => {
    const schoolSelected = schools.find(item => item.schoolName === values?.school)
    let payload = { ...values, _id: dancerDetails._id };
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/account/dancers/${dancer._id}`;
      const response = await axios.put(url, { ...values, school_id: schoolSelected._id }, config);
      if (response?.status === 200) {
        edit(payload);
        setDancerDetails({ ...values, _id: dancer._id, });
        setDancer({ ...values, _id: dancer._id })
        getAllEvents()
      }
    } catch (error) {
    }
  };


  return (
    <Modal className="addDancerScroll" sx={{ display: "flex", alignItems: "center", justifyContent: "center", }} open={modalState}>
      <Box>
        <div style={{ maxHeight: "100vh", padding: "5px 100vw", overflow: "scroll", }}>
          <div className="main-div">
            <div className="close-div mt-4 mb-1">
              <img src={closeIcon} alt="close-icon" className="close-icon" onClick={action} />
            </div>
            <p className="color-subtitle text-center mb-4">Edit dancer</p>
            <div
              className="d-flex align-items-center justify-content-center addDancermodelLabel"
              style={{ background: "white,borderRadius: 14px;" }}
            >
              <Formik
                initialValues={editDancerInitialValues}
                validationSchema={addDancerValidationSchema}
                onSubmit={editDancer}
                enableReinitialize={true}
              >
                {(formik) => (
                  <Form className="form form-label-right" autoComplete="off">
                    <div className="add-dancer">
                      <div className="">
                        <div>
                          <FormikControl
                            className="formikInput"
                            control="input"
                            type="text"
                            label="First name:"
                            name="fname"
                            placeholder="First name"
                            id="editDancerinput"
                            maxlength={30}
                          />
                          <FormikControl
                            className="formikInput"
                            control="input"
                            type="text"
                            label="Last name:"
                            name="lname"
                            placeholder="Last name"
                            id="editDancerinput"
                            maxlength={30}
                          />
                          <FormikControl
                            className="formikInput"
                            control="input"
                            type="number"
                            label="Birth Year:"
                            pattern="\d*"
                            name="birthday"
                            placeholder="Date of birth"
                            maxlength={4}
                            id="editDancerinput"
                          />
                          <FormikControl
                            className="formikSelect"
                            control="select"
                            label="Gender:"
                            name="gender"
                            options={genderData}
                            showAs="Gender"
                            id="editDancerinput"
                          />
                          <FormikControl
                            className="formikSelect"
                            control="select"
                            label="School:"
                            name="school"
                            options={schoolData}
                            showAs="School"
                            id="editDancerinput"
                          />
                          <FormikControl
                            className="formikSelect"
                            control="select"
                            label="Levels:"
                            name="levels"
                            options={levelData}
                            showAs="Levels"
                            id="editDancerinput"
                          />
                          <div className="my-5 d-flex justify-content-center">
                            <Button
                              variant="contained"
                              className={(isEqual(formik.values, editDancerInitialValues) || !formik.isValid) ? "updateChangeDisabled" : "updateChange"}
                              sx={{
                                color: "white",
                                width: "60px",
                                fontSize: "16px",
                                "&:hover": {
                                  background: " #fbb040 ",
                                  color: "#ffffff",
                                  borderRadius: "2px solid #fbb040"
                                },
                              }}
                              type="submit"
                              disabled={isEqual(formik.values, editDancerInitialValues) || !formik.isValid}
                            >
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </Modal >
  );
};

export default EditDancerModal;
