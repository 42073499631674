export const agesCode = ["U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "19 AND OVER"]
export const agesCode22 = ["U5", "U6", "U7", "U8", "U9", "U10", "U11", "U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "U20", "U21", "U22", "22 AND OVER"]
export const dancersData = [1, 2, 3, 4, 5, 6, 7, 8]
export const agesCodeObj = {
    "U5": ["U5"],
    "U6": ["U6"],
    "U7": ["U7"],
    "U8": ["U8"],
    "U9": ["U9"],
    "U10": ["U10"],
    "U11": ["U11"],
    "U12": ["U12"],
    "U13": ["U13"],
    "U14": ["U14"],
    "U15": ["U15"],
    "U16": ["U16"],
    "U17": ["U17"],
    "U18": ["U18"],
    "U19": ["U19"],
    "U20": ["U20", "19 AND OVER"],
    "U21": ["U21", "20 AND OVER", "19 AND OVER"],
    "U22": ["U22", "21 AND OVER", "20 AND OVER", "19 AND OVER"],
    "22 AND OVER": ["22 AND OVER", "21 AND OVER", "20 AND OVER", "19 AND OVER"],
}

export const agesCodeObj1 = {
    "U5": ["U5"],
    "U6": ["U6"],
    "U7": ["U7"],
    "U8": ["U8"],
    "U9": ["U9"],
    "U10": ["U10"],
    "U11": ["U11"],
    "U12": ["U12"],
    "U13": ["U13"],
    "U14": ["U14"],
    "U15": ["U15"],
    "U16": ["U16"],
    "U17": ["U17"],
    "U18": ["U18"],
    "U19": ["U19"],
    "U20": ["U20"],
    "U21": ["U21"],
    "U22": ["U22"],
    "19 AND OVER": ["U20", "U21", "U22", "22 AND OVER"],
    "20 AND OVER": ["U21", "U22", "22 AND OVER"],
    "21 AND OVER": ["U22", "22 AND OVER"],
    "22 AND OVER": ["22 AND OVER"],
}

export const levelData = ["Beginner", "Advanced Beginner", "Novice", "Prizewinner", "Preliminary Championships", "Open Championships", "Adult"]
export const genderData = [
    {
        key: 'Both',
        value: 'Both',
    },
    {
        key: 'Boys/Men',
        value: 'Boys/Men',
    },
    {
        key: 'Girls/Ladies',
        value: 'Girls/Ladies',
    },
];

export const Rounddata = [
    {
        key: '1',
        value: '1',
    },
    {
        key: '2',
        value: '2',
    },
    {
        key: '3',
        value: '3',
    },
];

export const AdjData = [
    {
        key: '1',
        value: '1',
    },
    {
        key: '3',
        value: '3',
    },
    {
        key: '9',
        value: '9',
    },
];