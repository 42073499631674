import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


const HomePageTitle = () => {
    const navigate = useNavigate();
    return (
        <section className="lg-mb-5 md-mb-3 lg-mt-3 md-mt-3">
            <div className="bg-1"></div>
            <div className="bg-2"></div>
            <div className="registration d-flex flex-column justify-content-center align-items-center ">
                <h1 className="text-center  desktop-heading">
                    FEIS<span className="heading-color"> REGISTRATION</span><br /> AND<span className="heading-color"> TABULATION.</span>
                </h1>
                <h1 className="text-center  mobile-heading">
                    FEIS<span className="heading-color"> REGISTRATION</span><br /> AND<span className="heading-color "> TABULATION.</span>
                </h1>
                <Button
                    variant="contained"
                    color="primary"
                    className="title-signup mt-2"
                    sx={{
                        color: 'white',
                        fontSize: '22px',
                        '&:hover': {
                            backgroundColor: '#68AD3F',
                        },
                    }}
                    onClick={() => navigate('/signup')}
                >
                    Sign Up
                </Button>
            </div>
        </section>
    )
}

export default HomePageTitle