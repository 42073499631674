import React, { useState, useEffect } from 'react';
import { serviceConsumer } from '../../network/ServiceConsumer';
import Loader from '../../Components/Loader/Loader';
import Card from '../../Components/CardOrg/Card';
import { useNavigate } from 'react-router-dom';
import InfiniteScrollComponent from '../../Components/InfiniteScrollComponent';
import moment from 'moment';


const PreviousEvents = ({ eventType, eventStatus }) => {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const [apiUrl, setApiUrl] = useState(null); // State to hold the apiUrl
    const navigate = useNavigate();
    const limit = 10;
    const date=moment(new Date()).utc(true).format()

    const navigateToEdit = (event) => {
        localStorage.setItem("ID", event?._id);
        localStorage.removeItem("TAB");
        const route = eventType === 'feiseanna' ? '/organizer-create-feisanna?previousEvent=true' : '/organizer-create-grade?previousEvent=true';
        navigate(route);
    };

    const getEvents = async (url) => {
        const closedPayload = {
            page:page,
            limit:limit,
            type:"CLOSED",
            date: date
        }
        try {
            const payload = eventStatus == "open" ? { page, limit,date } : closedPayload;
            const response = await serviceConsumer("POST", `${process.env.REACT_APP_BASE_URL}${url}`, payload);
            if (response?.Feis?.results?.length > 0) {
                setEvents((prevEvents) => [...prevEvents, ...response?.Feis?.results]);
                setHasMore(response?.Feis?.next?.hasMore);
                setPage(response?.Feis?.next?.hasMore ? response?.Feis?.next?.page : page);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        const newApiUrl = eventStatus === 'open'
            ? (eventType === 'feiseanna' ? '/feis/organizer/feis/previousevents' : '/feis/organizer/grade/events')
            : (eventType === 'feiseanna' ? '/feis/organizer/feis/closed' : '/feis/organizer/grade/closed');
        setLoading(true);
        setApiUrl(newApiUrl);  // Update the state with the latest apiUrl
        getEvents(newApiUrl);
    }, []);

    const renderEventCard = (event) => (
        <Card
            logo={event?.logo}
            eventType={event?.eventType}
            eventName={event?.name}
            date={eventType === 'feiseanna' ? event?.feis_date : event?.exam_date}
            venue={event?.venue_city}
            state={event?.state}
            onClick={() => navigateToEdit(event)}
            btnName="Select"
        />
    );

    const EventList = ({ events, title }) => (
        <>
            <div className="db-org-title pt-4">{title}</div>
            <div className="mt-1 hr-org-body" />
            <InfiniteScrollComponent events={events} hasMore={hasMore} getAllEvents={() => getEvents(apiUrl)}>
                {events.map((event) => renderEventCard(event))}
            </InfiniteScrollComponent>
        </>
    );

    return (
        <div className="db-body">
            {loading && <Loader />}
            {events.length > 0 && <EventList events={events} title={eventType === 'feiseanna' ? 'Fiesannas' : 'Grade Exam'} />}
            {!loading && events.length === 0 && <div className="py-5 text-center">No events.</div>}
        </div>
    );
};

export default PreviousEvents;
