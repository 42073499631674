import React from 'react'

const StripeCard = ({ card }) => {
    return (
        <div className="card " style={{ width: 'fit-content' }}>
            <div className="card-body">
                <blockquote className="blockquote mb-0">
                    <p className="card-cont">
                        CAD account {card?.externalAccount?.bank_name} ending with *{card?.externalAccount?.last4}{' '}
                        <span className="card-currency">
                            Default for{' '}
                            <span style={{ textTransform: 'uppercase' }}>{card?.externalAccount?.currency}</span>
                        </span>
                    </p>
                </blockquote>
            </div>
        </div>
    )
}

export default StripeCard
