import { serviceConsumer } from "../network/ServiceConsumer";
import axios from "axios";
import { ShowToastMessage } from "./ShowToastMessage";


export const postPrintHandler = async (urlApi, payload, setLoading, setErr,) => {
    setErr('');
    setLoading(true);
    //since popup blockers affects opening a page after 1 second of event click, open the page immediately and then add the url
    let pdfPage = window.open('', '_blank');
    pdfPage.document.write('Loading...');

    try {
        const response = await serviceConsumer('POST', urlApi, payload, '', 'buffer');
        printResponse(response, "", pdfPage);
        setLoading(false);
    } catch (error) {
        setErr(error?.messages);
        setLoading(false);
    }
}

export const getPrintHandler = async (url, setLoading, eventName, setOpenModal) => {
    // only in printAllScoreSheets eventName is used. so manipulate with that
    let pdfPage;
    if (!eventName) {
        setLoading(true)
        pdfPage = window.open('', '_blank');
        pdfPage.document.write('Loading preview...');
    }
    try {
        const response = await axios.get(url, {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + window.btoa(`${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`),
                accesstoken: localStorage.getItem("accessToken"),
            },
        });
        eventName && setOpenModal(true)
        !eventName && printResponse(response, eventName, pdfPage);
        !eventName && setLoading(false);
    } catch (error) {
        ShowToastMessage("error", "Something Went Wrong")
        setLoading(false);
    }
};


const printResponse = (response, eventName, pdfPage) => {
    //eventName is added to add name to a zip file and it is used as condition if it is zip since others are pdf
    if (eventName) {
        const blob = new Blob([response?.data], { type: 'application/zip' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = eventName
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    else {
        const blob = new Blob([response?.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        pdfPage.location.href = blobUrl;
    }
}