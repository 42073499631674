import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getClosed } from '../../helper/setLocalStorage';

const MainDiv = ({ photo, name, location, collaboratorModal, onDelete, id, from, }) => {
  const url = 'https://icon-library.com/images/no-user-image-icon/no-user-image-icon-29.jpg';
  return (
    <div className={collaboratorModal ? 'AddedAdjCollab mb-3' : 'AddedAdj'}>
      <div className="leftDivAdj ">
        <div className="adjImg mb-1">
          <img src={photo ? photo : url} />
          <div className="adjDes">
            <span className="adjNAme">{name}</span>
            <span className="adjCoun">{location}</span>
          </div>
        </div>
      </div>
      {from != 'golive' && !getClosed() &&(
        <div className="rightDivAdj" onClick={() => { onDelete(id, from); }}><DeleteOutlineIcon /></div>
      )}
    </div>
  );
};

export default MainDiv;
